export const ZKL_3000 = "zkl-3000";
export const ZKL_3000_RC = "zkl-3000-rc";
export const ZKL_3000_RCC = "zkl-3000-rcc";
export const ZKL_3000_RC_AFTC = "zkl-3000-rc-aftc";
export const RDI_3000 = "rdi";
export const RSS_3000 = "rss";

export type SupportedTypes =
  | typeof ZKL_3000
  | typeof ZKL_3000_RC
  | typeof ZKL_3000_RCC
  | typeof ZKL_3000_RC_AFTC
  | typeof RSS_3000
  | typeof RDI_3000;
