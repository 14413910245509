import { SwitchBaseProps } from "@material-ui/core/internal/SwitchBase";
import IndeterminateCheckbox from "components/Table/Checkbox/IndeterminateCheckbox";
import { Device, FOTAJobListView } from "Device/Device";
import React from "react";
import { useTranslation } from "react-i18next";
import { UseRowSelectInstanceProps, UseTableInstanceProps } from "react-table";

const HeaderCheckbox = ({
  toggleRowSelected,
  getToggleAllRowsSelectedProps,
  flatRows,
}: UseRowSelectInstanceProps<Device> &
  UseTableInstanceProps<Device>): JSX.Element => {
  const [t] = useTranslation(["common"]);

  const onChange: SwitchBaseProps["onChange"] = () => {
    flatRows.forEach((tableRowData) => {
      if (tableRowData.original.editable) {
        toggleRowSelected(tableRowData.id, !tableRowData.isSelected);
      }
    });
  };

  return (
    <IndeterminateCheckbox
      {...getToggleAllRowsSelectedProps()}
      title={t("common:table.toggleAllRowsSelected")}
      onChange={onChange}
    />
  );
};

export const FotaJobHeaderCheckbox = ({
  toggleRowSelected,
  getToggleAllRowsSelectedProps,
  flatRows,
}: UseRowSelectInstanceProps<FOTAJobListView> &
  UseTableInstanceProps<FOTAJobListView>): JSX.Element => {
  const [t] = useTranslation(["common"]);

  const onChange: SwitchBaseProps["onChange"] = () => {
    flatRows.forEach((tableRowData) => {
      if (tableRowData.original.is_online) {
        toggleRowSelected(tableRowData.id, !tableRowData.isSelected);
      }
    });
  };

  return (
    <IndeterminateCheckbox
      {...getToggleAllRowsSelectedProps()}
      title={t("common:table.toggleAllRowsSelected")}
      onChange={onChange}
    />
  );
};

export default HeaderCheckbox;
