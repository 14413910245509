import React from "react";
import PerfectScrollbar from "perfect-scrollbar";

export type User = {
  id: number;
  email: string;
  name: string;
  productionOnly: boolean;
};

export type Token = {
  email: string;
  password: string;
};

type ContextProps = {
  ps: null | PerfectScrollbar;
  setPs: (ps: PerfectScrollbar) => void;
};

export const AppContext = React.createContext<ContextProps>({
  ps: null,
  setPs: () => {
    //noop
  },
});
