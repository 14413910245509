import { CardActions, Fab } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import { Save } from "@material-ui/icons";
import Button from "components/Button";

import CustomLinearProgress from "components/CustomLinearProgress";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import CustomInputField from "components/Form/CustomInputField";
import FormCheckbox from "components/Form/FormCheckbox";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { format, formatRFC3339, parse, parseISO } from "date-fns";
import { Device, Rdi, Rss, ZklRc, ZklRcAftc } from "Device/Device";

import { dateInputFormat } from "Device/formatters";
import { patchDevice } from "Device/requests";
import { ZKL_3000_RC, ZKL_3000_RCC } from "Device/supportedTypes";

import { Field, Form, Formik } from "formik";
import { FormikProps } from "formik/dist/types";

import useDebounce from "hooks/Debounce";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { boolean, date, object, string } from "yup";
import { ObjectShape } from "yup/lib/object";

import Divider from "./Divider";
import Alert from "@material-ui/lab/Alert";
import ImeiChangeDialog from "Device/ImeiChangeDialog";

const useStyles = makeStyles((theme) => ({
  actions: {
    flexDirection: "row-reverse",
  },
  progressbar: {
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    marginBottom: 0,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

export default function UpdateForm({ device }: { device?: Device }) {
  const classes = useStyles();
  const [t] = useTranslation("di");
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showProgressIndicator, setShowProgressIndicator] = useState(false);
  const debouncedShowProgressIndicator = useDebounce(
    showProgressIndicator,
    300
  );
  const [open, setOpen] = useState<boolean>(false);

  const genericSerials = {
    ncmPCB: device?.pcbSerials?.ncmPCB || "",
    mainPCB: device?.pcbSerials?.mainPCB || "",
  };

  const initialValues: Required<
    Pick<
      Device,
      "serial" | "productionDate" | "productionNr" | "writtenOff" | "pcbSerials"
    >
  > = {
    serial: device?.serial || "",
    productionDate: device?.productionDate
      ? format(parseISO(device.productionDate), dateInputFormat)
      : "",
    productionNr: device?.productionNr || "",
    pcbSerials: {},
    writtenOff: device?.writtenOff === true,
  };

  let pcbSerials: (
    | keyof ZklRc["pcbSerials"]
    | keyof ZklRcAftc["pcbSerials"]
    | keyof Rdi["pcbSerials"]
    | keyof Rss["pcbSerials"]
  )[] = [];
  const schemaShape: ObjectShape = {
    serial: string().required(),
    productionDate: date().optional(),
    productionNr: string().optional(),
    pcbSerials: object().shape({
      batteryBoardPCB: string().optional(),
      batteryPackPCB: string().optional(),
      ncmPCB: string().optional(),
      mainPCB: string().optional(),
      switchPCB: string().optional(),
      aftcPCB: string().optional(),
      motor: string().optional(),
      ledPCB: string().optional(),
      cloudLightPCB: string().optional(),
      connectorPCB: string().optional(),
    }),
    writtenOff: boolean().required(),
  };
  if (device) {
    switch (device.type) {
      case "zkl-3000-rc-aftc":
        pcbSerials = [
          "batteryBoardPCB",
          "ncmPCB",
          "switchPCB",
          "mainPCB",
          "aftcPCB",
        ];
        initialValues.pcbSerials = {
          ...genericSerials,
          batteryBoardPCB: device?.pcbSerials?.batteryBoardPCB || "",
          aftcPCB: device.pcbSerials?.aftcPCB || "",
          switchPCB: device.pcbSerials?.switchPCB || "",
        };
        break;
      case "zkl-3000-rc":
      case "zkl-3000-rcc":
        pcbSerials = ["batteryBoardPCB", "ncmPCB", "switchPCB", "mainPCB"];
        initialValues.pcbSerials = {
          ...genericSerials,
          batteryBoardPCB: device?.pcbSerials?.batteryBoardPCB || "",
          switchPCB: device.pcbSerials?.switchPCB || "",
        };
        break;
      case "rdi":
        pcbSerials = ["batteryPackPCB", "ncmPCB", "mainPCB", "motor", "ledPCB"];
        initialValues.pcbSerials = {
          ...genericSerials,
          batteryPackPCB: device?.pcbSerials?.batteryPackPCB || "",
          motor: device.pcbSerials?.motor || "",
          ledPCB: device.pcbSerials?.ledPCB || "",
        };
        break;
      case "rss":
        pcbSerials = [
          "ncmPCB",
          "mainPCB",
          "connectorPCB",
          "cloudLightPCB",
          "switchPCB",
        ];
        initialValues.pcbSerials = {
          ...genericSerials,
          cloudLightPCB: device.pcbSerials?.cloudLightPCB || "",
          connectorPCB: device.pcbSerials?.connectorPCB || "",
        };
        break;
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, helpers) => {
        helpers.setSubmitting(false);
        setOpenConfirmationDialog(true);
      }}
      validationSchema={object(schemaShape)}
      enableReinitialize={true}
    >
      {({
        isSubmitting,
        setSubmitting,
        values,
      }: FormikProps<typeof initialValues>) => (
        <Form>
          <GridContainer>
            <GridItem>
              <Field
                component={CustomInputField}
                labelText={t("device.serialNr")}
                id="serial"
                name="serial"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  inputProps: {
                    maxLength: 32,
                    disabled: !device?.editable || device?.deliveryDate != null,
                  },
                }}
              />
            </GridItem>
            <GridItem>
              <Field
                component={CustomInputField}
                labelText={t("device.productionDate")}
                id="productionDate"
                name="productionDate"
                labelProps={{
                  shrink: true,
                }}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "date",
                  autoComplete: "off",
                  inputProps: {
                    disabled: !device?.editable,
                  },
                }}
              />
            </GridItem>
            <GridItem>
              <Field
                component={CustomInputField}
                labelText={t("device.productionNr")}
                id="productionNr"
                name="productionNr"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  inputProps: {
                    disabled: !device?.editable,
                  },
                }}
              />
            </GridItem>
          </GridContainer>
          <Divider />
          <GridContainer>
            {pcbSerials.map((key) => (
              <GridItem key={key} md={3}>
                <Field
                  component={CustomInputField}
                  labelText={t(`device.${key}`)}
                  id={key}
                  name={`pcbSerials.${key}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputProps: {
                      maxLength: 255,
                      disabled: !device?.editable,
                    },
                  }}
                />
              </GridItem>
            ))}
          </GridContainer>
          <Divider />
          <GridContainer>
            <GridItem>
              <GridContainer container spacing={2}>
                <GridItem item xs={12}>
                  <FormCheckbox
                    label={t("device.writtenOff")}
                    name="writtenOff"
                    disabled={!device?.editable || device.projectName != null}
                  />
                </GridItem>
                <GridItem item xs={12}>
                  {device?.projectName != null && (
                    <Alert severity="info">
                      {t("warning.disableWrittenOffForAssignedDevice")}
                    </Alert>
                  )}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>

          {!!device?.editable && (
            <CardActions className={classes.actions}>
              <Fab
                color="primary"
                aria-label="save"
                type="submit"
                disabled={isSubmitting && debouncedShowProgressIndicator}
              >
                <Save />
              </Fab>
            </CardActions>
          )}

          {isSubmitting && debouncedShowProgressIndicator && (
            <CustomLinearProgress
              variant="indeterminate"
              color="primary"
              className={classes.progressbar}
            />
          )}
          {(device?.type == ZKL_3000_RC || device?.type == ZKL_3000_RCC) &&
            device?.version == 2 &&
            device?.inService && (
              <>
                <div>
                  <Tooltip
                    title={t("device.info.imeiChange.confirmImeiChange")}
                  >
                    <span>
                      <Button
                        color="success"
                        onClick={() => {
                          setOpen(true);
                        }}
                        className={classes.button}
                      >
                        {t("Change IMEI")}
                      </Button>
                    </span>
                  </Tooltip>
                </div>
                <ImeiChangeDialog open={open} setOpen={setOpen} device={device}>
                  <h5>{t("device.info.imeiChange.header")}</h5>
                </ImeiChangeDialog>
              </>
            )}
          <ConfirmDialog
            title={t("device.update.title")}
            open={openConfirmationDialog}
            setOpen={setOpenConfirmationDialog}
            onConfirm={async () => {
              if (!device) return;
              setSubmitting(true);
              setShowProgressIndicator(true);
              try {
                const {
                  serial,
                  pcbSerials,
                  productionDate,
                  productionNr,
                  writtenOff,
                } = values;
                await patchDevice(device, {
                  serial,
                  pcbSerials,
                  productionNr,
                  productionDate: productionDate
                    ? formatRFC3339(
                        parse(productionDate, dateInputFormat, new Date())
                      )
                    : null,
                  writtenOff,
                });

                enqueueSnackbar(t("device.update.succeeded"), {
                  variant: "success",
                });
              } catch {
                // Caught by interceptor
              } finally {
                setSubmitting(false);
                setShowProgressIndicator(false);
              }
            }}
          >
            <h5>{t("device.update.explanation")}</h5>
          </ConfirmDialog>
        </Form>
      )}
    </Formik>
  );
}
