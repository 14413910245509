import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import CustomInputField from "components/Form/CustomInputField";
import FormCheckbox from "components/Form/FormCheckbox";
import { Composition } from "Device/Calibration/Composition/CompositionSchema";
import VerificationStep, {
  FinishSelfTest,
} from "Device/Calibration/Verification/VerificationStep";
import { Device } from "Device/Device";
import { downloadRdiReport } from "Device/requests";
import { Field } from "formik";
import { FormikValues } from "formik/dist/types";
import { WebSocketHook } from "hooks/WebSocket";
import React from "react";
import { useTranslation } from "react-i18next";
import { boolean, string } from "yup";

type Props = {
  device: Device | null;
  composition: Composition | null;
  previousStep?: () => void;
  firstStep?: () => void;
  finishSelfTest: FinishSelfTest;
  lastJsonMessage?: WebSocketHook["lastJsonMessage"];
};

const RdiVerificationStep = ({
  device,
  composition,
  previousStep,
  firstStep,
  finishSelfTest,
  lastJsonMessage,
}: Props): JSX.Element => {
  const [t] = useTranslation("app");

  const finishDataProvider = ({ remarks }: FormikValues) => {
    return {
      remark: remarks,
      deviceType: device?.type ?? "Unknown",
    };
  };

  return (
    <VerificationStep
      device={device}
      composition={composition}
      initialValues={{
        attachedToTrack: false,
        flagPlacement: false,
        uiPanelFunctions: false,
        magnetsSecured: false,
        remark: "",
      }}
      schema={{
        attachedToTrack: boolean().equals([true]),
        flagPlacement: boolean().equals([true]),
        uiPanelFunctions: boolean().equals([true]),
        magnetsSecured: boolean().equals([true]),
        remark: string(),
      }}
      finishSelfTest={finishSelfTest}
      finishDataProvider={finishDataProvider}
      previousStep={previousStep}
      firstStep={firstStep}
      lastJsonMessage={lastJsonMessage}
      downloadReport={downloadRdiReport}
      languages={["en", "nl", "fr"]}
    >
      <FormGroup>
        <FormLabel component="h3">
          {t("device.calibration.verifyStep.rdi.tested")}
        </FormLabel>
        <FormCheckbox
          label={t("device.calibration.verifyStep.rdi.attachedToTrack")}
          name="attachedToTrack"
        />
        <FormCheckbox
          label={t("device.calibration.verifyStep.rdi.flagPlacement")}
          name="flagPlacement"
        />
        <FormCheckbox
          label={t("device.calibration.verifyStep.rdi.uiPanelFunctions")}
          name="uiPanelFunctions"
        />
        <FormCheckbox
          label={t("device.calibration.verifyStep.rdi.magnetsSecured")}
          name="magnetsSecured"
        />
      </FormGroup>

      <Field
        labelText={t("device.calibration.verifyStep.remarks")}
        component={CustomInputField}
        id="remarks"
        name="remarks"
        multiline
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{ multiline: true }}
      />
    </VerificationStep>
  );
};

export default RdiVerificationStep;
