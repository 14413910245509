import axios from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import useWebSocketBase, { Options } from "react-use-websocket";
import { DEFAULT_OPTIONS } from "react-use-websocket/dist/lib/constants";
import { WebSocketHook as WebSocketHookBase } from "react-use-websocket/dist/lib/types";

export type WebSocketHook = Omit<WebSocketHookBase, "lastJsonMessage"> & {
  lastJsonMessage: Record<string, unknown> | null;
};

export default function useWebSocket(
  url: string | (() => string | Promise<string>) | null,
  { onClose, onError, onOpen, ...options }: Options = DEFAULT_OPTIONS,
  connect = true
): WebSocketHook {
  const { signoutRedirect } = useAuth();
  const [t] = useTranslation("app");
  const { enqueueSnackbar } = useSnackbar();
  const {
    sendJsonMessage,
    lastJsonMessage,
    lastMessage,
    sendMessage,
    getWebSocket,
    readyState,
  } = useWebSocketBase(
    url,
    {
      onClose: (event) => {
        const { code, reason } = event;
        if (code >= 4400) {
          if (code === 4401) {
            enqueueSnackbar(t("common:request.invalidCredentials"), {
              variant: "error",
            });
            signoutRedirect(); // Sign out the user if invalid credentials error occurs
          } else {
            enqueueSnackbar(
              t("app:device.calibration.wsError") + `: ${reason}`,
              {
                variant: "error",
              }
            );
          }
        }
        onClose?.(event);
      },
      onError: (event) => {
        enqueueSnackbar(
          t("app:device.calibration.wsError") + `: ${event.type}`,
          {
            variant: "error",
          }
        );
        onError?.(event);
      },
      onOpen: (event) => {
        sendJsonMessage({
          type: "authenticated",
          data: axios.defaults.headers.common["Authorization"],
        });
        onOpen?.(event);
      },
      ...options,
    },
    connect
  );

  return {
    sendJsonMessage,
    lastJsonMessage,
    sendMessage,
    lastMessage,
    getWebSocket,
    readyState,
  };
}
