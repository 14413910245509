import IndeterminateCheckbox from "components/Table/Checkbox/IndeterminateCheckbox";
import React from "react";
import { useTranslation } from "react-i18next";
import { UseRowSelectInstanceProps, UseTableInstanceProps } from "react-table";

export type HeaderCheckboxType<D extends Record<string, unknown>> = ({
  getToggleAllRowsSelectedProps,
}: UseRowSelectInstanceProps<D> & UseTableInstanceProps<D>) => JSX.Element;

type Props<D extends Record<string, unknown>> = Required<
  Pick<UseRowSelectInstanceProps<D>, "getToggleAllRowsSelectedProps">
>;

const HeaderCheckbox = <D extends Record<string, unknown>>({
  getToggleAllRowsSelectedProps,
}: Props<D>): JSX.Element => {
  const [t] = useTranslation(["common"]);

  return (
    <IndeterminateCheckbox
      {...getToggleAllRowsSelectedProps()}
      title={t("common:table.toggleAllRowsSelected")}
    />
  );
};

export default HeaderCheckbox;
