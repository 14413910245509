import React from "react";
import { getIn } from "formik";
import CustomInput, { CustomInputProps } from "components/Form/CustomInput";
import { InputProps } from "@material-ui/core/Input";
import { FieldInputProps, FormikValues } from "formik/dist/types";

type Props = {
  disabled: boolean;
  inputProps: InputProps;
  onBlur?: InputProps["onBlur"];
  helperText?: React.ReactNode | string;
  field: FieldInputProps<HTMLInputElement | HTMLTextAreaElement>;
  form: FormikValues;
};

const fieldToInputBase = ({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  inputProps,
  ...props
}: Props): CustomInputProps => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    inputProps: {
      ...inputProps,
      disabled: disabled ?? isSubmitting,
      onBlur:
        onBlur ??
        function (e) {
          fieldOnBlur(e ?? field.name);
        },
      ...field,
    },
    helperText: showError ? fieldError : helperText,
    error: showError,
    ...props,
  };
};

const CustomInputField = function (props: Props): JSX.Element {
  return <CustomInput {...fieldToInputBase(props)} />;
};

export default CustomInputField;
