import React from "react";

import { Grid } from "@material-ui/core";

import {
  IsOnlineIndicator,
  InServiceIndicator,
  ProjectIndicator,
  StatusCustomerIndicator,
} from "./indicators";

export default function Indicators({ device }: { device: DeviceStatus }) {
  return (
    <Grid container item justifyContent="flex-end">
      <IsOnlineIndicator isOnline={device.isOnline} />
      <InServiceIndicator inService={device.inService} />
      <StatusCustomerIndicator statusCustomer={device.statusCustomer} />
      <ProjectIndicator projectName={device.projectName} />
    </Grid>
  );
}

interface DeviceStatus {
  isOnline?: boolean;
  inService?: boolean;
  projectName?: string;
  statusCustomer?: string;
}
