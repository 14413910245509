import Wizard from "components/Wizard/Wizard";
import { Device } from "Device/Device";
import DeviceStartDurationSearch from "Device/DurationTest/DeviceStartDurationSearch";
import DeviceStartDurationTestStatus from "Device/DurationTest/DeviceStartDurationTestStatus";
import { FetchDeviceFilters, Status } from "Device/definitions";
import { transferDurationTestStatus } from "Device/requests";
import React, { useCallback, useState, useEffect } from "react";
import { Column } from "react-table";

type Props = {
  deviceFilters: FetchDeviceFilters;
  selectDevicesTitle: string;
  actionTitle: string;
  confirmActionText: string;
  columns: Column<Device>[];
  autoRefresh: boolean;
  onUpdate?: () => void;
};

const DeviceStartDurationTest = ({
  deviceFilters,
  selectDevicesTitle,
  actionTitle,
  confirmActionText,
  columns,
  autoRefresh,
  onUpdate,
}: Props): JSX.Element => {
  const [selected, setSelected] = React.useState<string[]>([]);
  const [loaded, setLoaded] = React.useState<Map<string, Device>>(new Map());
  const [selectedDevices, setSelectedDevices] = React.useState<Device[]>([]);
  const [activeStep, setActiveStep] = React.useState<number>(1);
  const [action, setAction] = React.useState<number>(-1);
  const [requests, setRequests] = useState(new Map());
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [remarks, setRemarks] = useState<string>("");

  const onDataFetched = (array: Device[]) => {
    setLoaded(
      new Map([...loaded].concat(array.map((device) => [device.uid, device])))
    );
    onUpdate && onUpdate();
  };

  const updateDurationTestStatus = async (uid: string, state: number) => {
    const oldRequest = requests.get(uid);
    if (oldRequest && oldRequest.status !== Status.FAILURE) return;
    const timeout = setTimeout(() => {
      setRequests(
        (prevState) =>
          new Map([...prevState, [uid, { status: Status.IN_PROGRESS }]])
      );
    }, 100);
    const result = await transferDurationTestStatus(uid, state, remarks);
    clearTimeout(timeout);
    setRequests((prevState) => new Map([...prevState, [uid, result]]));
  };

  const updateDurationTestsStatus = async (state: number) => {
    if (requestInProgress) return;
    setRequestInProgress(true);
    await Promise.allSettled(
      selected.map((device) => updateDurationTestStatus(device, state))
    );
    setRequestInProgress(false);
  };

  const updateSelected = useCallback(() => {
    setSelectedDevices(
      selected.reduce((result: Device[], uid) => {
        const loadedDevice = loaded.get(uid);
        if (loadedDevice) result.push(loadedDevice);
        return result;
      }, [])
    );
  }, [selected, loaded, setSelectedDevices]);

  const onStepChange = useCallback(
    (activeStep: number) => {
      updateSelected();
      setActiveStep(activeStep);
    },
    [updateSelected, setActiveStep, activeStep]
  );

  useEffect(() => {
    if (activeStep === 2) {
      updateDurationTestsStatus(action);
    }
  }, [action, activeStep]);

  return (
    <>
      <Wizard
        labels={[{ name: selectDevicesTitle }, { name: actionTitle }]}
        onStepChange={onStepChange}
      >
        <DeviceStartDurationSearch
          onSelectionChanged={setSelected}
          onDataFetched={onDataFetched}
          initialSelectedRowIds={selected}
          deviceFilters={deviceFilters}
          columns={columns}
          autoRefresh={autoRefresh}
          nextStep={updateDurationTestsStatus}
          setAction={setAction}
          remarks={remarks}
          setRemarks={setRemarks}
        />

        <DeviceStartDurationTestStatus
          devices={selectedDevices}
          confirmActionText={confirmActionText}
          actionBtnText={actionTitle}
          requests={requests}
        />
      </Wizard>
    </>
  );
};

export default DeviceStartDurationTest;
