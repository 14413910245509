import Wizard from "components/Wizard/Wizard";
import { FetchDeviceFilters } from "Device/definitions";
import { Device } from "Device/Device";
import DeviceServiceSearch from "Device/Service/DeviceServiceSearch";
import DeviceSetServiceStatus from "Device/Service/DeviceSetServiceStatus";
import React, { useCallback, useEffect } from "react";
import { Column } from "react-table";

type Props = {
  deviceFilters: FetchDeviceFilters;
  selectDevicesTitle: string;
  actionTitle: string;
  confirmActionText: string;
  columns: Column<Device>[];
  autoRefresh: boolean;
  onUpdate?: () => void;
};

const DeviceService = ({
  deviceFilters,
  selectDevicesTitle,
  actionTitle,
  confirmActionText,
  columns,
  autoRefresh,
  onUpdate,
}: Props): JSX.Element => {
  const [selected, setSelected] = React.useState<string[]>([]);
  const [loaded, setLoaded] = React.useState<Map<string, Device>>(new Map());
  const [selectedDevices, setSelectedDevices] = React.useState<Device[]>([]);
  const [activeStep, setActiveStep] = React.useState<number>(1);

  const onDataFetched = (array: Device[]) => {
    setLoaded(
      new Map([...loaded].concat(array.map((device) => [device.uid, device])))
    );
    onUpdate && onUpdate();
  };

  const updateSelected = useCallback(() => {
    setSelectedDevices(
      selected.reduce((result: Device[], uid) => {
        const loadedDevice = loaded.get(uid);
        if (loadedDevice) result.push(loadedDevice);
        return result;
      }, [])
    );
  }, [selected, loaded, setSelectedDevices]);

  const onStepChange = useCallback(
    (activeStep: number) => {
      if (activeStep === 1) {
        updateSelected();
      }

      setActiveStep(activeStep);
    },
    [updateSelected, setActiveStep, activeStep]
  );

  useEffect(() => {
    if (activeStep !== 1) return;

    updateSelected();
  }, [updateSelected, activeStep]);

  return (
    <Wizard
      labels={[{ name: selectDevicesTitle }, { name: actionTitle }]}
      onStepChange={onStepChange}
    >
      <DeviceServiceSearch
        deviceFilters={deviceFilters}
        onSelectionChanged={setSelected}
        onDataFetched={onDataFetched}
        initialSelectedRowIds={selected}
        columns={columns}
        autoRefresh={autoRefresh}
        selectionEnabled={true}
      />

      <DeviceSetServiceStatus
        devices={selectedDevices}
        confirmActionText={confirmActionText}
        actionBtnText={actionTitle}
        inService={deviceFilters.inService}
      />
    </Wizard>
  );
};

export default DeviceService;
