import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "components/Button";
import Table from "components/Table/Table";
import { Device } from "Device/Device";
import deviceType from "Device/deviceType";
import { FetchDevicePageOptions, FetchDeviceFilters } from "Device/definitions";
import { useFetchDevicePage } from "Device/requests";
import CellCheckbox from "Device/Service/CellCheckbox";
import HeaderCheckbox from "Device/Service/HeaderCheckbox";
import ToggleAllRowsSelectedHook from "Device/Service/ToggleAllRowsSelectedHook";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  deviceFilters: FetchDeviceFilters;
  onSelectionChanged: (rowIDs: string[]) => void;
  onDataFetched: (devices: Device[]) => void;
  initialSelectedRowIds?: string[];
  nextStep?: () => void;
  columns: Column<Device>[];
  autoRefresh: boolean;
  selectionEnabled: boolean;
};

const DeviceServiceSearch = ({
  deviceFilters,
  onSelectionChanged,
  initialSelectedRowIds,
  onDataFetched,
  nextStep,
  columns,
  autoRefresh,
  selectionEnabled,
}: Props): JSX.Element => {
  const classes = useStyles(),
    [t] = useTranslation("di"),
    [selected, setSelected] = React.useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const filterGroups = {
    type: { label: t("device.type"), options: deviceType },
  };
  const { fetchDevicePage } = useFetchDevicePage();

  const onRowClick = (device: Device): boolean => {
    if (device.editable) {
      return true;
    }

    enqueueSnackbar(t("device.rights.producerOnlyError"), {
      variant: "error",
    });
    return false;
  };

  useEffect(() => {
    onSelectionChanged(selected);
  }, [selected, onSelectionChanged]);

  const fetchData = async (options: FetchDevicePageOptions) => {
    const page = await fetchDevicePage(deviceFilters, options);
    if (page.data === null) return page;
    onDataFetched(page.data);
    return page;
  };

  return (
    <>
      <Table
        columns={columns}
        onFetchData={fetchData}
        onSelectionChanged={setSelected}
        initialSelectedRowIds={initialSelectedRowIds}
        selectionEnabled={selectionEnabled}
        autoRefresh={autoRefresh}
        filterGroups={filterGroups}
        rowIdProp="uid"
        cellCheckbox={CellCheckbox}
        headerCheckbox={HeaderCheckbox}
        toggleAllRowsSelectedHooks={[ToggleAllRowsSelectedHook]}
        onRowClick={onRowClick}
      />

      {selectionEnabled && (
        <div>
          <Button disabled={true} className={classes.button}>
            {t("common:general.back")}
          </Button>

          <Button
            color="primary"
            onClick={nextStep}
            className={classes.button}
            disabled={!selected.length}
          >
            {t("common:general.next")}
          </Button>
        </div>
      )}
    </>
  );
};

export default DeviceServiceSearch;
