import { CircularProgress, InputAdornment } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Alert from "@material-ui/lab/Alert/Alert";
import CustomInputField from "components/Form/CustomInputField";
import FormCheckbox from "components/Form/FormCheckbox";
import {
  Ba,
  Calibration,
  CalibrationSchema,
  JumperBox,
  ResistanceBox,
  ResistanceBoxLabel,
} from "Device/Calibration/Calibration";
import { Composition } from "Device/Calibration/Composition/CompositionSchema";
import VerificationStep, {
  FinishSelfTest,
} from "Device/Calibration/Verification/VerificationStep";
import { Device } from "Device/Device";
import { baFormatter } from "Device/formatters";
import { downloadZklRcReport, getZklRcCalibration } from "Device/requests";
import {
  ZKL_3000,
  ZKL_3000_RC,
  ZKL_3000_RCC,
  ZKL_3000_RC_AFTC,
} from "Device/supportedTypes";
import { Field } from "formik";
import { FormikValues } from "formik/dist/types";
import useResponseValidator from "hooks/ResponseValidator";
import { WebSocketHook } from "hooks/WebSocket";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { boolean, number, string } from "yup";

const MaxShortCircuitWireResistance = 9.5;

// const BaRanges = new Map([
//   [supportedTypes.ZKL_3000, { lower: 0.39, upper: 0.46 }],
//   [supportedTypes.ZKL_3000_RC, { lower: 0.4, upper: 0.47 }],
//   [supportedTypes.ZKL_3000_RCC, { lower: 0.4, upper: 0.47 }],
//   [supportedTypes.ZKL_3000_RC_UK, { lower: 0.38, upper: 0.45 }],
// ]);

type Props = {
  device: Device | null;
  composition: Composition | null;
  resistanceBox: ResistanceBox | null;
  jumperBox: JumperBox | null;
  dropOff: string;
  previousStep?: () => void;
  firstStep?: () => void;
  isActive?: () => void;
  finishSelfTest: FinishSelfTest;
  lastJsonMessage?: WebSocketHook["lastJsonMessage"];
  initialBa: Ba;
};

const ZklRcVerificationStep = ({
  device,
  composition,
  resistanceBox,
  jumperBox,
  isActive,
  dropOff,
  previousStep,
  firstStep,
  finishSelfTest,
  lastJsonMessage,
  initialBa,
}: Props): JSX.Element => {
  const [t] = useTranslation("app");
  const { validate } = useResponseValidator();
  const [updatedBa, setUpdatedBa] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (device == null) return;
    if (!isActive) return;
    (async () => {
      const response = await getZklRcCalibration(device.uid);
      const { calibration } = await validate<Calibration>(
        CalibrationSchema,
        response
      );
      setUpdatedBa(calibration.ba);
    })();
  }, [isActive]);

  const finishDataProvider = ({ remarks }: FormikValues) => {
    return {
      resistanceBox: resistanceBox?.label ?? "Unknown",
      jumperBox: jumperBox ?? "Unknown",
      remark: remarks,
      deviceType: device?.type ?? "Unknown",
    };
  };

  const isNonRC = () => device?.type === ZKL_3000;
  const isRC = () =>
    [ZKL_3000_RC, ZKL_3000_RCC, ZKL_3000_RC_AFTC].includes(device?.type || "");
  const hasAFTC = () => device?.type === ZKL_3000_RC_AFTC;

  return (
    <VerificationStep
      device={device}
      composition={composition}
      initialValues={{
        greenLedOK: false,
        redLedOK: false,
        redLedOnOffOK: false,
        AFTCMOSFETOk: false,
        shortCircuitDetected: false,
        shortCircuitWire: "",
        shortCircuitRemoved: false,
        remark: "",
        baValue: updatedBa ? baFormatter(updatedBa) : baFormatter(initialBa),
        resistanceBox: ResistanceBoxLabel(resistanceBox),
        jumperBox: jumperBox || "",
        dropOff,
      }}
      schema={{
        greenLedOK: boolean().equals([true]),
        redLedOK: boolean().equals([true]),
        redLedOnOffOK: boolean().when({
          is: isNonRC,
          then: boolean().equals([true]),
          otherwise: boolean(),
        }),
        AFTCMOSFETOk: boolean().when({
          is: hasAFTC,
          then: boolean().equals([true]),
          otherwise: boolean(),
        }),
        shortCircuitDetected: boolean().equals([true]),
        shortCircuitWire: number()
          .required()
          .max(MaxShortCircuitWireResistance)
          .min(0),
        shortCircuitRemoved: boolean().when({
          is: isRC,
          then: boolean().equals([true]),
          otherwise: boolean(),
        }),
        remark: string(),
      }}
      finishSelfTest={finishSelfTest}
      finishDataProvider={finishDataProvider}
      previousStep={previousStep}
      firstStep={firstStep}
      lastJsonMessage={lastJsonMessage}
      downloadReport={downloadZklRcReport}
      languages={["en", "nl", "fr", "de"]}
    >
      <FormGroup>
        {updatedBa == undefined ? (
          <Alert severity="info" icon={<CircularProgress size={"1rem"} />}>
            {t("device.calibration.zklRc.refreshingBa")}
          </Alert>
        ) : (
          []
        )}
        <Field
          labelText={t("device.calibration.zklRc.baValue")}
          component={CustomInputField}
          id="baValueVerification"
          name="baValue"
          disabled={true}
          inputProps={{
            type: "number",
          }}
          labelProps={{
            shrink: true,
          }}
          formControlProps={{
            fullWidth: true,
          }}
        />
        <Field
          labelText={t("device.calibration.zklRc.resistanceBox")}
          component={CustomInputField}
          name="resistanceBox"
          id="resistanceBoxVerification"
          disabled={true}
          labelProps={{
            shrink: true,
          }}
          formControlProps={{
            fullWidth: true,
          }}
        />
        <Field
          labelText={t("device.calibration.zklRc.switchBox")}
          component={CustomInputField}
          name="jumperBox"
          id="jumperBoxVerification"
          disabled={true}
          labelProps={{
            shrink: true,
          }}
          formControlProps={{
            fullWidth: true,
          }}
        />
        <Field
          labelText={t("device.calibration.zklRc.calibrationDropOff")}
          component={CustomInputField}
          name="dropOff"
          id="dropOffVerification"
          disabled={true}
          inputProps={{
            endAdornment: <InputAdornment position="end">mOhm</InputAdornment>,
            startAdornment: (
              <InputAdornment position="start">{"<"}</InputAdornment>
            ),
          }}
          labelProps={{
            shrink: true,
          }}
          formControlProps={{
            fullWidth: true,
          }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel component="h3">
          {t("device.calibration.verifyStep.zklRc.duringCalibration")}
        </FormLabel>

        <FormCheckbox
          label={t("device.calibration.verifyStep.zklRc.greenLedOK")}
          name="greenLedOK"
        />

        <FormCheckbox
          label={t("device.calibration.verifyStep.zklRc.redLedOK")}
          name="redLedOK"
        />
      </FormGroup>
      {isNonRC() && (
        <FormGroup>
          <FormCheckbox
            label={t("device.calibration.verifyStep.zklRc.redLedOnOffOK")}
            name="redLedOnOffOK"
          />
        </FormGroup>
      )}
      {hasAFTC() && (
        <FormGroup>
          <FormCheckbox
            label={t("device.calibration.verifyStep.zklRc.AFTCMOSFETOk")}
            name="AFTCMOSFETOk"
          />
        </FormGroup>
      )}
      <FormGroup>
        <FormLabel component="h3">
          {t("device.calibration.verifyStep.zklRc.shortCircuitTest")}
        </FormLabel>

        <FormCheckbox
          label={t("device.calibration.verifyStep.zklRc.shortCircuitPresent")}
          name="shortCircuitDetected"
        />

        <Field
          labelText={t("device.calibration.verifyStep.zklRc.shortCircuitWire")}
          component={CustomInputField}
          id="shortCircuitWire"
          name="shortCircuitWire"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: "number",
            autoComplete: "off",
          }}
        />

        {isRC() && (
          <FormCheckbox
            label={t("device.calibration.verifyStep.zklRc.shortCircuitRemoved")}
            name="shortCircuitRemoved"
          />
        )}
      </FormGroup>
      <Field
        labelText={t("device.calibration.verifyStep.remarks")}
        component={CustomInputField}
        id="remarks"
        name="remarks"
        multiline
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{ multiline: true }}
      />
    </VerificationStep>
  );
};

export default ZklRcVerificationStep;
