import { Grid, Typography } from "@material-ui/core";
import { Done, Clear } from "@material-ui/icons";
import { PreFOTACheckResult } from "Device/Device";
import { TFunction } from "i18next";
import React from "react";

type CheckTooltipDetailProps = {
  t: TFunction;
  result: PreFOTACheckResult;
};

const CheckTooltipDetail = ({ t, result }: CheckTooltipDetailProps) => (
  <div>
    <SingleCheckDetail status={result.isOnline} text={t("di:fota.isOnline")} />
    <SingleCheckDetail
      status={result.isInService}
      text={t("di:fota.inService")}
      optional
    />
    <SingleCheckDetail
      status={result.isKeySwitchOperational}
      text={t("di:fota.keySwitchOperational")}
    />
  </div>
);

type SingleCheckDetailProps = {
  status: boolean;
  text: string;
  // Show only if the status fails
  optional?: boolean;
};

const SingleCheckDetail = ({
  status,
  text,
  optional,
}: SingleCheckDetailProps) => {
  if (optional == true && status) {
    return <></>;
  }
  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item>
        {status ? <Done color={"primary"} /> : <Clear color={"error"} />}
      </Grid>
      <Grid item>
        <Typography>{text}</Typography>
      </Grid>
    </Grid>
  );
};

export default CheckTooltipDetail;
