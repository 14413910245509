import React from "react";

import cx from "classnames";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { Avatar, ListItem, ListItemText } from "@material-ui/core";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import { ExitToApp } from "@material-ui/icons";

import { BgColor } from "layout/Sidebar";
import {
  defaultFont,
  grayColor,
  hexToRgb,
} from "styles/material-dashboard-pro-react";

import { useAuth } from "auth";

export default function User(props: Props): JSX.Element {
  const { profile, signOut } = useAuth(),
    classes = useStyles(),
    [t] = useTranslation("common"),
    { miniActive, bgColor } = props;

  const userWrapperClass =
    classes.user +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white",
    });

  const userDetails =
    classes.userDetails +
    " " +
    cx({
      [classes.userDetailsHidden]: miniActive,
    });

  const collapseItemText =
    classes.itemText +
    " " +
    cx({
      [classes.itemTextMini]: miniActive,
    });

  return (
    <div className={userWrapperClass}>
      <div className={classes.photo}>
        <Avatar src={profile?.picture} className={classes.avatarImg} />
        <div className={userDetails}>
          <span>{profile?.name}</span>
          <div>{profile?.company || ""}</div>
        </div>
      </div>
      <List className={classes.list}>
        <ListItem className={classes.item}>
          <NavLink
            to="#"
            className={classes.itemLink}
            onClick={() => signOut()}
          >
            <ExitToApp className={classes.itemIcon} />
            <ListItemText
              primary={t("auth.logout.menuLabel")}
              disableTypography={true}
              className={collapseItemText}
            />
          </NavLink>
        </ListItem>
      </List>
    </div>
  );
}

type Props = {
  miniActive: boolean;
  bgColor?: BgColor;
};

const useStyles = makeStyles(() => ({
  user: {
    paddingBottom: "10px",
    margin: "10px auto 0",
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      right: "15px",
      height: "1px",
      width: "calc(100% - 30px)",
      backgroundColor: "hsla(0,0%,100%,.3)",
    },
  },
  photo: {
    display: "flex",
    alignItems: "center",
    paddingRight: "10px",
    transition: "all 300ms linear",
    margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    padding: "10px 15px",
    backgroundColor: "transparent",
    ...defaultFont,
    width: "auto",
    "&:hover": {
      outline: "none",
      backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
      boxShadow: "none",
    },
    "&,&:hover,&:focus": {
      color: "inherit",
    },
    paddingLeft: "5px",
  },
  avatarImg: {
    zIndex: 5,
    position: "absolute",
    marginRight: "11px",
  },
  whiteAfter: {
    "&:after": {
      backgroundColor: "hsla(0,0%,71%,.3) !important",
    },
  },
  userDetails: {
    transition: "all 300ms linear",
    opacity: "1",
    transform: "translate3d(0px, 0, 0)",
    minWidth: "140px",
    marginLeft: "55px",
  },
  userDetailsHidden: {
    opacity: 0,
    transform: "translate3d(-25px, 0, 0)",
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    margin: "10px 0 0 0",
    padding: "0",
  },
  itemText: {
    color: "inherit",
    ...defaultFont,
    margin: "0",
    position: "relative",
    transform: "translateX(0px)",
    opacity: "1",
    whiteSpace: "nowrap",
    display: "block",
    transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
    fontSize: "14px",
    lineHeight: "30px",
  },
  itemTextMini: {
    transform: "translate3d(-25px, 0, 0)",
    opacity: "0",
  },

  list: {
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    color: "inherit",
    "&:before,&:after": {
      display: "table",
      content: '" "',
    },
    "&:after": {
      clear: "both",
    },
  },
  itemLink: {
    paddingLeft: "10px",
    paddingRight: "10px",
    transition: "all 300ms linear",
    margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    backgroundColor: "transparent",
    ...defaultFont,
    width: "auto",
    "&:hover": {
      outline: "none",
      backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
      boxShadow: "none",
    },
    "&,&:hover,&:focus": {
      color: "inherit",
    },
  },

  itemIcon: {
    color: "inherit",
    width: "30px",
    height: "24px",
    float: "left",
    position: "inherit",
    top: "3px",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    opacity: "0.8",
  },
}));
