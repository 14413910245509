import {
  Checkbox,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import { SwipeableDrawerProps } from "@material-ui/core/SwipeableDrawer/SwipeableDrawer";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { FilterGroups, SelectedFilters } from "./Table";

export type OnFilterChange = (
  name: string,
  key: string,
  value: string,
  selected: boolean
) => void;

type Props = {
  filterGroups: FilterGroups;
  selectedFilters: SelectedFilters;
  open: boolean;
  onClose: SwipeableDrawerProps["onClose"];
  onOpen: SwipeableDrawerProps["onOpen"];
  onFilterChange: OnFilterChange;
};

const FilterDrawer = ({
  filterGroups,
  selectedFilters = {},
  open = false,
  onClose = () => {
    //noop
  },
  onOpen = () => {
    //noop
  },
  onFilterChange = () => {
    //noop
  },
}: Props): JSX.Element => {
  const [t] = useTranslation("common");

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <div role="presentation">
        <List>
          <ListItem>
            <ListItemText secondary={t("general.filters")} />
          </ListItem>

          <Divider />
          {Object.keys(filterGroups).map((name) => {
            const { label, options } = filterGroups[name];

            return (
              <React.Fragment key={name}>
                <ListItem>
                  <ListItemText primary={label} />
                </ListItem>
                {Object.keys(options).map((key) => {
                  const labelId = `checkbox-list-label-${name}-${key}`;
                  const selected =
                    name in selectedFilters && key in selectedFilters[name];
                  const value = options[key];
                  return (
                    <ListItem
                      button
                      key={`${name}-${key}`}
                      onClick={() =>
                        onFilterChange(name, key, value, !selected)
                      }
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selected}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </ListItemIcon>

                      <ListItemText id={labelId} primary={value} />
                    </ListItem>
                  );
                })}
              </React.Fragment>
            );
          })}
        </List>
      </div>
    </SwipeableDrawer>
  );
};

FilterDrawer.propTypes = {
  filterGroups: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.object.isRequired,
    })
  ).isRequired,
  selectedFilters: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.string).isRequired
  ),
  onFilterChange: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default FilterDrawer;
