import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import { useAuth } from "auth";
import Button from "components/Button";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import Table from "components/Table/Table";
import {
  DurationTestStatus,
  FetchDeviceFilters,
  FetchDevicePageOptions,
} from "Device/definitions";
import { Device } from "Device/Device";
import deviceType from "Device/deviceType";
import Tooltip from "@material-ui/core/Tooltip";
import { useFetchDevicePage } from "Device/requests";
import CellCheckbox from "Device/Service/CellCheckbox";
import HeaderCheckbox from "Device/Service/HeaderCheckbox";
import ToggleAllRowsSelectedHook from "Device/Service/ToggleAllRowsSelectedHook";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  deviceFilters: FetchDeviceFilters;
  onSelectionChanged: (rowIDs: string[]) => void;
  onDataFetched: (devices: Device[]) => void;
  initialSelectedRowIds?: string[];
  nextStep?: (state: number) => void;
  columns: Column<Device>[];
  autoRefresh: boolean;
  setAction: (state: number) => void;
  remarks: string;
  setRemarks: (value: string) => void;
};

const DeviceStartDurationSearch = ({
  deviceFilters,
  onSelectionChanged,
  initialSelectedRowIds,
  onDataFetched,
  nextStep,
  columns,
  autoRefresh,
  setAction,
  remarks,
  setRemarks,
}: Props): JSX.Element => {
  const { profile } = useAuth();
  const classes = useStyles(),
    [t] = useTranslation("di"),
    [selected, setSelected] = React.useState<string[]>([]);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const filterGroups = {
    type: { label: t("device.type"), options: deviceType },
  };
  const { fetchDevicePage } = useFetchDevicePage();
  const onRowClick = (device: Device): boolean => {
    if (device.editable) {
      return true;
    }

    enqueueSnackbar(t("device.rights.producerOnlyError"), {
      variant: "error",
    });
    return false;
  };

  useEffect(() => {
    onSelectionChanged(selected);
    setConfirmed(false);
  }, [selected, onSelectionChanged]);

  const fetchData = async (options: FetchDevicePageOptions) => {
    const page = await fetchDevicePage(deviceFilters, options);
    if (page.data === null) return page;
    onDataFetched(page.data);
    return page;
  };

  return (
    <>
      <Table
        columns={columns}
        onFetchData={fetchData}
        onSelectionChanged={setSelected}
        initialSelectedRowIds={initialSelectedRowIds}
        selectionEnabled={true}
        autoRefresh={autoRefresh}
        filterGroups={filterGroups}
        rowIdProp="uid"
        cellCheckbox={CellCheckbox}
        headerCheckbox={HeaderCheckbox}
        toggleAllRowsSelectedHooks={[ToggleAllRowsSelectedHook]}
        onRowClick={onRowClick}
      />

      <div>
        <Button disabled={true} className={classes.button}>
          {t("common:general.back")}
        </Button>

        <Tooltip
          title={
            profile?.productionOnly
              ? (t("device.rights.noPermissionError") as string)
              : (t("device.durationTest.skipDurationTest") as string)
          }
        >
          <span>
            <Button
              color="primary"
              onClick={
                confirmed
                  ? () => {
                      setAction(DurationTestStatus.DurationTestSkipped);
                      nextStep &&
                        nextStep(DurationTestStatus.DurationTestSkipped);
                    }
                  : () => {
                      setOpen(true);
                    }
              }
              className={classes.button}
              disabled={!selected.length || profile?.productionOnly}
            >
              {t("device.durationTest.skipDurationTest")}
            </Button>
          </span>
        </Tooltip>

        <Button
          color="success"
          onClick={() => {
            setAction(DurationTestStatus.DurationTestInProgress);
            nextStep && nextStep(DurationTestStatus.DurationTestInProgress);
          }}
          className={classes.button}
          disabled={!selected.length}
        >
          {t("device.durationTest.startDurationTest")}
        </Button>
      </div>
      <ConfirmDialog
        onConfirm={() => {
          if (remarks !== "") {
            setError(false);
            setConfirmed(true);
            setAction(DurationTestStatus.DurationTestSkipped);
            return nextStep
              ? nextStep(DurationTestStatus.DurationTestInProgress)
              : null;
          }
          setError(true);
          setOpen(true);
        }}
        open={open}
        setOpen={setOpen}
      >
        <h5>{t("device.durationTest.confirmSkipDurationTest")}</h5>
        <TextField
          error={error && remarks === ""}
          fullWidth
          id="remarks"
          required={true}
          label={t("common:general.remarks")}
          value={remarks}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setRemarks(event.target.value)
          }
          helperText={
            error && remarks === "" && t("device.durationTest.remarksRequired")
          }
        />
      </ConfirmDialog>
    </>
  );
};

export default DeviceStartDurationSearch;
