import axios from "axios";
import { useAuth as useOIDC } from "react-oidc-context";
import * as Sentry from "@sentry/react";

export const useAuth = (): Context => {
  const oidc = useOIDC();

  if (oidc.user) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${oidc.user?.access_token}`;
    Sentry.configureScope((scope) => {
      scope.setUser({ id: oidc.user?.profile.sub });
    });
    const profile: Profile = oidc.user.profile;
    const privileges = oidc.user.profile?.privileges;
    profile.productionOnly = !(
      Array.isArray(privileges) && privileges.includes("pm.service")
    );
    return {
      isAuthenticated: true,
      isLoading: false,
      profile: profile,
      signOut: oidc.signoutRedirect,
    };
  } else {
    delete axios.defaults.headers.common["Authorization"];
    return {
      isAuthenticated: false,
      isLoading: oidc.isLoading,
      signInOIDC: oidc.signinRedirect,
      signOut: async () => {
        /* be quiet eslint, placeholder functions are okay... */
      },
    };
  }
};

export type Context = {
  isAuthenticated: boolean;
  isLoading: boolean;
  profile?: Profile;
  signOut: () => Promise<void>;
  signInOIDC?: () => Promise<void>;
};

export type Profile = {
  name?: string;
  company?: string;
  picture?: string;
  productionOnly?: boolean;
};
