import axios, { AxiosResponse } from "axios";
import { add, formatRFC3339, sub } from "date-fns";

import { Device } from "../Device";
import {
  ZKL_3000_RC,
  ZKL_3000_RC_AFTC,
  ZKL_3000_RCC,
} from "Device/supportedTypes";

// updater determines whether or not the given device can update
export const updater = (device: Device) =>
  [ZKL_3000_RCC, ZKL_3000_RC, ZKL_3000_RC_AFTC].includes(device.type) && update;

const update = (
  uid: Device["uid"],
  installedAt: Date
): Promise<AxiosResponse> =>
  axios.patch(`${process.env.REACT_APP_API_URL}zkl-rc/${uid}/battery-board`, {
    installedAt: formatRFC3339(installedAt),
  });

// installationDate calculates the oldest date on which a battery-board is
// allowed to be installed.
//
// the given years plus one month are seen as the minimum amount of time the
// battery-board must be usable for.
export const installationDate = (years: number): Date =>
  sub(add(new Date(), { years, months: 1 }), lifespan);

// lifespan denotes the time which a battery-board is allowed to be used for.
const lifespan = {
  years: 5,
  months: 6,
};
