import {
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListSubheader,
  MenuItem,
  Select,
} from "@material-ui/core";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "components/Button";

import ConfirmDialog from "components/Dialog/ConfirmDialog";
import { Device } from "Device/Device";
import deviceType from "Device/deviceType";
import EndpointsSchema, { Endpoints } from "Device/Endpoint/EndpointsSchema";
import { Status } from "Device/definitions";
import { fetchEndpoints, pushOut, takeIn } from "Device/requests";
import DeviceListItem from "Device/Service/DeviceListItem";
import useResponseValidator from "hooks/ResponseValidator";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "use-async-effect";

type Props = {
  devices: Device[];
  previousStep?: MouseEventHandler<HTMLButtonElement>;
  confirmActionText: string;
  actionBtnText: string;
  inService: boolean;
};

const DeviceSetServiceStatus = ({
  devices,
  previousStep,
  confirmActionText,
  actionBtnText,
  inService,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [confirmed, setConfirmed] = useState(false);
  const [endpoints, setEndpoints] = useState<Endpoints | null>(null);
  const [endpoint, setEndpoint] = useState("");
  const [open, setOpen] = useState(false);
  const [requests, setRequests] = useState(new Map());
  const [statusBeingUpdated, setStatusBeingUpdated] = useState(false);
  const [touched, setTouched] = useState(false);
  const [t] = useTranslation("common");
  const { validate } = useResponseValidator();

  useAsyncEffect(
    async (isMounted) => {
      if (!isMounted() || !inService || endpoints) return;

      try {
        const response = await fetchEndpoints();

        if (!isMounted()) return;

        const fetched = await validate<Endpoints>(EndpointsSchema, response);
        setEndpoints(fetched);
      } catch {
        // Caught by validate
      }
    },
    [inService, endpoints]
  );

  useEffect(() => {
    setConfirmed(false);
  }, [devices, setConfirmed]);

  const updateServiceStatus = async (uid: string) => {
    const oldRequest = requests.get(uid);
    if (oldRequest && oldRequest.status !== Status.FAILURE) return;
    const timeout = setTimeout(() => {
      setRequests(
        (prevState) =>
          new Map([...prevState, [uid, { status: Status.IN_PROGRESS }]])
      );
    }, 100);
    const action = inService ? pushOut : takeIn;
    const result = await action(uid, endpoint);
    clearTimeout(timeout);
    setRequests((prevState) => new Map([...prevState, [uid, result]]));
  };

  const updateServiceStatuses = async () => {
    if (statusBeingUpdated) return;
    setStatusBeingUpdated(true);
    await Promise.allSettled(
      devices.map((device) => updateServiceStatus(device.uid))
    );
    setStatusBeingUpdated(false);
  };

  return (
    <>
      <List>
        {devices.map(({ type, serial, label, uid }) => (
          <DeviceListItem
            key={uid}
            serialNr={serial}
            ownerLabel={label}
            deviceTypeName={deviceType[type] ?? type}
            request={requests.get(uid)}
            onRetryClick={() => updateServiceStatus(uid)}
          />
        ))}
      </List>
      {inService && endpoints && (
        <FormControl
          className={classes.selectControl}
          required
          error={touched && !endpoint}
        >
          <InputLabel id="EndpointSelectorLabel">
            {t("device.inService.selectEndpoint")}
          </InputLabel>

          <Select
            className={classes.select}
            labelId="EndpointSelectorLabel"
            id="EndpointSelector"
            value={endpoint}
            onClose={() => setTouched(true)}
            onChange={({ target }) =>
              typeof target.value === "string" && setEndpoint(target.value)
            }
          >
            {endpoints.production.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}

            <ListSubheader>Development Environments</ListSubheader>
            {endpoints.development.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {touched && !endpoint && (
            <FormHelperText>
              {t("device.inService.missingEndpoint")}
            </FormHelperText>
          )}
        </FormControl>
      )}

      <div>
        <Button onClick={previousStep} className={classes.button}>
          {t("general.back")}
        </Button>

        <Button
          color="primary"
          disabled={statusBeingUpdated || (inService && !endpoint)}
          onClick={() => (confirmed ? updateServiceStatuses() : setOpen(true))}
          className={classes.button}
        >
          {actionBtnText}
        </Button>
      </div>

      <ConfirmDialog
        onConfirm={() => {
          setConfirmed(true);
          return updateServiceStatuses();
        }}
        open={open}
        setOpen={setOpen}
      >
        <h5>{confirmActionText}</h5>
      </ConfirmDialog>
    </>
  );
};

export default DeviceSetServiceStatus;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  selectControl: {
    width: "100%",
  },
  select: {
    width: "100%",
  },
}));
