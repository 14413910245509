import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { DataUsage } from "@material-ui/icons";
import { LAYOUT_DEFAULT } from "routes/routes";
import { RT_DEVICE_DURATION_TEST_SUMMARY } from "Device/routes";
import Button from "components/Button";

type Props = {
  row: {
    original: {
      uid: string;
      serial?: string;
    };
  };
};

export default function DurationTestResultButton({ row }: Props): JSX.Element {
  const [t] = useTranslation("common");
  return (
    <Link
      to={generatePath(LAYOUT_DEFAULT + RT_DEVICE_DURATION_TEST_SUMMARY, {
        uid: row.original.uid,
      })}
      target={"_blank"}
      onClick={(e) => {
        e.stopPropagation();
      }}
      aria-label={t("device.durationTest.viewTestResult")}
    >
      <Button
        justIcon={true}
        round
        color="warning"
        size="sm"
        title={t("device.durationTest.viewTestResult")}
      >
        <DataUsage />
      </Button>
    </Link>
  );
}
