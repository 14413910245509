import { Divider } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Check, Error as Err, Info } from "@material-ui/icons";
import * as Sentry from "@sentry/react";
import Button from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import CircularProgressWithLabel from "components/CircularProgressWithLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import config from "config/WebSocket";
import { Device } from "Device/Device";
import deviceType from "Device/deviceType";
import useWebSocket from "hooks/WebSocket";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StepWizardChildProps } from "react-step-wizard";
import {
  dangerColor,
  infoColor,
  successColor,
} from "styles/material-dashboard-pro-react";
import { object, string } from "yup";

type Props = {
  device: Device | null;
} & Partial<StepWizardChildProps>;

const useStyles = makeStyles(() => ({
  listItemIconRoot: {
    justifyContent: "center",
  },
  listItemIconInfo: {
    color: infoColor[0],
  },
  listItemIconSuccess: {
    color: successColor[0],
  },
  listItemIconError: {
    color: dangerColor[0],
  },
  thingsToTryItem: {
    paddingBottom: 0,
  },
}));

type Instruction = { title: string; descr: ReactNode; thingsToTry?: string[] };

const messageSchema = object().shape({
  type: string().required(),
});

const CalibrationStep = ({
  device,
  previousStep,
  nextStep,
}: Props): JSX.Element => {
  const [t] = useTranslation("app");
  const [url, setUrl] = useState<string | null>(null);
  const [running, setRunning] = useState(false);
  const [stopping, setStopping] = useState(false);
  const [instruction, setInstruction] = useState<Instruction | null>(null);
  const onClose = useCallback(() => {
    setRunning(false);
    setStopping(false);
  }, [setRunning, setStopping]);
  const onError = useCallback(() => {
    setRunning(false);
    setInstruction(null);
  }, [setRunning, setInstruction]);
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(url, {
    onClose,
    onError,
  });
  const [progress, setProgress] = useState<number>(0);
  const [complete, setComplete] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const classes = useStyles();
  const start = useCallback(() => {
    if (!device) return;

    setUrl(`${config.url}rdi/${device.uid}/calibrate?t=${Date.now()}`);
    setProgress(0);
    setRunning(true);
    setComplete(false);
    setShowSuccess(false);
    setInstruction({
      title: t("device.calibration.calibrateStep.instructions.started.title"),
      descr: t("device.calibration.calibrateStep.instructions.started.descr"),
    });
  }, [
    setUrl,
    setProgress,
    setRunning,
    setComplete,
    setShowSuccess,
    setInstruction,
    device,
    t,
  ]);
  const stop = useCallback(() => {
    setStopping(true);
    sendJsonMessage({ type: "stop" });
  }, [sendJsonMessage, setStopping]);

  const increaseProgress = useCallback(() => {
    setProgress((prevState) => prevState + 25);
  }, [setProgress]);

  const checkProgress = useCallback(() => {
    if (progress !== 100) return;
    setShowSuccess(true);
  }, [progress, setShowSuccess]);

  useEffect(() => {
    return () => {
      setUrl(null);
    };
  }, [setUrl]);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    try {
      switch (messageSchema.validateSync(lastJsonMessage).type) {
        case "notStarted":
          setInstruction({
            title: t(
              "device.calibration.calibrateStep.instructions.notStarted.title"
            ),
            descr: t(
              "device.calibration.calibrateStep.instructions.notStarted.descr"
            ),
            thingsToTry: t<string, string[]>(
              "device.calibration.calibrateStep.instructions.thingsToTryWhenNotStarted",
              {
                returnObjects: true,
              }
            ),
          });
          break;
        case "startAtCwEnd":
          setInstruction({
            title: t(
              "device.calibration.calibrateStep.instructions.startAtCwEnd.title"
            ),
            descr: t(
              "device.calibration.calibrateStep.instructions.startAtCwEnd.descr"
            ),
          });
          increaseProgress();
          break;
        case "calCwEnd":
          setInstruction({
            title: t(
              "device.calibration.calibrateStep.instructions.calCwEnd.title"
            ),
            descr: t(
              "device.calibration.calibrateStep.instructions.calCwEnd.descr"
            ),
          });
          increaseProgress();
          break;
        case "calCcwEnd":
          setInstruction({
            title: t(
              "device.calibration.calibrateStep.instructions.calCcwEnd.title"
            ),
            descr: t(
              "device.calibration.calibrateStep.instructions.calCcwEnd.descr"
            ),
          });
          increaseProgress();
          break;
        case "notCalibrated":
          setInstruction({
            title: t(
              "device.calibration.calibrateStep.instructions.notCalibrated.title"
            ),
            descr: t(
              "device.calibration.calibrateStep.instructions.notCalibrated.descr"
            ),
            thingsToTry: t<string, string[]>(
              "device.calibration.calibrateStep.instructions.thingsToTryWhenNotCalibrated",
              {
                returnObjects: true,
              }
            ),
          });
          break;
        case "calibrationStopped":
          setInstruction({
            title: t(
              "device.calibration.calibrateStep.instructions.calibrationStopped.title"
            ),
            descr: t(
              "device.calibration.calibrateStep.instructions.calibrationStopped.descr"
            ),
            thingsToTry: t<string, string[]>(
              "device.calibration.calibrateStep.instructions.thingsToTryOnError",
              {
                returnObjects: true,
              }
            ),
          });
          break;
        case "calibrated":
          setInstruction({
            title: t(
              "device.calibration.calibrateStep.instructions.calibrated.title"
            ),
            descr: t(
              "device.calibration.calibrateStep.instructions.calibrated.descr"
            ),
          });
          setComplete(true);
          // The web socket closes the connection right after this which may leave outdated errors.
          // Therefor we want to clear the errors as they might confuse the user in thinking there are still problems.
          increaseProgress();
          break;
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [lastJsonMessage, setInstruction, setComplete]);

  if (!device) return <></>;

  const { type, serial, label, editable } = device;
  return (
    <>
      <Card>
        <CardHeader color="warning" text>
          <CardText color="warning">
            <h4>
              {serial}
              {label ? ` - ${label}` : ""}
            </h4>

            <h4>{deviceType[type] ?? type}</h4>
          </CardText>
        </CardHeader>

        <CardBody>
          <GridContainer>
            <GridItem xs={12} lg={12}>
              <Button
                color="primary"
                onClick={running ? stop : start}
                disabled={stopping}
              >
                {running
                  ? t("device.calibration.calibrateStep.stopCalibration")
                  : t("device.calibration.calibrateStep.startCalibration")}
              </Button>
              <List>
                {instruction && (
                  <ListItem>
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                      {instruction.thingsToTry?.length ? (
                        <Err
                          className={classes.listItemIconError}
                          aria-label={t(
                            "device.calibration.calibrateStep.failure"
                          )}
                        />
                      ) : showSuccess ? (
                        <Check
                          className={classes.listItemIconSuccess}
                          aria-label={t(
                            "device.calibration.calibrateStep.success"
                          )}
                        />
                      ) : (
                        <CircularProgressWithLabel
                          variant={progress ? "determinate" : "indeterminate"}
                          value={progress}
                          onTransitionEnd={checkProgress}
                          id="rdiCalibrateProgress"
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={instruction.title}
                      secondary={instruction.descr}
                    />
                  </ListItem>
                )}
                {instruction?.thingsToTry?.length && (
                  <>
                    <ListItem>
                      <ListItemIcon
                        classes={{ root: classes.listItemIconRoot }}
                      >
                        <Info className={classes.listItemIconInfo} />
                      </ListItemIcon>
                      <ListItemText>
                        {t(
                          "device.calibration.calibrateStep.instructions.thingsToTry"
                        )}
                      </ListItemText>
                    </ListItem>
                    <Divider />
                  </>
                )}
                {instruction?.thingsToTry?.length &&
                  instruction.thingsToTry.map((v) => (
                    <ListItem key={v} className={classes.thingsToTryItem}>
                      <ListItemText inset secondary={v} />
                    </ListItem>
                  ))}
              </List>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <div>
        <Button onClick={previousStep}>{t("common:general.back")}</Button>
        <Button
          onClick={nextStep}
          color="primary"
          disabled={!editable || running || !complete}
        >
          {t("common:general.next")}
        </Button>
      </div>
    </>
  );
};

export default CalibrationStep;
