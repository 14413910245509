import { isEqual } from "lodash";
import { useState } from "react";

export type jobActionState<T> = {
  selected: string[];
  confirm: boolean;
  results: T[];
};

export const defaultActionState: <T>() => jobActionState<T> = () => ({
  selected: [],
  confirm: false,
  results: [],
});

export function useActionState<T>(): [
  jobActionState<T>,
  (ids: string[]) => void,
  () => void,
  (rows: T[]) => void
] {
  const [state, setState] = useState<jobActionState<T>>(defaultActionState());

  const setSelected = (ids: string[]) => {
    // Circumvent re-rendering issues of the table component
    if (isEqual(ids, state.selected)) return;

    setState((prevState) => ({
      ...prevState,
      selected: ids,
    }));
  };

  const toggleConfirm = () => {
    setState((prevState) => ({
      ...prevState,
      confirm: !prevState.confirm,
    }));
  };

  const setResults = (results: T[]) => {
    setState((prevState) => ({
      ...prevState,
      results: results,
    }));
  };

  return [state, setSelected, toggleConfirm, setResults];
}
