import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "components/Button";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import Table from "components/Table/Table";
import { DeviceUpdatesAvailable, FotaDeviceView } from "Device/Device";
import deviceType from "Device/deviceType";
import Tooltip from "@material-ui/core/Tooltip";
import { useFetchFotaDevicePage } from "Device/requests";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  Meta,
  TableToggleAllRowsSelectedProps,
  UseRowSelectInstanceProps,
  UseTableInstanceProps,
} from "react-table";
import { UpdatesContext, updateReducerAction, updates } from "./reducers";
import { isOnlineFormatter, uidFormatter } from "Device/formatters";
import { SupportedTypes } from "Device/supportedTypes";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Cc, { CellCheckboxProps } from "components/Table/Checkbox/CellCheckbox";
import { SwitchBaseProps } from "@material-ui/core/internal/SwitchBase";
import IndeterminateCheckbox from "components/Table/Checkbox/IndeterminateCheckbox";
import groupBy from "lodash/groupBy";
import forEach from "lodash/forEach";
import flatMap from "lodash/flatMap";
import * as semver from "semver";
import { Box } from "@material-ui/core";
import { includes } from "lodash";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  updatesDispatch: React.Dispatch<updateReducerAction>;
  nextStep?: () => void;
};

const SelectUpdatesStep = ({
  updatesDispatch,
  nextStep,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [t] = useTranslation(["di", "app"]);
  const [open, setOpen] = useState<boolean>(false);
  const updates = useContext(UpdatesContext);
  const filterGroups = {
    type: { label: t("device.type"), options: deviceType },
    is_online: {
      label: t("device.isOnlineFilter"),
      options: isOnlineBooleanOptions,
    },
    in_service: {
      label: t("device.inServiceFilter"),
      options: inServiceBooleanOptions,
    },
  };
  const { fetchFotaDevicePage } = useFetchFotaDevicePage();

  const UpdateSelect = ({ value }: { value: FotaDeviceView }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const state = useContext<updates>(UpdatesContext);

    // Given an update can it be selected, it won't be selectable if
    // there is already an update selected for an unique type
    const isUpdateSelectable = (update: DeviceUpdatesAvailable) =>
      value.availableUpdates
        .filter((u) => includes(state[value.uid], u.id))
        .map((u) => u.type)
        .includes(update.type) && !includes(state[value.uid], update.id);

    return (
      <Tooltip
        open={tooltipOpen}
        title={
          value.selectableReason
            ? t(`di:fota.reasonEnum.${value.selectableReason}`)
            : ""
        }
        placement="top"
      >
        <Select
          id="firmaware-updates"
          label="firmaware-updates"
          disabled={value.availableUpdates.length == 0}
          onMouseEnter={() => {
            setTooltipOpen(true);
          }}
          onMouseLeave={() => {
            setTooltipOpen(false);
          }}
          onChange={(event) => {
            setTooltipOpen(false);
            const ids = event.target.value as (number | string)[];
            if (ids.length > 0) {
              updatesDispatch({
                action: "set",
                updates: ids.filter((i) => i != "") as number[],
                device: value.uid,
              });
            } else {
              updatesDispatch({
                action: "unset",
                device: value.uid,
              });
            }
          }}
          renderValue={(selected) => {
            const ids = selected as (string | number)[];
            if (value.availableUpdates.length == 0) {
              return t("di:fota.noUpdates");
            }
            if (ids[0] == "") {
              return t("di:fota.selectUpdates");
            }
            return ids
              .filter((id) => id != "")
              .map((id) => value.availableUpdates.find((u) => u.id == id))
              .map(
                (update) =>
                  `${t(`di:fota.componentsEnum.${update?.type}`)} (${
                    update?.version
                  })`
              )
              .join(", ");
          }}
          onClick={(e) => {
            setTooltipOpen(false);
            e.stopPropagation();
          }}
          style={{ color: !value.selectable ? "grey" : "inherit" }}
          value={state[value.uid] || [""]}
          fullWidth={true}
          displayEmpty
          multiple
        >
          {flatMap(
            groupBy(value.availableUpdates, (u) => u.type),
            (updates, type) => [
              <MenuItem disabled={true} value={type} key={type}>
                <Box sx={{ fontWeight: "bold" }}>
                  {t(`di:fota.componentsEnum.${type}`)}
                </Box>
              </MenuItem>,
              ...updates.map((update) => (
                <MenuItem
                  key={update.id}
                  value={update.id}
                  disabled={!update.selectable || isUpdateSelectable(update)}
                  title={t(`di:fota.componentsEnum.${type}`)}
                >
                  <Box sx={{ paddingLeft: 10 }}>
                    {update.version}{" "}
                    {update.jobId ? `(${t("di:fota.hasActiveJob")})` : ""}
                  </Box>
                </MenuItem>
              )),
            ]
          )}
        </Select>
      </Tooltip>
    );
  };

  const columns = React.useMemo<Column<FotaDeviceView>[]>(
    () => [
      {
        Header: t<string>("di:device.uid"),
        accessor: "uid",
        Cell: uidFormatter,
      },
      {
        Header: t<string>("di:device.serialNr"),
        accessor: "serial",
      },
      {
        Header: t<string>("di:device.ownerLabel"),
        accessor: "label",
      },
      {
        Header: t<string>("di:device.type"),
        accessor: "deviceType",
        Cell: ({ value }: { value: SupportedTypes }) =>
          deviceType[value] ?? value,
      },
      {
        Header: t<string>("di:device.isOnline"),
        accessor: "isOnline",
        Cell: isOnlineFormatter,
      },
      {
        Header: t<string>("di:fota.country"),
        accessor: "country",
      },
      {
        Header: t<string>("di:fota.updatesAvailable"),
        id: "availableUpdates",
        accessor: (row) => row,
        Cell: UpdateSelect,
      },
    ],
    [t]
  );

  // Group by component and pick the latest update available
  const autoPickUpdates = (device: FotaDeviceView): number[] | undefined => {
    let updates: number[] = [];
    const groupedUpdates = groupBy(
      device.availableUpdates.filter((u) => !u.jobId)
    );
    forEach(groupedUpdates, (value) => {
      const latest = value.sort((a, b) =>
        semver.gt(a.version, b.version) ? -1 : 1
      )?.[0]?.id;
      updates = [...updates, latest];
    });
    return updates;
  };

  const SelectDeviceCheckBox = ({
    row,
  }: CellCheckboxProps<FotaDeviceView>): JSX.Element => {
    return (
      <Cc
        row={row}
        disabled={!row.original.selectable}
        onClick={() => {
          const updates = autoPickUpdates(row.original);
          if (updates) {
            updatesDispatch({
              action: "toggle",
              device: row.original.uid,
              updates: updates,
            });
          }
        }}
      />
    );
  };

  /* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
  const ToggleAllDevicesCheckBoxHook = (
    props: TableToggleAllRowsSelectedProps,
    { instance }: Meta<FotaDeviceView>
  ): TableToggleAllRowsSelectedProps => {
    const { flatRows } = instance;
    const totalSelectable = flatRows
      .map((row) => row.original.selectable)
      .filter(Boolean).length;
    const selectedRows = Object.keys(updates).length;
    props.checked = totalSelectable != 0 && totalSelectable === selectedRows;
    return props;
  };

  const ToggleAllDevicesCheckBox = ({
    getToggleAllRowsSelectedProps,
    flatRows,
  }: UseRowSelectInstanceProps<FotaDeviceView> &
    UseTableInstanceProps<FotaDeviceView>): JSX.Element => {
    const [t] = useTranslation(["common"]);

    const onChange: SwitchBaseProps["onChange"] = () => {
      flatRows.forEach((tableRowData) => {
        if (tableRowData.original.selectable) {
          const updates = autoPickUpdates(tableRowData.original);
          if (updates) {
            updatesDispatch({
              action: "toggle",
              device: tableRowData.original.uid,
              updates: updates,
            });
          }
        }
      });
    };

    return (
      <IndeterminateCheckbox
        {...getToggleAllRowsSelectedProps()}
        title={t("common:table.toggleAllRowsSelected")}
        onChange={onChange}
      />
    );
  };

  return (
    <>
      <Table<FotaDeviceView>
        columns={columns}
        onFetchData={fetchFotaDevicePage}
        selectedRows={Object.keys(updates)}
        autoRefresh={true}
        filterGroups={filterGroups}
        rowIdProp="uid"
        cellCheckbox={SelectDeviceCheckBox}
        headerCheckbox={ToggleAllDevicesCheckBox}
        toggleAllRowsSelectedHooks={[ToggleAllDevicesCheckBoxHook]}
        selectionEnabled
      />

      <div>
        <Tooltip title={t("device.fotaJobs.startDownload") as string}>
          <span>
            <Button
              color="success"
              onClick={() => {
                setOpen(true);
              }}
              className={classes.button}
              disabled={Object.keys(updates).length == 0}
            >
              {t("device.fotaJobs.startDownload")}
            </Button>
          </span>
        </Tooltip>
      </div>
      <ConfirmDialog
        onConfirm={() => {
          setOpen(false);
          if (nextStep) nextStep();
        }}
        open={open}
        setOpen={setOpen}
      >
        <h5>{t("device.fotaJobs.confirmFotaDownload")}</h5>
      </ConfirmDialog>
    </>
  );
};

export default SelectUpdatesStep;

export const isOnlineBooleanOptions: Record<string, string> = {
  ["true"]: "ONLINE",
  ["false"]: "NOT ONLINE",
};

export const inServiceBooleanOptions: Record<string, string> = {
  ["true"]: "IN SERVICE",
  ["false"]: "NOT IN SERVICE",
};
