import { Collapse, TableContainer } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";

import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import { LabelDisplayedRowsArgs } from "@material-ui/core/TablePagination/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CellCheckbox, {
  CellCheckboxType,
} from "components/Table/Checkbox/CellCheckbox";
import HeaderCheckbox, {
  HeaderCheckboxType,
} from "components/Table/Checkbox/HeaderCheckbox";

import TablePaginationActions from "components/Table/TablePaginationActions";
import TableToolbar from "components/Table/TableToolbar";
import Muted from "components/Typography/Muted";
import { AppContext } from "context/context";
import { FetchDevicePageOptions } from "Device/definitions";
import useInterval from "hooks/Interval";
import useIsMounted from "hooks/IsMounted";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Column,
  HeaderGroup,
  IdType,
  PluginHook,
  PropGetter,
  Row,
  TableToggleAllRowsSelectedProps,
  useAsyncDebounce,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  UseRowSelectHooks,
  useSortBy,
  useTable,
  UseTableCellProps,
  UseTableHooks,
} from "react-table";
import useAsyncEffect from "use-async-effect";

export type PageOptions = {
  pageIndex: number;
  pageSize: number;
  sortBy: { id: string; desc?: boolean }[];
  globalFilter: string;
};

export type SelectedFilters = Record<string, Record<string, string>>;

type FilterGroup = { label: string; options: Record<string, string> };
export type FilterGroups = Record<string, FilterGroup>;

type CancelledPageResult = {
  rowCount: null;
  data: null;
};

type SuccessfulPageResult<T> = {
  rowCount: number;
  data: T;
};

export type PageResult<T> = CancelledPageResult | SuccessfulPageResult<T>;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  xsSelectRoot: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  xsToolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  selected: {
    flex: "1 1 100%",
  },
  loadingRow: {
    height: theme.spacing(10),
  },
  loadingCell: {
    textAlign: "center",
    verticalAlign: "middle",
  },
  sizeSmall: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  headerCell: {
    fontWeight: "bold",
  },
  removeBorder: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  expansionRow: { paddingBottom: 0, paddingTop: 0 },
  expansionCell: { "&:empty": { padding: 0 } },
}));

const SelectionID = "selection";
export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZES = [5, DEFAULT_PAGE_SIZE, 25, 50];
Object.freeze(PAGE_SIZES);

export const DEBOUNCE_TIMEOUT = 300;
export const PROGRESSBAR_TIMEOUT = 300;

const GetInitialSettings = <D extends Record<string, unknown>>(
  columns: Column<D>[]
) => {
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  let pageIndex = Number(searchParams.get("p"));
  pageIndex = isNaN(pageIndex) || pageIndex <= 1 ? 0 : pageIndex - 1;
  let pageSize = Number(searchParams.get("size"));
  pageSize =
    isNaN(pageSize) || PAGE_SIZES.indexOf(pageSize) === -1
      ? DEFAULT_PAGE_SIZE
      : pageSize;
  const globalFilter = searchParams.get("q");
  const sortBy = searchParams.get("sort");
  const order = searchParams.get("order");

  return {
    pageIndex,
    pageSize,
    globalFilter: React.useMemo(() => globalFilter, [globalFilter]),
    sortBy: React.useMemo(() => {
      if (!sortBy) {
        return [];
      }

      for (const column of columns) {
        if (sortBy !== column.accessor) {
          continue;
        }

        if (order === "desc" || order === "asc") {
          return [{ id: sortBy, desc: order === "desc" }];
        }

        return [{ id: sortBy }];
      }

      return [];
    }, [sortBy, order, columns]),
  };
};

const GetSelectedFilters = (filterGroups: FilterGroups) => {
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);

  const selectedFilters: SelectedFilters = {};
  Object.keys(filterGroups).forEach((name) => {
    const { options } = filterGroups[name];

    selectedFilters[name] = {};
    searchParams.getAll(name).forEach((key) => {
      if (key in options) {
        selectedFilters[name][key] = options[key];
      }
    });
  });

  return selectedFilters;
};

type TableProps<D extends Record<string, unknown>> = {
  rowIdProp?: string;
  columns: Column<D>[];
  selectedRows?: IdType<D>[];
  onFetchData: (options: FetchDevicePageOptions) => Promise<PageResult<D[]>>;
  onSelectionChanged?: (rowIDs: string[]) => void;
  onRowClick?: (originalRowData: D) => boolean;
  initialSelectedRowIds?: string[];
  selectionEnabled?: boolean;
  onRowExpanded?: (row: Row<D>) => JSX.Element;
  autoRefresh?: boolean;
  filterGroups?: FilterGroups;
  containerHeader?: JSX.Element;
  cellCheckbox?: CellCheckboxType<D>;
  headerCheckbox?: HeaderCheckboxType<D>;
  toggleAllRowsSelectedHooks?: Array<
    PropGetter<D, TableToggleAllRowsSelectedProps>
  >;
};

function Table<D extends Record<string, unknown>>({
  columns,
  onFetchData,
  selectedRows,
  onSelectionChanged = () => {
    //noop
  },
  onRowClick = () => true,
  rowIdProp = "id",
  initialSelectedRowIds = [],
  selectionEnabled = false,
  onRowExpanded,
  autoRefresh = false,
  filterGroups = {},
  containerHeader,
  cellCheckbox = CellCheckbox,
  headerCheckbox = HeaderCheckbox,
  toggleAllRowsSelectedHooks = [],
}: TableProps<D>): JSX.Element {
  const [skipPageReset, setSkipPageReset] = React.useState(false),
    [isLoading, setIsLoading] = React.useState(false),
    [rowCount, setRowCount] = React.useState(-1),
    [pageCount, setPageCount] = React.useState(0),
    [data, setData] = React.useState(React.useMemo<D[]>((): D[] => [], [])),
    { enqueueSnackbar } = useSnackbar(),
    [t] = useTranslation(["common"]),
    isMounted = useIsMounted(),
    initialTableArgs: PluginHook<D>[] = [useGlobalFilter, useSortBy],
    theme = useTheme(),
    smOrLarger = useMediaQuery(theme.breakpoints.up("sm")),
    { ps } = useContext(AppContext);
  const [isAutoRefreshRunning, setIsAutoRefreshRunning] =
    React.useState(autoRefresh);
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters>(
    GetSelectedFilters(filterGroups)
  );
  const history = useHistory();

  const getRowId = React.useCallback((row) => row[rowIdProp], [rowIdProp]);

  if (onRowExpanded) {
    initialTableArgs.push(useExpanded);
  }

  initialTableArgs.push(usePagination);

  if (selectionEnabled) {
    initialTableArgs.push(
      useRowSelect,
      (hooks: UseTableHooks<D> & UseRowSelectHooks<D>) => {
        hooks.visibleColumns.push((columns) => [
          {
            id: SelectionID,
            Header: headerCheckbox,
            Cell: cellCheckbox,
          },
          ...columns,
        ]);
        toggleAllRowsSelectedHooks?.forEach((hook) => {
          hooks.getToggleAllRowsSelectedProps.push(hook);
        });
      }
    );
  }

  let i: Record<string, boolean> = {};
  for (const id of initialSelectedRowIds) {
    i = { ...i, ...{ [id]: true } };
  }

  const {
      getTableProps,
      headerGroups,
      prepareRow,
      page,
      gotoPage,
      setPageSize,
      setGlobalFilter,
      visibleColumns,
      state: { pageIndex, pageSize, sortBy, globalFilter, selectedRowIds },
    } = useTable<D>(
      {
        columns,
        data,
        getRowId,
        autoResetPage: !skipPageReset,
        autoResetSortBy: !skipPageReset,
        autoResetGlobalFilter: !skipPageReset,
        autoResetRowState: !skipPageReset,
        autoResetSelectedRows: !skipPageReset,
        manualPagination: true,
        pageCount: pageCount,
        manualSortBy: true,
        manualGlobalFilter: true,
        useControlledState: (state) => {
          return useMemo(
            () => ({
              ...state,
              selectedRowIds: selectedRows
                ? selectedRows.reduce(
                    (acc, item) => ({ ...acc, [item]: true }),
                    {} as Record<IdType<D>, boolean>
                  )
                : state.selectedRowIds,
            }),
            [state, selectedRows]
          );
        },
        initialState: {
          ...GetInitialSettings(columns),
          selectedRowIds: i,
        },
      },
      ...initialTableArgs
    ),
    classes = useStyles();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    gotoPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(Number(event.target.value));
  };

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    disableLoadingAnimation = false,
  }: PageOptions & { disableLoadingAnimation?: boolean }) => {
    if (isMounted()) {
      setIsAutoRefreshRunning(false);
      setSkipPageReset(true);
      let timeout;
      if (!disableLoadingAnimation) {
        timeout = setTimeout(() => {
          if (isMounted()) {
            setIsLoading(true);
          }
        }, PROGRESSBAR_TIMEOUT);
      }

      try {
        const { rowCount, data } = await onFetchData({
          pageIndex,
          pageSize,
          sortBy,
          globalFilter,
          selectedFilters,
        });

        if (data !== null && rowCount !== null && isMounted()) {
          setData(data);
          setRowCount(rowCount);
          setPageCount(Math.ceil(rowCount / pageSize));

          if (ps) {
            ps.update();
          }
        }
      } catch {
        if (isMounted()) {
          enqueueSnackbar(t("request.failedToRetrieve"), {
            variant: "error",
          });
        }
      }

      typeof timeout === "number" && clearTimeout(timeout);
      if (isMounted()) {
        if (!disableLoadingAnimation) {
          setIsLoading(false);
        }
        setSkipPageReset(false);
        if (autoRefresh) {
          setIsAutoRefreshRunning(true);
        }
      }
    }
  };

  const autoRefreshCallback = React.useCallback(async () => {
    await fetchData({
      pageIndex,
      pageSize,
      sortBy,
      globalFilter,
      disableLoadingAnimation: true,
    });
  }, [pageIndex, pageSize, sortBy, globalFilter, selectedFilters]);
  useInterval(autoRefreshCallback, isAutoRefreshRunning ? 10000 : null);

  // Debounce our onFetchData call for 100ms
  const onFetchDataDebounced = useAsyncDebounce(fetchData, DEBOUNCE_TIMEOUT);
  useAsyncEffect(async () => {
    await onFetchDataDebounced({
      pageIndex,
      pageSize,
      sortBy,
      globalFilter,
    });

    let query = `?p=${pageIndex + 1}&size=${pageSize}`;
    if (globalFilter) {
      query += `&q=${globalFilter}`;
    }

    if (sortBy.length) {
      const [s] = sortBy;
      query += `&sort=${s.id}&order=${s.desc ? "desc" : "asc"}`;
    }

    Object.keys(selectedFilters).forEach((filter) => {
      Object.keys(selectedFilters[filter]).forEach((value) => {
        query += `&${filter}=${value}`;
      });
    });

    history.replace({
      ...history.location,
      search: query,
    });
  }, [
    onFetchDataDebounced,
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    selectedFilters,
  ]);

  const onFilterChange = (
    name: string,
    key: string,
    label: string,
    selected: boolean
  ) => {
    const newSelectedFilters = { ...selectedFilters };
    if (!(name in newSelectedFilters)) {
      newSelectedFilters[name] = {};
    }

    if (selected) {
      newSelectedFilters[name][key] = label;
    } else {
      delete newSelectedFilters[name][key];
    }

    setSelectedFilters(newSelectedFilters);
  };

  React.useEffect(() => {
    onSelectionChanged(Object.keys(selectedRowIds));
  }, [onSelectionChanged, selectedRowIds]);

  const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
    return count !== -1
      ? t("table.pagination.rows", { from, to, count })
      : t("table.pagination.rowsIndeterminate", { from, to });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {containerHeader}
        <TableToolbar
          selectionEnabled={selectionEnabled}
          selectedRowIds={selectedRowIds}
          globalFilter={globalFilter}
          onGlobalFilterChange={setGlobalFilter}
          filterGroups={filterGroups}
          onFilterChange={onFilterChange}
          selectedFilters={selectedFilters}
        />

        <TableContainer>
          <MaUTable {...getTableProps()} size="small">
            <TableHead>
              {headerGroups.map((headerGroup) => (
                // eslint-disable-next-line react/jsx-key
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: HeaderGroup<D>) => (
                    // eslint-disable-next-line react/jsx-key
                    <TableCell
                      {...(column.id === SelectionID
                        ? { ...column.getHeaderProps(), padding: "checkbox" }
                        : {
                            ...column.getHeaderProps(
                              column.getSortByToggleProps()
                            ),
                            title: t("table.toggleSortBy"),
                          })}
                      classes={{ sizeSmall: classes.sizeSmall }}
                      className={classes.headerCell}
                    >
                      {column.render("Header")}

                      {column.id !== SelectionID ? (
                        <TableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        />
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow className={classes.loadingRow}>
                  <TableCell
                    className={classes.loadingCell}
                    colSpan={columns.length + 1}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : data.length ? (
                page.map((row) => {
                  prepareRow(row);

                  return (
                    <React.Fragment key={row.getRowProps().key}>
                      <TableRow
                        {...row.getRowProps()}
                        className={onRowExpanded && classes.removeBorder}
                        onClick={() => {
                          if (onRowClick(row.original) && selectionEnabled) {
                            row.toggleRowSelected(!row.isSelected);
                          }
                        }}
                        hover
                      >
                        {row.cells.map((cell: UseTableCellProps<D>) => {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <TableCell
                              {...cell.getCellProps()}
                              padding={
                                cell.column.id === SelectionID
                                  ? "checkbox"
                                  : "normal"
                              }
                              classes={{ sizeSmall: classes.sizeSmall }}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          );
                        })}
                      </TableRow>

                      {onRowExpanded ? (
                        <TableRow className={classes.expansionRow}>
                          <TableCell
                            colSpan={visibleColumns.length}
                            className={classes.expansionCell}
                          >
                            <Collapse
                              in={row.isExpanded}
                              timeout="auto"
                              unmountOnExit
                            >
                              {onRowExpanded(row)}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </React.Fragment>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + 1}>
                    <Muted>{t("table.noRecordsFound")}</Muted>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MaUTable>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={PAGE_SIZES}
          colSpan={columns.length + 1}
          count={rowCount === -1 ? pageSize * (pageIndex + 1) : rowCount}
          rowsPerPage={pageSize}
          page={pageIndex}
          labelDisplayedRows={labelDisplayedRows}
          labelRowsPerPage={smOrLarger ? t("table.pagination.rowsPerPage") : ""}
          classes={
            smOrLarger
              ? {}
              : {
                  selectRoot: classes.xsSelectRoot,
                  toolbar: classes.xsToolbar,
                }
          }
          SelectProps={{
            inputProps: {
              "aria-label": t("table.pagination.rowsPerPage"),
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
}

Table.defaultProps = {
  rowIdProp: "id",
};

export default Table;
