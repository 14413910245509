import { array, number, object, string } from "yup";

export type ResistanceBox = {
  label: string;
  mOhm: number;
};
export type JumperBox = string;
export type Calibration = {
  calibration: { ba: number; frequency: number };
  resistances: ResistanceBox[];
  jumpers: JumperBox[];
};

export type RssCalibration = {
  resistances: ResistanceBox[];
};

export type Ba = string | number;

export const CalibrationSchema = object().shape({
  calibration: object().shape({
    ba: number().required(),
    frequency: number().required(),
  }),
  resistances: array(
    object().shape({
      label: string().required(),
      mOhm: number().required(),
    })
  ),
  jumpers: array(string()).required(),
});

export const RssCalibrationSchema = object().shape({
  resistances: array(
    object().shape({
      label: string().required(),
      mOhm: number().required(),
    })
  ),
});

export function ResistanceBoxLabel(rb: ResistanceBox | null): string {
  return rb ? `${rb.label} ${rb.mOhm > 0 ? `mOhm (${rb.mOhm})` : ""}` : "";
}
