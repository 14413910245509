import ListFotaJobs from "Device/Fota/jobsList/TableView";

import jobStates from "Device/jobState";
import { useFetchAllJoblist } from "Device/requests";

import React from "react";
import { useTranslation } from "react-i18next";

const ListAllJobs = () => {
  const [t] = useTranslation(["di", "app"]);
  const filterGroups = {
    state: { label: t("fota.state"), options: jobStates },
  };
  const { fetchFotaAllJobList } = useFetchAllJoblist();

  return (
    <ListFotaJobs
      filterGroups={filterGroups}
      fetchJobList={fetchFotaAllJobList}
    />
  );
};

export default ListAllJobs;
