import { Dialog, DialogTitle, ListItem, ListItemText } from "@material-ui/core";
import List from "@material-ui/core/List";
import { useDialog } from "hooks/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";

const languages = ["en", "nl", "fr", "de"] as const;

export type Language = (typeof languages)[number];

export type ReportLanguageDialogProps = {
  onClose: (language: Language) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export type RequiredLanguages = [Language, ...Language[]];

function LanguageList({
  languages,
  handleListItemClick,
}: {
  languages: RequiredLanguages;
  handleListItemClick: (value: Language) => void;
}) {
  const [t] = useTranslation("common");
  return (
    <List>
      {[...languages].map((language) => (
        <ListItem
          button
          onClick={() => handleListItemClick(language)}
          key={language}
        >
          <ListItemText primary={t(`language.code.${language}`)} />
        </ListItem>
      ))}
    </List>
  );
}

function ReportLanguageDialog({
  onClose,
  open,
  setOpen,
  languages,
}: ReportLanguageDialogProps & {
  languages: RequiredLanguages;
}): JSX.Element {
  const [t] = useTranslation("common");

  const handleListItemClick = (value: Language) => {
    onClose(value);
    setOpen(false);
  };

  return (
    <Dialog
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="report-language-dialog"
      open={open}
    >
      <DialogTitle id="report-language-dialog">
        {t("language.select")}
      </DialogTitle>

      <LanguageList
        languages={languages}
        handleListItemClick={handleListItemClick}
      />
    </Dialog>
  );
}

export default ReportLanguageDialog;

export function useReportLanguageDialog(languages: RequiredLanguages) {
  const dialog = useDialog();
  const [t] = useTranslation("common");

  return {
    open: (onItemClick: (value: Language) => void) => {
      dialog.open({
        title: t("language.select"),
        element: (
          <LanguageList
            languages={languages}
            handleListItemClick={(value: Language) => {
              onItemClick(value);
              dialog.close();
            }}
          />
        ),
      });
    },
    close: () => {
      dialog.close();
    },
  };
}
