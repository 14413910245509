import { FetchDeviceFilters } from "Device/definitions";
import DeviceServiceSearch from "Device/Service/DeviceServiceSearch";
import { Device } from "../Device";
import React from "react";
import { Column } from "react-table";

type Props = {
  deviceFilters: FetchDeviceFilters;
  columns: Column<Device>[];
  autoRefresh: boolean;
  onUpdate: () => void;
};

const DeviceCheck = ({
  deviceFilters,
  columns,
  autoRefresh,
  onUpdate,
}: Props): JSX.Element => {
  const [selected, setSelected] = React.useState<string[]>([]);
  const [loaded, setLoaded] = React.useState<Map<string, Device>>(new Map());
  const onDataFetched = (array: Device[]) => {
    setLoaded(
      new Map([...loaded].concat(array.map((device) => [device.uid, device])))
    );
    onUpdate();
  };
  return (
    <DeviceServiceSearch
      deviceFilters={deviceFilters}
      onSelectionChanged={setSelected}
      onDataFetched={onDataFetched}
      initialSelectedRowIds={selected}
      columns={columns}
      autoRefresh={autoRefresh}
      selectionEnabled={false}
    />
  );
};

export default DeviceCheck;
