//TODO fix the state names once the update phase implementation is finalized
export const DownloadQueuedState = "DownloadQueued";
export const DownloadingState = "Downloading";
export const DownloadReadyState = "DownloadReady";
export const DownloadTimeoutState = "DownloadTimeout";
export const UpdateQueuedState = "UpdateQueued";
export const UpdatingState = "Updating";
export const UpdateTimeoutState = "UpdateTimeout";
export const DownloadErrorState = "DownloadError";
export const InstallErrorState = "InstallError";
export const Installed = "Installed";
export const CompleteState = "Confirmed";
export const ConfirmTimeoutState = "ConfirmTimeout";
export const ConfirmErrorState = "ConfirmError";
export const ConfirmtimeElapsedErrorState = "ConfirmtimeElapsed";
export type SupportedStates =
  | typeof DownloadQueuedState
  | typeof DownloadingState
  | typeof DownloadReadyState
  | typeof DownloadTimeoutState
  | typeof UpdateQueuedState
  | typeof UpdatingState
  | typeof UpdateTimeoutState
  | typeof Installed
  | typeof CompleteState
  | typeof DownloadErrorState
  | typeof InstallErrorState
  | typeof ConfirmErrorState
  | typeof ConfirmTimeoutState
  | typeof ConfirmtimeElapsedErrorState;

const jobStates: Record<SupportedStates, string> = {
  [DownloadQueuedState]: "DOWNLOAD QUEUED",
  [DownloadingState]: "DOWNLOADING",
  [DownloadReadyState]: "DOWNLOAD READY",
  [DownloadTimeoutState]: "DOWNLOAD TIMEOUT",
  [UpdateQueuedState]: "UPDATE QUEUED",
  [UpdatingState]: "UPDATING",
  [UpdateTimeoutState]: "UPDATE TIMEOUT",
  [Installed]: "INSTALLED",
  [CompleteState]: "CONFIRMED",
  [DownloadErrorState]: "DOWNLOAD ERROR ",
  [InstallErrorState]: "INSTALL ERROR ",
  [ConfirmErrorState]: "CONFIRM ERROR ",
  [ConfirmTimeoutState]: "CONFIRM TIMEOUT ",
  [ConfirmtimeElapsedErrorState]: "CONFIRMTIME ELAPSED ",
};

export default jobStates;
