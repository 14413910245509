import PerfectScrollbar from "perfect-scrollbar";
import React, { createRef, useEffect } from "react";

type SidebarWrapperProps = {
  className?: string;
  children?: JSX.Element | JSX.Element[];
};

// We've created this component, so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
const SidebarWrapper = (props: SidebarWrapperProps) => {
  const sidebarWrapper = createRef<HTMLDivElement>();
  const { className } = props;
  let { current: ps } = createRef<PerfectScrollbar>();

  useEffect(() => {
    const { current: wrapper } = sidebarWrapper;
    if (navigator.platform.indexOf("Win") > -1 && wrapper) {
      ps = new PerfectScrollbar(wrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    return () => {
      if (navigator.platform.indexOf("Win") > -1 && ps) {
        ps.destroy();
      }
    };
  }, []);

  return (
    <div className={className} ref={sidebarWrapper}>
      {props.children}
    </div>
  );
};

export default SidebarWrapper;
