import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import WizardStep from "components/Wizard/WizardStep";
import { AppContext } from "context/context";
import React, { isValidElement, useContext } from "react";
import StepWizard from "react-step-wizard";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  optional: {
    textAlign: "center",
    display: "block",
  },
}));

type Props = {
  children: React.ReactNode;
  labels?: { name: string; optional?: boolean }[];
  onStepChange?: (stepNr: number) => void;
};

const Wizard = ({
  children,
  labels = [],
  onStepChange = () => {
    //noop
  },
}: Props): JSX.Element => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const { ps } = useContext(AppContext);

  const onStepChangeInternal = ({
    activeStep,
  }: {
    previousStep: number;
    activeStep: number;
  }) => {
    setActiveStep(activeStep);
    onStepChange(activeStep);
    if (ps) {
      ps.update();
    }
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep - 1} alternativeLabel>
        {labels.map(({ name, optional }) => (
          <Step key={name}>
            <StepLabel
              optional={
                optional === true && (
                  <Typography variant="caption" className={classes.optional}>
                    Optional
                  </Typography>
                )
              }
            >
              {name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <StepWizard initialStep={activeStep} onStepChange={onStepChangeInternal}>
        {children
          ? React.Children.map<
              JSX.Element | undefined,
              Exclude<React.ReactNode, null>
            >(children, (child) => {
              if (!isValidElement(child)) return;
              return <WizardStep>{child}</WizardStep>;
            })
          : undefined}
      </StepWizard>
    </div>
  );
};

export default Wizard;
