import React, { MutableRefObject, useEffect } from "react";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";

const useCombinedRefs = <T extends Element>(
  ...refs: (
    | MutableRefObject<T | null>
    | null
    | ((instance: T | null) => void)
  )[]
): React.MutableRefObject<T | null> => {
  const targetRef = React.useRef(null);

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref || !targetRef.current) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

const IndeterminateCheckbox = React.forwardRef<
  HTMLButtonElement,
  CheckboxProps
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef<HTMLButtonElement>(null);
  const combinedRef = useCombinedRefs<
    { indeterminate?: boolean } & HTMLButtonElement
  >(ref, defaultRef);

  useEffect(() => {
    if (combinedRef?.current) {
      combinedRef.current.indeterminate = indeterminate ?? false;
    }
  }, [combinedRef, indeterminate]);

  return <Checkbox ref={combinedRef} {...rest} />;
});

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
};

IndeterminateCheckbox.displayName = "IndeterminateCheckbox";

export default IndeterminateCheckbox;
