import makeStyles from "@material-ui/core/styles/makeStyles";
import { Save } from "@material-ui/icons";
import Button from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import CustomLinearProgress from "components/CustomLinearProgress";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CompositionCheckTable from "Device/Calibration/Composition/CompositionCheckTable";
import { Composition } from "Device/Calibration/Composition/CompositionSchema";
import FinishSnackbar from "Device/Calibration/Verification/FinishSnackbar";
import { Device } from "Device/Device";
import deviceType from "Device/deviceType";
import {
  RequiredLanguages,
  useReportLanguageDialog,
} from "Device/Report/ReportLanguageDialog";
import { postDeviceComposition } from "Device/requests";
import { Form, Formik } from "formik";
import { FormikValues } from "formik/dist/types";
import { WebSocketHook } from "hooks/WebSocket";
import { useSnackbar } from "notistack";
import React, { ReactNode, useCallback, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "use-async-effect";
import { InferType, number, object, string } from "yup";
import { ObjectShape } from "yup/lib/object";

type State = {
  pending: boolean;
  openConfirmationDialog: boolean;
};

type Action =
  | { type: "reset" }
  | { type: "pending"; value: State["pending"] }
  | { type: "openConfirmationDialog"; value: State["openConfirmationDialog"] };

const initialState: State = {
  pending: false,
  openConfirmationDialog: false,
};

const reducer = (state: State, action: Action) => {
  if (action.type === "reset") {
    return initialState;
  }

  const result = { ...state };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  result[action.type] = action.value;
  return result;
};

type FinishData = Record<string, unknown>;

const useStyles = makeStyles((theme) => ({
  progressbar: {
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    marginBottom: 0,
  },
}));

const messageSchema = object().shape({
  type: string().required(),
});

const finishedMessageSchema = messageSchema.concat(
  object().shape({
    type: string().matches(/^finished$/),
    data: number().required(),
  })
);

type FinishedMessage = InferType<typeof finishedMessageSchema>;

export type FinishSelfTest = (jsonMessage: unknown, keep?: boolean) => void;

type Props = {
  device: Device | null;
  composition: Composition | null;
  initialValues: Record<string, unknown>;
  schema: ObjectShape;
  children: ReactNode;
  previousStep?: () => void;
  firstStep?: () => void;
  finishSelfTest: FinishSelfTest;
  finishDataProvider: (values: FormikValues) => FinishData;
  lastJsonMessage?: WebSocketHook["lastJsonMessage"];
  downloadReport: (
    uid: string,
    reportID: number,
    language: string
  ) => Promise<void>;
  languages: RequiredLanguages;
};

const VerificationStep = ({
  device,
  composition,
  initialValues,
  schema,
  children,
  previousStep,
  firstStep,
  finishSelfTest,
  finishDataProvider,
  lastJsonMessage,
  downloadReport,
  languages,
}: Props): JSX.Element => {
  const [t] = useTranslation("app"),
    classes = useStyles(),
    { enqueueSnackbar } = useSnackbar(),
    finishData = useRef<FinishData | undefined>(),
    finishedResolve = useRef<(value?: unknown) => void | undefined>(),
    finishedReject = useRef<(reason?: unknown) => void | undefined>(),
    uid = useRef<string | undefined>(),
    [state, dispatch] = useReducer(reducer, initialState),
    { openConfirmationDialog, pending } = state;
  const dialog = useReportLanguageDialog(languages);

  if (device) {
    uid.current = device.uid;
  }

  const submit = async (values: FormikValues) => {
    try {
      await new Promise((resolve, reject) => {
        dispatch({ type: "openConfirmationDialog", value: true });
        finishedResolve.current = resolve;
        finishedReject.current = reject;
        finishData.current = finishDataProvider(values);
      });
    } catch (e) {
      dispatch({ type: "pending", value: false });
      throw e;
    }
  };

  const onConfirm = () => {
    if (!pending && finishData.current) {
      dispatch({ type: "pending", value: true });
      finishSelfTest({
        type: "confirmed",
        data: finishData.current,
      });
    }
  };

  const onCancel = () => {
    finishedReject.current?.();
  };

  const onFinishedMessage = useCallback(
    async (message: FinishedMessage) => {
      if (!finishedResolve.current) return;

      if (!device || !composition) {
        finishedReject.current?.();
        return;
      }

      try {
        await postDeviceComposition(device.uid, composition.configuration.id);

        finishedResolve.current();
        finishedResolve.current = undefined;
        finishedReject.current = undefined;
        finishData.current = undefined;

        dispatch({ type: "reset" });

        firstStep?.();
        enqueueSnackbar(t("device.calibration.verifyStep.complete"), {
          variant: "success",
          autoHideDuration: 10000,
          action: (
            <FinishSnackbar
              reportID={message.data}
              onDownloadClick={(reportID) => {
                dialog.open((l) => {
                  if (!uid.current) return;
                  downloadReport(uid.current, reportID, l);
                });
              }}
            />
          ),
        });
      } catch {
        finishedReject.current?.();
      }
    },
    [enqueueSnackbar, firstStep, t, device, composition]
  );

  useAsyncEffect(async () => {
    if (!lastJsonMessage) {
      return;
    }
    switch ((await messageSchema.validate(lastJsonMessage)).type) {
      case "finished":
        await onFinishedMessage(
          finishedMessageSchema.validateSync(lastJsonMessage)
        );
        break;
      default:
        // Just to get rid of compiler warning.
        break;
    }
  }, [lastJsonMessage]);

  return (
    <>
      {device && (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={object(schema)}
          enableReinitialize={true}
        >
          {({ isSubmitting }) => (
            <Form>
              <Card>
                <CardHeader color="warning" text>
                  <CardText color="warning">
                    <h4>
                      {device.serial}
                      {device.label ? ` - ${device.label}` : ""}
                    </h4>

                    <h4>{deviceType[device.type] ?? device.type}</h4>
                  </CardText>
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    <GridItem>{children}</GridItem>
                    <GridItem>
                      <CompositionCheckTable composition={composition} />
                    </GridItem>
                  </GridContainer>
                </CardBody>

                {pending && (
                  <CustomLinearProgress
                    className={classes.progressbar}
                    variant="indeterminate"
                    color="primary"
                  />
                )}
              </Card>

              <div>
                <Button onClick={previousStep}>
                  {t("common:general.back")}
                </Button>

                <Button type="submit" disabled={isSubmitting} color="primary">
                  <Save />
                  {t("common:form.submit")}
                </Button>
              </div>

              <ConfirmDialog
                onConfirm={onConfirm}
                onClose={onCancel}
                onCancel={onCancel}
                open={openConfirmationDialog}
                setOpen={(value) => {
                  dispatch({ type: "openConfirmationDialog", value });
                }}
              >
                <h5>{t("device.calibration.verifyStep.confirm")}</h5>
              </ConfirmDialog>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default VerificationStep;
