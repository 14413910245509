import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CancelSharp, CheckCircleSharp } from "@material-ui/icons";
import { parseISO } from "date-fns";
import { Imsi } from "Device/formatters";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  successColor,
  warningColor,
} from "styles/material-dashboard-pro-react";
import { CompositionCheckRow } from "./CompositionCheckRow";
import { Composition } from "./CompositionSchema";
const useStyles = makeStyles(() => ({
  headerCell: {
    fontWeight: "bold",
  },
  updatedAt: {
    marginLeft: "4px",
  },
  modemFirmware: {
    maxWidth: "200px",
  },
  refreshContainer: {
    display: "flex",
    alignItems: "center",
  },
  validIcon: {
    color: successColor[0],
  },
  checkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "50px",
    textAlign: "right",
  },
  warningIcon: {
    color: warningColor[0],
  },
}));

type Props = {
  composition: Composition | null;
  caption?: JSX.Element;
  updatedAtPrefix?: JSX.Element;
};

const CompositionCheckTable = ({
  composition,
  caption,
  updatedAtPrefix,
}: Props): JSX.Element => {
  const [t] = useTranslation("app");
  const classes = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell} component="th">
              <div className={classes.refreshContainer}>
                {updatedAtPrefix}
                <label className={classes.updatedAt}>
                  {t("device.calibration.preFlightStep.updatedAt", {
                    date: composition
                      ? parseISO(
                          composition.composition.updatedAt
                        ).toLocaleString()
                      : t("common:general.notAvailable"),
                    interpolation: { escapeValue: false },
                  })}
                </label>
              </div>
            </TableCell>
            <TableCell
              className={classes.headerCell}
              align="right"
              component="th"
            >
              {t("device.calibration.preFlightStep.valid")}
            </TableCell>
            <TableCell
              className={classes.headerCell}
              align="right"
              component="th"
            >
              {t("device.calibration.preFlightStep.actualValue")}
            </TableCell>
            <TableCell
              className={classes.headerCell}
              align="right"
              component="th"
            >
              {t("device.calibration.preFlightStep.allowedValues")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {composition && (
            <>
              <TableRow>
                <TableCell component="th" scope="row">
                  <div>
                    {t("device.calibration.preFlightStep.check.SIMCardICCID")}
                  </div>
                </TableCell>
                <TableCell align="right">
                  {composition?.iccidStatus.isValid ? (
                    <CheckCircleSharp
                      color="primary"
                      className={classes.validIcon}
                      aria-label="valid-sim"
                    />
                  ) : (
                    <CancelSharp color="error" aria-label="invalid-sim" />
                  )}
                </TableCell>
                <TableCell align="right">
                  {composition?.iccidStatus.isValid
                    ? composition?.iccidStatus.iccid
                    : composition?.iccidStatus.reasons}
                </TableCell>
                <TableCell align="right">
                  {t(
                    "device.calibration.preFlightStep.check.actualCheckForFaultSIM"
                  )}
                  <br />
                  {t("device.calibration.preFlightStep.check.robustSimMessage")}
                </TableCell>
              </TableRow>
              <CompositionCheckRow
                valueKey="imsi"
                composition={composition}
                compositionRenderer={(value) => <Imsi value={value} />}
                configurationRenderer={
                  <>
                    {[
                      ...new Set(composition.configuration.imsi),
                    ].map<JSX.Element>((v) => {
                      return (
                        <div key={v}>
                          <Imsi value={v} />
                        </div>
                      );
                    })}
                  </>
                }
              />
              <CompositionCheckRow
                valueKey="ncmHardware"
                composition={composition}
              />
              <CompositionCheckRow
                valueKey="ncmFirmware"
                composition={composition}
              />
              <CompositionCheckRow
                valueKey="mainHardware"
                composition={composition}
              />
              <CompositionCheckRow
                valueKey="mainFirmware"
                composition={composition}
              />
              <CompositionCheckRow
                valueKey="modemHardware"
                composition={composition}
              />
              <CompositionCheckRow
                valueKey="modemFirmware"
                composition={composition}
                compositionCellProps={{ className: classes.modemFirmware }}
                configurationCellProps={{ className: classes.modemFirmware }}
              />
              <CompositionCheckRow
                valueKey="switchHardware"
                composition={composition}
              />
              <CompositionCheckRow
                valueKey="switchFirmwareControl"
                composition={composition}
              />
              <CompositionCheckRow
                valueKey="switchFirmwareDrive"
                composition={composition}
              />
              <CompositionCheckRow
                valueKey="switchFirmwareMeasure"
                composition={composition}
              />
            </>
          )}
        </TableBody>
        {caption && <caption>{caption}</caption>}
      </Table>
    </TableContainer>
  );
};

export default CompositionCheckTable;
