import { FormControl, InputLabel } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Refresh } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import classNames from "classnames";
import Button from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import CustomLinearProgress from "components/CustomLinearProgress";
import CustomInputField from "components/Form/CustomInputField";
import FormRadioGroup from "components/Form/FormRadioGroup";
import FormSelect, { Item } from "components/Form/FormSelect";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { format, parse, parseISO } from "date-fns";
import CompositionSchema, {
  Composition,
} from "Device/Calibration/Composition/CompositionSchema";
import { Device } from "Device/Device";
import deviceType from "Device/deviceType";
import { dateInputFormat } from "Device/formatters";
import { PatchDevice } from "Device/definitions";
import {
  getDeviceComposition,
  patchDevice,
  updateServiceInterval,
} from "Device/requests";
import { RDI_3000, RSS_3000 } from "Device/supportedTypes";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FormikProps, FormikValues } from "formik/dist/types";
import useDebounce from "hooks/Debounce";
import useResponseValidator from "hooks/ResponseValidator";
import countries from "i18n-iso-countries";
import i18n from "i18next";
import { useSnackbar } from "notistack";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { StepWizardChildProps } from "react-step-wizard";
import {
  dangerColor,
  defaultFont,
  grayColor,
} from "styles/material-dashboard-pro-react";
import useAsyncEffect from "use-async-effect";
import { boolean, date, object, string } from "yup";
import { ObjectShape } from "yup/lib/object";
import * as BatteryBoard from "./battery-board";
import CompositionCheckTable from "./Composition/CompositionCheckTable";

const useStyles = makeStyles((theme) => ({
  headerCell: {
    fontWeight: "bold",
  },
  altFormControl: {
    margin: "0 0 17px 0",
    paddingTop: "27px",
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: 400,
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
  },
  labelRootError: {
    color: dangerColor[0] + " !important",
  },
  progressbar: {
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    marginBottom: 0,
  },
}));

type OnSubmit = () => Promise<unknown>;

type InitialValues = {
  installedDate?: string;
  yearsBetweenMaintenance?: string;
  country: string;
  composition: boolean;
} & Record<string, unknown>;

type Props = {
  device: Device | null;
  setDevice?: Dispatch<SetStateAction<Device | null>>;
  composition: Composition | null;
  setComposition: React.Dispatch<React.SetStateAction<Composition | null>>;
  initValues?: Record<string, unknown>;
  initTouched?: Record<string, unknown>;
  schemaShape?: ObjectShape;
  hasConfigurableServiceInterval: boolean;
  onSubmit?: OnSubmit[];
  children?:
    | ((props: FormikProps<InitialValues>) => React.ReactNode)
    | ReactNode;
} & Partial<StepWizardChildProps>;

const PreFlightStepBase = ({
  device,
  setDevice,
  composition,
  setComposition,
  initValues = {},
  initTouched = {},
  schemaShape = {},
  hasConfigurableServiceInterval,
  onSubmit = [],
  children,
  previousStep,
  nextStep,
}: Props): JSX.Element => {
  const [t] = useTranslation("app");
  const classes = useStyles();
  const { validate } = useResponseValidator();
  const [ignoreValueCheck, setIgnoreValueCheck] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>();
  const [selectedYearsBetweenMaintenance, setSelectedYearsBetweenMaintenance] =
    useState<number | undefined>();
  const [selectedInstalledDate, setSelectedInstalledDate] = useState<
    string | undefined
  >();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgressIndicator, setShowProgressIndicator] =
    React.useState(false);
  const debouncedShowProgressIndicator = useDebounce(
    showProgressIndicator,
    300
  );

  const isProductionEnv = () => {
    return window.location.href.includes("sam.dualinventive.com");
  };

  const updateConfig = useCallback(async () => {
    if (!device) return;
    let country = supportedCountries.length > 0 ? supportedCountries[0] : "";
    if (
      selectedCountry === undefined &&
      supportedCountries.includes(device.country)
    ) {
      country = device.country;
    }

    try {
      const response = await getDeviceComposition(
        device.uid,
        selectedCountry ?? country
      );

      setComposition(await validate(CompositionSchema, response));
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 503) {
        enqueueSnackbar(
          t("device.calibration.preFlightStep.configNotAvailable"),
          {
            autoHideDuration: 5000,
            variant: "error",
          }
        );
      }
      setComposition(null);
    }
  }, [setComposition, device, selectedCountry]);

  useAsyncEffect(async () => {
    if (!device) {
      setComposition(null);
      return;
    }
    await updateConfig();
  }, [device, setComposition, updateConfig]);

  if (!device) return <></>;
  const {
    type,
    batteryBoardDate,
    uid,
    serial,
    label,
    country,
    yearsBetweenMaintenance,
  } = device;
  const initialValues: InitialValues = {
    country: selectedCountry ?? country,
    composition: !!(composition && !composition.mismatches.length),
    ...initValues,
  };
  schemaShape = {
    ...schemaShape,
    country: string().required(),
    composition: !ignoreValueCheck
      ? boolean()
          .equals(
            [true],
            t("device.calibration.preFlightStep.valueChecksError")
          )
          .required()
      : boolean().optional(),
  };

  if (hasConfigurableServiceInterval) {
    initialValues.yearsBetweenMaintenance = (
      selectedYearsBetweenMaintenance ?? yearsBetweenMaintenance
    ).toString();
    schemaShape.yearsBetweenMaintenance = string().oneOf(["2", "4"]).required();
  }

  const minInstalledDate = BatteryBoard.installationDate(
    selectedYearsBetweenMaintenance ?? yearsBetweenMaintenance
  );
  const maxInstalledDate = new Date();
  const updateBatteryBoardDate = BatteryBoard.updater(device);
  if (updateBatteryBoardDate) {
    const installedDate = selectedInstalledDate ?? batteryBoardDate;
    initialValues.installedDate = installedDate
      ? format(parseISO(installedDate), dateInputFormat)
      : "";
    schemaShape.installedDate = date()
      .required()
      .min(
        minInstalledDate,
        t("device.calibration.preFlightStep.replaceBattery")
      );
  }

  // Replaced the nested ternary expression with a switch-case.
  let supportedCountries: string[] = [];
  switch (device?.type) {
    case RSS_3000:
      supportedCountries = ["GB"];
      break;
    case RDI_3000:
      supportedCountries = ["FR", "BE"];
      break;
    default:
      supportedCountries = ["NL", "GB", "FR", "BE", "AU"];
  }

  const countryNames: Item[] = supportedCountries.map((code) => {
    return {
      id: code,
      label: countries.getName(code, i18n.language, {
        select: "official",
      }),
    };
  });

  const submit = async ({
    yearsBetweenMaintenance,
    installedDate,
    country,
  }: FormikValues) => {
    setShowProgressIndicator(true);

    const toUpdate: PatchDevice = { country };
    const promises: Promise<unknown>[] = [patchDevice(device, toUpdate)];
    if (updateBatteryBoardDate)
      promises.push(
        updateBatteryBoardDate(
          uid,
          parse(installedDate, dateInputFormat, new Date())
        )
      );
    if (hasConfigurableServiceInterval)
      promises.push(
        updateServiceInterval(uid, parseInt(yearsBetweenMaintenance))
      );

    for (const onSubmitElement of onSubmit) {
      promises.push(onSubmitElement());
    }

    try {
      await Promise.all(promises);
      nextStep?.();
    } catch {
      // Caught by interceptor
    } finally {
      setShowProgressIndicator(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={object(schemaShape)}
      enableReinitialize={true}
      initialTouched={{
        composition: true,
        yearsBetweenMaintenance: true,
        installedDate: true,
        country: true,
        ...initTouched,
      }}
      initialErrors={{}}
      validateOnMount={true}
    >
      {(fProps) => {
        const { isSubmitting, values, touched, handleChange } = fProps;
        return (
          <Form noValidate={true}>
            <Card>
              <CardHeader color="warning" text>
                <CardText color="warning">
                  <h4>
                    {serial}
                    {label ? ` - ${label}` : ""}
                  </h4>

                  <h4>{deviceType[type] ?? type}</h4>
                </CardText>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={4} lg={3} xl={2}>
                    {updateBatteryBoardDate && (
                      <Field
                        component={CustomInputField}
                        labelText={t(
                          "device.calibration.preFlightStep.installedDate"
                        )}
                        id="installedDate"
                        name="installedDate"
                        labelProps={{
                          shrink: true,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "date",
                          autoComplete: "off",
                          inputProps: {
                            min: format(minInstalledDate, dateInputFormat),
                            max: format(maxInstalledDate, dateInputFormat),
                            onChange: async (
                              e: React.ChangeEvent<{
                                name?: string;
                                value: unknown;
                              }>
                            ) => {
                              handleChange(e);
                              setSelectedInstalledDate(`${e.target.value}`);
                            },
                          },
                        }}
                      />
                    )}
                    <FormSelect
                      items={countryNames}
                      label={t("di:device.country")}
                      name="country"
                      onChange={async (
                        e: React.ChangeEvent<{
                          name?: string;
                          value: unknown;
                        }>
                      ) => {
                        handleChange(e);
                        setSelectedCountry(`${e.target.value}`);
                        setDevice &&
                          setDevice({
                            ...device,
                            country: e.target.value as string,
                          });
                      }}
                    />
                    {hasConfigurableServiceInterval && (
                      <FormRadioGroup
                        controlName="yearsBetweenMaintenance"
                        controlLabelText={t(
                          "device.calibration.preFlightStep.yearsBetweenMaintenance"
                        )}
                        options={["2", "4"]}
                        onChange={async (
                          e: React.ChangeEvent<{
                            name?: string;
                            value: string;
                          }>
                        ) => {
                          handleChange(e);
                          setSelectedYearsBetweenMaintenance(
                            parseInt(e.target.value)
                          );
                        }}
                        disabled={isSubmitting}
                      />
                    )}
                    {typeof children === "function"
                      ? children(fProps)
                      : children}
                  </GridItem>
                  <GridItem xs={12} lg={8} xl={6}>
                    <FormControl
                      className={classes.altFormControl}
                      fullWidth={true}
                    >
                      <InputLabel
                        shrink={true}
                        className={classNames(
                          classes.labelRoot,
                          touched.composition && !values.composition
                            ? classes.labelRootError
                            : null
                        )}
                      >
                        {t("device.calibration.preFlightStep.valueChecks")}
                      </InputLabel>
                      {!isProductionEnv() && (
                        <FormControlLabel
                          label={t(
                            "device.calibration.preFlightStep.ignoreValueCheck"
                          )}
                          control={
                            <Checkbox
                              checked={ignoreValueCheck}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setIgnoreValueCheck(e.target?.checked === true);
                              }}
                              name="ignoreValueCheck"
                            />
                          }
                          name="ignoreValueCheckLabel"
                        />
                      )}

                      <CompositionCheckTable
                        composition={composition}
                        updatedAtPrefix={
                          <Button
                            justIcon={true}
                            round
                            color="primary"
                            size="sm"
                            title={t("common:general.refresh")}
                            onClick={updateConfig}
                          >
                            <Refresh />
                          </Button>
                        }
                        caption={
                          <>
                            <input type="hidden" name="composition" />
                            <ErrorMessage
                              name="composition"
                              render={(errorMessage) => (
                                <FormHelperText error={true}>
                                  {errorMessage}
                                </FormHelperText>
                              )}
                            />
                          </>
                        }
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>

              {isSubmitting && debouncedShowProgressIndicator && (
                <CustomLinearProgress
                  variant="indeterminate"
                  color="primary"
                  className={classes.progressbar}
                />
              )}
            </Card>
            <div>
              <Button onClick={previousStep}>{t("common:general.back")}</Button>
              <Button type="submit" disabled={isSubmitting} color="primary">
                {t("common:general.next")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PreFlightStepBase;
