import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import cx from "classnames";
import Button from "components/Button";
import React, { MouseEventHandler, ReactNode } from "react";
import {
  containerFluid,
  dangerColor,
  defaultBoxShadow,
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  successColor,
  warningColor,
  whiteColor,
} from "styles/material-dashboard-pro-react";

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute" as const,
    width: "100%",
    paddingTop: "10px",
    zIndex: 1029,
    color: grayColor[6],
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
  },
  container: {
    ...containerFluid,
    minHeight: "50px",
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0 !important",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  sidebarMinimize: {
    float: "left" as const,
    padding: "0 0 0 15px",
    display: "block",
    color: grayColor[6],
  },
  sidebarMinimizeRTL: {
    padding: "0 15px 0 0 !important",
  },
  sidebarMiniIcon: {
    width: "20px",
    height: "17px",
  },
}));

type Props = {
  color?: "primary" | "info" | "success" | "warning" | "danger";
  rtlActive?: boolean;
  brandText?: ReactNode;
  miniActive?: boolean;
  handleDrawerToggle?: MouseEventHandler<HTMLButtonElement>;
  sidebarMinimize?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Navbar = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { color, rtlActive, brandText } = props;
  const appBarClasses = color
    ? cx({
        [" " + classes[color]]: color,
      })
    : "";
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>

        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}

          <Button href="#" className={classes.title} color="transparent">
            {brandText}
          </Button>
        </div>

        <Hidden mdUp implementation="css">
          <Button
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
