import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Close } from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import modalStyle from "styles/modalStyle";
import Button from "components/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { AnyObjectSchema } from "yup";
import { FormikHelpers, FormikValues } from "formik/dist/types";
import { TransitionProps } from "@material-ui/core/transitions";

const useStyles = makeStyles((theme) => ({
  ...modalStyle(theme),
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps,
  ref
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type Props = {
  title: string;
  submitText?: string;
  cancelText?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onCancel?: () => void;
  onClose?: () => void;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => void | Promise<void>;
  children: React.ReactNode[] | React.ReactNode;
  validationSchema: AnyObjectSchema;
  initialValues: FormikValues;
};

const FormDialog = function (props: Props): JSX.Element {
  const [t] = useTranslation();
  const {
    title,
    children,
    submitText = t("common:form.submit"),
    onSubmit,
    cancelText = t("common:general.cancel"),
    onCancel = () => {
      //noop
    },
    onClose = () => {
      //noop
    },
    initialValues,
    validationSchema,
    open,
    setOpen,
  } = props;
  const classes = useStyles();

  const onCancelClick = () => {
    setOpen(false);
    onCancel();
  };

  const onCloseInternal = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={onCloseInternal}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          fullWidth={true}
          maxWidth="lg"
        >
          <Form>
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={onCloseInternal}
              >
                <Close className={classes.modalClose} />
              </Button>

              <h4 className={classes.modalTitle}>{title}</h4>
            </DialogTitle>

            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <GridContainer justifyContent="center">
                <GridItem xs={12}>{children}</GridItem>
              </GridContainer>
            </DialogContent>

            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button onClick={onCancelClick}>{cancelText}</Button>

              <Button type="submit" color="success" disabled={isSubmitting}>
                {submitText}
              </Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

FormDialog.propTypes = {
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  children: PropTypes.node.isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default FormDialog;
