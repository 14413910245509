import { TableCell, TableRow, Tooltip } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TableCellProps } from "@material-ui/core/TableCell/TableCell";
import { CancelSharp, CheckCircleSharp, Help } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { successColor } from "styles/material-dashboard-pro-react";
import {
  Composition,
  DeviceCompositionKey,
  DeviceConfiguration,
} from "./CompositionSchema";

const useStyles = makeStyles(() => ({
  iconCell: {
    paddingTop: "20px",
  },
  validIcon: {
    color: successColor[0],
  },
  helpIcon: {
    float: "right",
  },
  checkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type ConfigurationRenderer =
  | JSX.Element
  | ((value: DeviceConfiguration[Props["valueKey"]]) => JSX.Element);

type Props = {
  valueKey: DeviceCompositionKey;
  composition: Composition;
  compositionRenderer?: (value: string) => JSX.Element;
  compositionCellProps?: TableCellProps;
  configurationRenderer?: ConfigurationRenderer;
  configurationCellProps?: TableCellProps;
};

export const CompositionCheckRow = ({
  valueKey,
  composition,
  compositionRenderer,
  configurationRenderer,
  compositionCellProps = {},
  configurationCellProps = {},
}: Props): JSX.Element => {
  const [t] = useTranslation("app");
  const classes = useStyles();
  const { composition: comp, configuration, mismatches } = composition;
  const compositionValue = comp[valueKey];
  const configurationValue = configuration[valueKey];

  if (configurationValue.length === 0) {
    return <></>;
  }

  const hasPercentSign = Array.isArray(configurationValue)
    ? configurationValue.some((v) => v.includes("%"))
    : configurationValue.includes("%");

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <div className={classes.checkContainer}>
          {t(`device.calibration.preFlightStep.check.${valueKey}`)}

          {hasPercentSign && (
            <Tooltip
              title={t<string>("device.calibration.preFlightStep.explanation")}
              arrow
            >
              <Help
                color="action"
                className={classes.helpIcon}
                aria-label="percent-explanation"
              />
            </Tooltip>
          )}
        </div>
      </TableCell>
      <TableCell align="right" className={classes.iconCell}>
        {mismatches.indexOf(valueKey) === -1 ? (
          <CheckCircleSharp
            color="primary"
            className={classes.validIcon}
            aria-label="valid"
          />
        ) : (
          <CancelSharp color="error" aria-label="invalid" />
        )}
      </TableCell>
      <TableCell align="right" {...compositionCellProps}>
        {compositionRenderer
          ? compositionRenderer(compositionValue)
          : compositionValue}
      </TableCell>
      <TableCell align="right" {...configurationCellProps}>
        {renderConfigurationValue(configurationValue, configurationRenderer)}
      </TableCell>
    </TableRow>
  );
};

const renderConfigurationValue = (
  value: string | string[],
  renderer?: ConfigurationRenderer
) => {
  if (!renderer) {
    return !Array.isArray(value) ? (
      value
    ) : (
      <>
        {value.map((v) => (
          <div key={v}>{v}</div>
        ))}
      </>
    );
  } else if (React.isValidElement(renderer)) {
    return renderer;
  } else if (typeof renderer === "function") {
    return renderer(value);
  }
};
