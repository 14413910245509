import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import cx from "classnames";
import Navbar from "components/Navbar";
import Sidebar, { BgColor, Color } from "layout/Sidebar";
import { AppContext } from "context/context";
import getDeviceRoutes, { Routes, RT_DEVICE_INTAKE } from "Device/routes";
import ErrorFallback from "error/ErrorFallback";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import logo from "assets/img/logo-mtinfo-cloud.svg";
import { LAYOUT_DEFAULT } from "routes/routes";
import {
  containerFluid,
  drawerMiniWidth,
  drawerWidth,
  transition,
} from "styles/material-dashboard-pro-react";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "',
    },
  },

  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
  },

  content: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)",
  },

  container: { ...containerFluid },

  map: {
    marginTop: "70px",
  },

  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
  },

  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important",
  },
}));

type Props = {
  rtlActive?: boolean;
} & RouteComponentProps;

const Dashboard = (props: Props): JSX.Element => {
  const { rtlActive } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [color] = React.useState<Color>("blue");
  const [bgColor] = React.useState<BgColor>("black");
  const [t] = useTranslation();
  const routes = getDeviceRoutes();
  const { setPs } = useContext(AppContext);

  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef<HTMLDivElement>();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    let ps: PerfectScrollbar;
    if (navigator.platform.indexOf("Win") > -1 && mainPanel.current) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      setPs(ps);

      if (document.body) {
        document.body.style.overflow = "hidden";
      }
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [setPs]);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getActiveRouteTitle = (routes: Routes): JSX.Element[] => {
    return routes.flatMap((route, key) => {
      if ("collapse" in route && route.collapse && route.views) {
        return getActiveRouteTitle(route.views);
      } else if (route.layout === LAYOUT_DEFAULT) {
        return [
          <Route path={route.layout + route.path} key={key}>
            {route.pageTitle}
          </Route>,
        ];
      }

      return [];
    });
  };
  const getRouteList = (routes: Routes): JSX.Element[] => {
    return routes.flatMap((route, key) => {
      if ("collapse" in route && route.collapse && route.views) {
        return getRouteList(route.views);
      }
      if (route.layout === LAYOUT_DEFAULT) {
        return [
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
          />,
        ];
      }

      return [];
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  const getSidebarRoutes = (routes: Routes) =>
    routes.filter((route) => !("inSidebar" in route));

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={getSidebarRoutes(routes)}
        logoText={t("app:title")}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        rtlActive={rtlActive}
      />

      <div className={mainPanelClasses} ref={mainPanel}>
        <Navbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={<Switch>{getActiveRouteTitle(routes)}</Switch>}
          handleDrawerToggle={handleDrawerToggle}
        />

        <div className={classes.content}>
          <div className={classes.container}>
            <Sentry.ErrorBoundary fallback={ErrorFallback}>
              {
                <Switch>
                  {getRouteList(routes)}

                  <Redirect
                    from={LAYOUT_DEFAULT}
                    to={LAYOUT_DEFAULT + RT_DEVICE_INTAKE}
                  />
                </Switch>
              }
            </Sentry.ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
