import React from "react";
import Paper from "@material-ui/core/Paper";
import { Refresh } from "@material-ui/icons";
import Button from "@material-ui/core/Button";

const ErrorFallback = function (): JSX.Element {
  return (
    <Paper elevation={3}>
      <h1>Oh no! Something bad happened.</h1>

      <h3>Please try refreshing the page.</h3>

      <Button onClick={() => window.location.reload()}>
        Refresh
        <Refresh />
      </Button>
    </Paper>
  );
};

export default ErrorFallback;
