import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "components/Button";
import { FotaBulkCreateView } from "Device/Device";
import deviceType from "Device/deviceType";
import Tooltip from "@material-ui/core/Tooltip";
import { postBulkUpdates } from "Device/requests";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UpdatesContext, updateReducerAction } from "./reducers";
import { uidFormatter } from "Device/formatters";
import { SupportedTypes } from "Device/supportedTypes";
import { Done, Clear } from "@material-ui/icons";
import { Column } from "react-table";
import Table from "components/Table/Table";
import Loader from "components/Loader";
import Alert from "@material-ui/lab/Alert";
import CheckTooltipDetail from "components/Fota/CheckTooltipDetail";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
  },
  table: {
    paddingTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  preFotaTooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(1.5),
    boxShadow: theme.shadows[3],
  },
}));

type Props = {
  updatesDispatch: React.Dispatch<updateReducerAction>;
  nextStep?: () => void;
  previousStep?: () => void;
  isActive?: boolean;
};

const PreDownloadCheckStep = ({
  isActive,
  previousStep,
  updatesDispatch,
}: Props): JSX.Element => {
  const [t] = useTranslation(["di", "app", "common"]);
  const classes = useStyles();
  const updates = useContext(UpdatesContext);
  const [result, setResult] = useState<FotaBulkCreateView[]>([]);

  const columns = React.useMemo<Column<FotaBulkCreateView>[]>(
    () => [
      {
        Header: t<string>("di:device.uid"),
        accessor: "uid",
        Cell: uidFormatter,
      },
      {
        Header: t<string>("di:device.serialNr"),
        accessor: "serial",
      },
      {
        Header: t<string>("di:device.ownerLabel"),
        accessor: "label",
      },
      {
        Header: t<string>("di:device.type"),
        accessor: "type",
        Cell: ({ value }: { value: SupportedTypes }) =>
          deviceType[value] ?? value,
      },
      {
        id: "checkPassed",
        accessor: (row) => row,
        Header: t<string>("di:fota.preFotaCheck"),
        Cell: ({ value }: { value: FotaBulkCreateView }) => (
          <Tooltip
            classes={{ tooltip: classes.preFotaTooltip }}
            title={<CheckTooltipDetail result={value.checkDetail} t={t} />}
            arrow
          >
            {value.checkPassed ? (
              <Done color={"primary"} />
            ) : (
              <Clear color={"error"} />
            )}
          </Tooltip>
        ),
      },
    ],
    [t]
  );

  useEffect(() => {
    if (isActive) {
      const selectedUpdateIds = Object.values(updates).flatMap((u) => u);
      postBulkUpdates(selectedUpdateIds).then((res) => setResult(res));
    }
  }, [isActive]);

  const checkPassedCount = result.filter((e) => e.checkPassed).length;
  const checkFailedCount = result.length - checkPassedCount;

  const handleBackClick = () => {
    setResult([]);
    updatesDispatch({ action: "keep", devices: [] });
    if (previousStep) {
      previousStep();
    }
  };
  return (
    <>
      {checkPassedCount > 0 || checkFailedCount > 0 ? (
        <Alert severity="info">
          {checkPassedCount > 0 &&
            t("di:fota.fotaCheckPassedMessage", {
              count: checkPassedCount,
            })}{" "}
          {checkFailedCount > 0 &&
            t("di:fota.fotaCheckFailedMessage", { count: checkFailedCount })}
        </Alert>
      ) : null}
      {result.length != 0 ? (
        <Table<FotaBulkCreateView>
          columns={columns}
          onFetchData={() =>
            new Promise((res) => {
              res({
                rowCount: result.length,
                data: result,
              });
            })
          }
          autoRefresh={false}
          rowIdProp="uid"
        />
      ) : (
        <Loader />
      )}
      <div>
        <Button onClick={handleBackClick} className={classes.button}>
          {t("common:general.back")}
        </Button>
      </div>
    </>
  );
};

export default PreDownloadCheckStep;
