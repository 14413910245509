import axios, { AxiosRequestConfig, AxiosResponse, Canceler } from "axios";

const useNonConcurrentRequest = function (): {
  request: <T = unknown, R = AxiosResponse<T>, D = unknown>(
    cfg: Omit<AxiosRequestConfig<D>, "cancelToken">
  ) => Promise<R>;
} {
  let canceler: Canceler;

  return {
    request: <T = unknown, R = AxiosResponse<T>, D = unknown>(
      cfg: AxiosRequestConfig<D>
    ): Promise<R> => {
      if (canceler) canceler();
      const { token, cancel } = axios.CancelToken.source();
      canceler = cancel;
      cfg.cancelToken = token;
      return axios.request(cfg);
    },
  };
};

export default useNonConcurrentRequest;
