import { Tab, Tabs } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Error } from "@material-ui/icons";
import { useAuth } from "auth";
import TabPanel from "components/TabPanel";
import { Device } from "Device/Device";
import DeviceInfoButton from "Device/Info/DeviceInfoButton";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import DeviceService from "Device/Service/DeviceService";
import DeviceCheck from "Device/Service/DeviceCheck";
import { Column } from "react-table";
import {
  dangerColor,
  successColor,
  warningColor,
} from "styles/material-dashboard-pro-react";
import GetColumns from "./columns";
import Badge from "@material-ui/core/Badge";
import {
  TransferState,
  DurationTestStatus,
  DefaultFetchDeviceFilters,
} from "Device/definitions";
import { useFetchDevicePage } from "Device/requests";

type Props = {
  autoRefresh?: boolean;
};

const useStyles = makeStyles(() => ({
  listItemIconSuccess: {
    color: successColor[0],
  },
  listItemIconWarning: {
    color: warningColor[0],
  },
  listItemIconError: {
    color: dangerColor[0],
  },
}));

const InServicePage = ({ autoRefresh = true }: Props): JSX.Element => {
  const classes = useStyles();
  const { profile } = useAuth();
  const [t] = useTranslation(["app"]);
  const columns = GetColumns(t);
  const trimmedColumns = columns.slice().splice(0, columns.length - 1);
  const verifyCheckColumns = React.useMemo<Column<Device>[]>(
    () => [
      ...trimmedColumns,
      {
        Header: t<string>("di:device.status"),
        accessor: "mobilizeTimedOut",
        Cell: ({ value }: { value: boolean }) => {
          if (value) {
            return <Error className={classes.listItemIconError} />;
          }
          return <CircularProgress className={classes.listItemIconWarning} />;
        },
      },
      {
        Header: "",
        id: "action",
        Cell: DeviceInfoButton,
      },
    ],
    [t]
  );

  const [tabPanel, setTabPanel] = useState<number>(0);
  const [verifyDeviceCount, setVerifyDeviceCount] = useState<number>(-1);
  const { fetchDevicePage } = useFetchDevicePage();

  const fetchData = async () => {
    const page = await fetchDevicePage(
      {
        ...DefaultFetchDeviceFilters,
        inService: true,
        durationTestStatus: [DurationTestStatus.DurationTestNotStarted],
        transferStatus: [TransferState.IN_PROGRESS],
      },
      {
        pageIndex: 0,
        pageSize: 100,
        sortBy: [],
        globalFilter: "",
        selectedFilters: {},
      }
    );
    if (page?.rowCount) {
      setVerifyDeviceCount(page.rowCount);
    } else {
      setVerifyDeviceCount(0);
    }
  };

  const changeTabPanel = (event: ChangeEvent<unknown>, newValue: number) => {
    setTabPanel(newValue);
  };

  return (
    <div>
      <Tabs value={tabPanel} onChange={changeTabPanel}>
        <Tab label={t("device.inService.pageTitle")} />
        <Tab
          label={
            verifyDeviceCount > 0 ? (
              <Badge badgeContent={verifyDeviceCount} color="error">
                {t("device.inService.verify")}
              </Badge>
            ) : (
              t("device.inService.verify")
            )
          }
        />
      </Tabs>
      <TabPanel value={tabPanel} index={0}>
        <DeviceService
          actionTitle={t("device.inService.takeOutOfService")}
          selectDevicesTitle={t("device.inService.selectDevices")}
          deviceFilters={{
            ...DefaultFetchDeviceFilters,
            inService: true,
            durationTestStatus: !profile?.productionOnly
              ? [
                  DurationTestStatus.DurationTestNotStarted,
                  DurationTestStatus.DurationTestCompleted,
                ]
              : [DurationTestStatus.DurationTestCompleted],
            transferStatus: [TransferState.DOMESTIC, TransferState.FOREIGN],
          }}
          confirmActionText={t("device.inService.willTakeAllOutOfService")}
          columns={columns}
          autoRefresh={autoRefresh}
          onUpdate={fetchData}
        />
      </TabPanel>
      <TabPanel value={tabPanel} index={1}>
        <DeviceCheck
          deviceFilters={{
            ...DefaultFetchDeviceFilters,
            inService: true,
            transferStatus: [TransferState.IN_PROGRESS],
          }}
          columns={verifyCheckColumns}
          autoRefresh={autoRefresh}
          onUpdate={fetchData}
        />
      </TabPanel>
    </div>
  );
};

export default InServicePage;
