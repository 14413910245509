import IconButton from "@material-ui/core/IconButton";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { UseExpandedRowProps } from "react-table";

type Props<D extends Record<string, unknown>> = {
  row: UseExpandedRowProps<D>;
};

const ExpansionCell = <D extends Record<string, unknown>>({
  row,
}: Props<D>): JSX.Element => {
  const [t] = useTranslation(["common"]);

  return (
    <IconButton
      {...row.getToggleRowExpandedProps()}
      size="small"
      title={t("table.toggleRowExpanded")}
      aria-checked={row.isExpanded}
      role="switch"
    >
      {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
    </IconButton>
  );
};

export default ExpansionCell;
