import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { container } from "styles/material-dashboard-pro-react";
import { useAuth } from "react-oidc-context";
import { useSnackbar } from "notistack";
import Loader from "components/Loader";
import * as Sentry from "@sentry/react";

export default function SignInPage(): JSX.Element {
  const classes = useStyles();
  const [t] = useTranslation(["common"]);
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    try {
      auth.signinRedirect();
    } catch (err) {
      Sentry.captureMessage("failed to redirect user to sign in", {
        level: Sentry.Severity.Error,
        extra: { err },
      });
      enqueueSnackbar(t("common:request.serverError"), {
        variant: "error",
      });
    }
  }, []);

  return (
    <div className={classes.container}>
      <Loader />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    ...container,
    zIndex: 4,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
}));
