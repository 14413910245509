import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import FormikI18nInit from "config/FormikI18n";
import en from "i18n-iso-countries/langs/en.json";
import nl from "i18n-iso-countries/langs/nl.json";
import { registerLocale } from "i18n-iso-countries";

/**
 * @see https://github.com/i18next/react-i18next/blob/master/example/react/src/
 */
i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      ns: ["app", "common", "di"],
      lng: "en",
      fallbackLng: "en",
      preload: ["en"],
      defaultNS: "app",
      fallbackNS: "app",
      backend: {
        loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json?v=2.9.6`,
      },
    },
    FormikI18nInit
  );

registerLocale(en);
registerLocale(nl);

export default i18n;
