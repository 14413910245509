import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "components/Button";
import { FOTAJobListView } from "Device/Device";

import Tooltip from "@material-ui/core/Tooltip";
import { postBulkUpdatesForUpdateReady } from "Device/requests";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { uidFormatter } from "Device/formatters";
import { Done, Clear } from "@material-ui/icons";
import Table from "components/Table/Table";
import { Column } from "react-table";
import Loader from "components/Loader";
import CheckTooltipDetail from "components/Fota/CheckTooltipDetail";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
  },
  table: {
    paddingTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  preFotaTooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(1.5),
    boxShadow: theme.shadows[3],
  },
}));

type Props = {
  nextStep?: () => void;
  previousStep?: () => void;
  isActive?: boolean;
  selectedRows: number[];
};

const SendToUpdateQueuedStep = ({
  selectedRows,
  isActive,
  previousStep,
}: Props): JSX.Element => {
  const [t] = useTranslation(["di", "app", "common"]);
  const classes = useStyles();
  const [result, setResult] = useState<FOTAJobListView[]>([]);
  const columns = React.useMemo<Column<FOTAJobListView>[]>(
    () => [
      {
        Header: t<string>("di:device.uid"),
        accessor: "uid",
        Cell: uidFormatter,
      },
      {
        Header: t<string>("di:device.serialNr"),
        accessor: "serial",
      },
      {
        Header: t<string>("di:device.ownerLabel"),
        accessor: "label",
      },
      {
        id: "checkPassed",
        accessor: (row) => row,
        Header: t<string>("di:fota.preFotaCheck"),
        Cell: ({ value }: { value: FOTAJobListView }) => (
          <Tooltip
            classes={{ tooltip: classes.preFotaTooltip }}
            title={<CheckTooltipDetail result={value.check_detail} t={t} />}
            arrow
          >
            {value.check_passed ? (
              <Done color={"primary"} />
            ) : (
              <Clear color={"error"} />
            )}
          </Tooltip>
        ),
      },
    ],
    [t]
  );

  useEffect(() => {
    if (isActive) {
      postBulkUpdatesForUpdateReady(selectedRows).then((res) => {
        if (res.jobs) {
          setResult(res.jobs);
        }
      });
    }
  }, [isActive]);

  const handleBackClick = () => {
    setResult([]);
    if (previousStep) {
      previousStep();
    }
  };

  return (
    <>
      {result.length != 0 ? (
        <Table<FOTAJobListView>
          columns={columns}
          onFetchData={() =>
            new Promise((res) => {
              res({
                rowCount: result.length,
                data: result,
              });
            })
          }
          autoRefresh={false}
          rowIdProp="uid"
        />
      ) : (
        <Loader />
      )}
      <div>
        <Button onClick={handleBackClick} className={classes.button}>
          {t("common:general.back")}
        </Button>
      </div>
    </>
  );
};

export default SendToUpdateQueuedStep;
