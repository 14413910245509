import Wizard from "components/Wizard/Wizard";
import SelectDownloadReadyStep from "Device/Fota/updateReady/SelectDownloadReadyStep";
import SendToUpdateQueuedStep from "Device/Fota/updateReady/SendToUpdateQueuedStep";

import React from "react";

type Props = {
  selectJobsTitle: string;
  actionTitle: string;
  autoRefresh: boolean;
  onUpdate?: () => void;
};

const UpdateReadyFotaJobs = ({
  selectJobsTitle,
  actionTitle,
}: Props): JSX.Element => {
  const [selectedJobs, setSelectedJobs] = React.useState<number[]>([]);

  return (
    <Wizard labels={[{ name: actionTitle }, { name: selectJobsTitle }]}>
      <SelectDownloadReadyStep onSelectedJobs={setSelectedJobs} />
      <SendToUpdateQueuedStep selectedRows={selectedJobs} />
    </Wizard>
  );
};
export default UpdateReadyFotaJobs;
