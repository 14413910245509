import {
  Dialog,
  DialogContent,
  DialogTitle,
  PropTypes,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TransitionProps } from "@material-ui/core/transitions";
import * as React from "react";
import { ReactNode, useContext, useState } from "react";
import modalStyle from "styles/modalStyle";

export interface IDialogOptions {
  className?: string;
  title?: string;
  message?: string;
  element?: ReactNode;
  okColor?: PropTypes.Color;
  cancelColor?: PropTypes.Color;
  hideOk?: boolean;
  hideCancel?: boolean;
  okText?: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
  fullWidth?: boolean;
  fullScreen?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

export const DEFAULT_DIALOG_OPTIONS: IDialogOptions = {};

export interface IDialogContext {
  open: (options: IDialogOptions) => void;
  close: () => void;
}

const DEFAULT_CONTEXT: IDialogContext = {
  open: () => {
    //
  },
  close: () => {
    //
  },
};

export const DialogContext = React.createContext(DEFAULT_CONTEXT);

const useStyles = makeStyles((theme) => ({
  ...modalStyle(theme),
}));

interface Props {
  context?: (context: IDialogContext) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps,
  ref
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const DialogProvider: React.FunctionComponent<Props> = ({
  context,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(DEFAULT_DIALOG_OPTIONS);
  const { title, onCancel, element } = options;

  const classes = useStyles();

  const openHandler = (options: IDialogOptions) => {
    setOptions(options);
    setOpen(true);
  };

  const closeHandler = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const provider: IDialogContext = {
    open: openHandler,
    close: closeHandler,
  };

  if (context) {
    context(provider);
  }

  return (
    <DialogContext.Provider value={provider}>
      {children}

      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          {title}
        </DialogTitle>

        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {element}
        </DialogContent>
      </Dialog>
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  return useContext(DialogContext);
};
