import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import * as Sentry from "@sentry/react";
import Button from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ErrorList from "Device/Calibration/ErrorList";
import { Device } from "Device/Device";
import deviceType from "Device/deviceType";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CalibrationTestListItem from "./CalibrationTestListItem";

type Props = {
  device?: Device | null;
  nextStep?: () => void;
  previousStep?: () => void;
  startSelfTest: () => void;
  stopSelfTest: () => void;
  lastJsonMessage?: Record<string, unknown> | null;
};

type Check = {
  code: string;
  status: number;
  label: string;
  instructions: string;
};

const DeviceCalibrationTestStep = ({
  device,
  previousStep,
  nextStep,
  startSelfTest,
  stopSelfTest,
  lastJsonMessage,
}: Props): JSX.Element => {
  const [t] = useTranslation(["common", "di", "app"]);
  const { enqueueSnackbar } = useSnackbar();
  const [checks, setChecks] = useState<Check[]>([]),
    [errorCodes, setErrorCodes] = useState<number[]>([]),
    [running, setRunning] = useState(false),
    [satisfied, setSatisfied] = useState(false);

  const resetTests = () => {
    setChecks([]);
    setErrorCodes([]);
    setSatisfied(false);
    setRunning(false);
  };

  const onExpectedTestsReceived = useCallback(
    (expectedTests) => {
      setSatisfied(false);
      setChecks(
        expectedTests.map((expectedTest: number) => {
          return {
            code: expectedTest,
            status: -1,
            label: t(`app:device.calibration.test.${expectedTest}`),
            instructions: t(
              `app:device.calibration.testStep.instructions.${expectedTest}`
            ),
          };
        })
      );
    },
    [t, setChecks]
  );

  const onSatisfiedTestsReceived = useCallback(
    (satisfiedChecks) => {
      setChecks((checks) =>
        checks.map((checkItem) => {
          if (satisfiedChecks.indexOf(checkItem.code) > -1)
            checkItem.status = 0;
          return checkItem;
        })
      );
    },
    [setChecks]
  );

  const wsMessageReceived = useCallback(
    (message) => {
      if (message) {
        switch (message.type) {
          case "expected":
            onExpectedTestsReceived(message.data);
            break;
          case "satisfied":
            onSatisfiedTestsReceived(message.data);
            break;
          case "erred":
            if (!Array.isArray(message.data)) return;
            setErrorCodes(message.data);
            break;
          case "finished":
          case "calibrated":
            break;
          default:
            Sentry.captureMessage(JSON.stringify(message));
            break;
        }
      }
    },
    [onExpectedTestsReceived, onSatisfiedTestsReceived, setErrorCodes]
  );

  const selfTestStatus = () => {
    if (running) return t("app:device.calibration.testStep.selfTestRunning");
    return satisfied
      ? t("app:device.calibration.testStep.selfTestDone")
      : t("app:device.calibration.testStep.selfTestNotStarted");
  };

  const stop = () => {
    setChecks(
      checks.map((check) => {
        if (check.status === -1) check.status = 1;
        return check;
      })
    );
    setRunning(false);
    stopSelfTest();
  };

  const start = () => {
    resetTests();
    setRunning(true);
    startSelfTest();
  };

  // Process websocket messages
  useEffect(() => {
    wsMessageReceived(lastJsonMessage);
  }, [lastJsonMessage, wsMessageReceived]);

  // Reset page when the device is set
  useEffect(() => {
    resetTests();
  }, [device]);

  // Recalculate self test status vars
  useEffect(() => {
    const unchecked = checks.reduce((result: string[], { code, status }) => {
      if (status !== 0) result.push(code);
      return result;
    }, []);
    if (checks.length > 0 && unchecked.length === 0) {
      setSatisfied(true);
      setRunning(false);
    }
  }, [checks]);

  useEffect(() => {
    if (satisfied && errorCodes.length === 0) {
      enqueueSnackbar(t("app:device.calibration.testStep.selfTestOK"), {
        variant: "success",
      });
    }
  }, [enqueueSnackbar, errorCodes, satisfied, t]);

  return device ? (
    <>
      <Card>
        <CardHeader color="warning" text>
          <CardText color="warning">
            <h4>
              {device.serial}
              {device.label ? ` - ${device.label}` : ""}
            </h4>

            <h4>{deviceType[device.type] ?? device.type}</h4>
          </CardText>
        </CardHeader>

        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <GridItem xs={12} sm={12} md={12}>
                <Button color="primary" onClick={running ? stop : start}>
                  {running
                    ? t("app:device.calibration.testStep.stopSelfTest")
                    : t("app:device.calibration.testStep.startSelfTest")}
                </Button>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={selfTestStatus()}
                      disableTypography={true}
                    />
                  </ListItem>
                  {checks.map(({ code, label, status, instructions }) => {
                    return (
                      <CalibrationTestListItem
                        key={code}
                        label={label}
                        status={status}
                        description={instructions}
                      />
                    );
                  })}
                </List>
              </GridItem>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <ErrorList id="deviceErrorList" errorCodes={errorCodes} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <div>
        <Button onClick={previousStep} disabled={running}>
          {t("general.back")}
        </Button>

        <Button
          color="primary"
          onClick={nextStep}
          disabled={!satisfied || errorCodes.length > 0}
        >
          {t("common:general.next")}
        </Button>
      </div>
    </>
  ) : (
    <></>
  );
};

export default DeviceCalibrationTestStep;
