import * as Sentry from "@sentry/react";
import Axios, { AxiosError, AxiosInstance } from "axios";
import useRedirect from "hooks/Auth";
import useIsMounted from "hooks/IsMounted";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

const useAxiosInterceptors = function (): {
  addResponseInterceptor: (axios: AxiosInstance) => number;
} {
  const [t] = useTranslation("common"),
    auth = useAuth(),
    { enqueueSnackbar } = useSnackbar(),
    isMounted = useIsMounted();

  return {
    addResponseInterceptor: (axios: AxiosInstance): number =>
      axios.interceptors.response.use(
        (response) => response,
        async (e: AxiosError<string>) => {
          if (!Axios.isCancel(e) && isMounted()) {
            const msg = [];

            if (e.response) {
              const { status, data } = e.response;
              switch (status) {
                case 401: {
                  try {
                    const user = await auth.signinSilent();
                    if (!user) await useRedirect();
                  } catch (err) {
                    console.warn(
                      "Silent sign-in failed, forcefully redirecting",
                      err
                    );
                    await auth.signoutRedirect();
                  }
                  break;
                }
                case 403:
                  msg.push(t("request.accessDenied"));
                  break;
                case 500:
                  msg.push(t("request.serverError"));
                  if (data) msg.push(data);
                  Sentry.captureException(e);
                  break;
                default:
                  msg.push(e.message);
                  if (data) msg.push(data);
                  Sentry.captureException(e);
                  break;
              }
            } else {
              msg.push(t("request.connectionError"));
            }

            if (msg.length) {
              enqueueSnackbar(msg.join("\n"), {
                variant: "error",
                style: { whiteSpace: "pre-line" },
              });
            }
          }

          return Promise.reject(e);
        }
      ),
  };
};

export default useAxiosInterceptors;
