import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import { useAuth } from "auth";
import Button from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ErrorList from "Device/Calibration/ErrorList";
import Tooltip from "@material-ui/core/Tooltip";
import { DurationTestStatus } from "Device/definitions";
import { Device } from "Device/Device";

import {
  DurationTestReport,
  DefaultDurationTestReport,
} from "Device/Report/DurationTestReport";
import {
  transferDurationTestStatus,
  useFetchDurationTestReport,
} from "Device/requests";
import { dateTimeFormatter } from "Device/formatters";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useEffect from "use-async-effect";
import CalibrationTestListItem from "../Calibration/CalibrationTestListItem";
import { useSnackbar } from "notistack";

const MINIMUM_GPS_MESSAGE_COUNT = 6;
const MINIMUM_HEARTBEAT_MESSAGE_COUNT = 480;
const MAXIMUM_DISCONNECTS_ALLOWED = 2;

type Props = {
  device?: Device | null;
};

const DurationTestSummary = ({ device }: Props): JSX.Element => {
  const { profile } = useAuth();
  const [t] = useTranslation(["common", "di", "app"]),
    [durationTestReport, setDurationTestReport] = useState<DurationTestReport>(
      DefaultDurationTestReport
    ),
    [errorCodes, setErrorCodes] = useState<number[]>([]);
  const [verified, setVerified] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>("");
  const { fetchDurationTestReport } = useFetchDurationTestReport();
  const { enqueueSnackbar } = useSnackbar();

  const finishDurationTest = async () => {
    if (device == null) return;

    // remarks are required if all test cases hasn't passed
    if (!durationTestPassed() && remarks === "") {
      return;
    }
    setInProgress(true);
    const result = await transferDurationTestStatus(
      device.uid,
      DurationTestStatus.DurationTestCompleted,
      remarks
    );

    if (result?.status === 0) {
      setVerified(true);
      enqueueSnackbar(t("device.durationTest.finishedTestSuccessfully"), {
        variant: "success",
      });
    } else {
      enqueueSnackbar(t(result?.reason), {
        variant: "error",
      });
    }
    setInProgress(false);
  };

  useEffect(async () => {
    if (!device) return;
    const response = await fetchDurationTestReport(device.uid);
    setDurationTestReport(response);
    setRemarks(response.remarks);
    if (response?.concerns) {
      setErrorCodes(response.concerns.map((concern) => concern.error_code));
    }

    // if duration test is not in verify state disable buttons
    if (response.status !== 2) {
      setVerified(true);
    }
  }, [device, setDurationTestReport, setRemarks]);

  const discardDurationTest = async () => {
    if (device == null) return;
    setInProgress(true);
    const result = await transferDurationTestStatus(
      device.uid,
      DurationTestStatus.DurationTestIgnored
    );
    if (result?.status === 0) {
      setVerified(true);
      enqueueSnackbar(t("device.durationTest.sentToRedoTestSuccessfully"), {
        variant: "success",
      });
    } else {
      enqueueSnackbar(t("device.durationTest.sentToRedoTestError"), {
        variant: "error",
      });
    }
    setInProgress(false);
  };

  const updateRemarks = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemarks(event.target.value);
  };

  const durationTestPassed = () => {
    if (!durationTestReport) return false;
    if (durationTestReport?.noOfGPSMessages < MINIMUM_GPS_MESSAGE_COUNT) {
      return false;
    }
    if (durationTestReport?.noOfHeartbeats < MINIMUM_HEARTBEAT_MESSAGE_COUNT) {
      return false;
    }
    if (durationTestReport?.noOfDisconnects > MAXIMUM_DISCONNECTS_ALLOWED) {
      return false;
    }
    return errorCodes.length == 0;
  };

  return device ? (
    <>
      <Card>
        <CardHeader color="warning" text>
          <CardText color="warning">
            <h4>{t("device.durationTest.durationTestSummary")}</h4>
            <Typography style={{ textAlign: "right" }}>
              {durationTestReport?.startedAt
                ? t("device.report.startedAt") +
                  ": " +
                  dateTimeFormatter(durationTestReport?.startedAt)
                : ""}{" "}
            </Typography>
            <Typography style={{ textAlign: "right" }}>
              {durationTestReport?.updatedAt
                ? t("device.report.endedAt") +
                  ": " +
                  dateTimeFormatter(durationTestReport?.updatedAt)
                : ""}{" "}
            </Typography>
          </CardText>
        </CardHeader>

        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <GridItem xs={12} sm={12} md={12}>
                <List>
                  <ListItem>
                    <ListItemText primary={""} disableTypography={true} />
                  </ListItem>
                  <CalibrationTestListItem
                    key={"gps"}
                    label={durationTestReport?.noOfGPSMessages?.toString()}
                    status={
                      durationTestReport.noOfGPSMessages <
                      MINIMUM_GPS_MESSAGE_COUNT
                        ? 1
                        : 0
                    }
                    description={t("device.durationTest.gpsCount")}
                  />
                  <CalibrationTestListItem
                    key={"heart-beat"}
                    label={durationTestReport?.noOfHeartbeats?.toString()}
                    status={
                      durationTestReport
                        ? durationTestReport?.noOfHeartbeats <
                          MINIMUM_HEARTBEAT_MESSAGE_COUNT
                          ? 1
                          : 0
                        : -1
                    }
                    description={t("device.durationTest.heartBeatCount")}
                  />
                  <CalibrationTestListItem
                    key={"disconnects"}
                    label={durationTestReport?.noOfDisconnects?.toString()}
                    status={
                      durationTestReport
                        ? durationTestReport?.noOfDisconnects >
                          MAXIMUM_DISCONNECTS_ALLOWED
                          ? 1
                          : 0
                        : -1
                    }
                    description={t("device.durationTest.disconnectCount")}
                  />
                </List>
                <List style={{ paddingLeft: "55px" }}>
                  <ListItem>
                    <ListItemText
                      primary={durationTestReport?.minimumRSSIValue?.toString()}
                      secondary={t("device.durationTest.minimumRSSIValue")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={durationTestReport?.maximumRSSIValue?.toString()}
                      secondary={t("device.durationTest.maximumRSSIValue")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={durationTestReport?.minimumRSRPValue?.toString()}
                      secondary={t("device.durationTest.minimumRSRPValue")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={durationTestReport?.maximumRSRPValue?.toString()}
                      secondary={t("device.durationTest.maximumRSRPValue")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={durationTestReport?.minimumAvgRSRQValue?.toString()}
                      secondary={t("device.durationTest.minimumAvgRSRQValue")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={durationTestReport?.maximumAvgRSRQValue?.toString()}
                      secondary={t("device.durationTest.maximumAvgRSRQValue")}
                    />
                  </ListItem>
                </List>
              </GridItem>
              <GridItem>
                <TextField
                  error={!durationTestPassed() && confirmed && remarks === ""}
                  fullWidth
                  id="remarks"
                  required={!durationTestPassed()}
                  label={t("common:general.remarks")}
                  value={remarks}
                  onChange={updateRemarks}
                  helperText={
                    !durationTestPassed() && confirmed && remarks === ""
                      ? t("device.durationTest.remarksRequired")
                      : ""
                  }
                />
              </GridItem>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <ErrorList id="deviceErrorList" errorCodes={errorCodes} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <div>
        <Button
          color="primary"
          onClick={() => discardDurationTest()}
          disabled={durationTestPassed() || verified || inProgress}
        >
          {t("device.durationTest.discardDurationTest")}
        </Button>
        <Tooltip
          title={
            !durationTestPassed() && profile?.productionOnly
              ? (t("device.rights.noPermissionError") as string)
              : (t("device.durationTest.finishDurationTest") as string)
          }
        >
          <span>
            <Button
              color="success"
              onClick={() =>
                durationTestPassed()
                  ? finishDurationTest()
                  : confirmed
                  ? finishDurationTest()
                  : setOpen(true)
              }
              disabled={
                verified ||
                inProgress ||
                (!durationTestPassed() && profile?.productionOnly)
              }
            >
              {t("device.durationTest.finishDurationTest")}
            </Button>
          </span>
        </Tooltip>
      </div>

      <ConfirmDialog
        onConfirm={() => {
          setConfirmed(true);
          return finishDurationTest();
        }}
        open={open}
        setOpen={setOpen}
      >
        <h5>{t("device.durationTest.confirmDurationTestFinish")}</h5>
      </ConfirmDialog>
    </>
  ) : (
    <></>
  );
};

export default DurationTestSummary;
