import { Chip, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { CloseSharp, FilterList, Search } from "@material-ui/icons";
import CustomInput from "components/Form/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FilterDrawer, { OnFilterChange } from "components/Table/FilterDrawer";
import PropTypes from "prop-types";
import React, { KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { useAsyncDebounce, UseRowSelectState } from "react-table";
import { FilterGroups, SelectedFilters } from "./Table";

const useStyles = makeStyles((theme) => ({
  selected: {
    flex: "1 1 100%",
    paddingTop: theme.spacing(1.5),
  },
  search: {
    paddingTop: theme.spacing(0.5),
    width: "100%",
    marginRight: theme.spacing(1.5),
  },
  filterChipContainer: {
    marginTop: theme.spacing(1.5),
  },
  filterChip: {
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
}));

type Props<D extends Record<string, unknown>> = {
  filterGroups: FilterGroups;
  selectedRowIds: UseRowSelectState<D>["selectedRowIds"];
  globalFilter: string;
  selectionEnabled: boolean;
  selectedFilters: SelectedFilters;
  onFilterChange: OnFilterChange;
  onGlobalFilterChange: (filter: string | undefined) => void;
};

const TableToolbar = <D extends Record<string, unknown>>({
  globalFilter,
  onGlobalFilterChange,
  selectedRowIds = {} as UseRowSelectState<D>["selectedRowIds"],
  selectionEnabled = false,
  filterGroups = {},
  onFilterChange = () => {
    //noop
  },
  selectedFilters = {},
}: Props<D>): JSX.Element => {
  const [globalFilterValue, setGlobalFilterValue] =
    React.useState(globalFilter);
  const classes = useStyles();
  const [t] = useTranslation("common");
  const onGlobalChange = useAsyncDebounce((value) => {
    onGlobalFilterChange(value || undefined);
  }, 200);
  const [showSearch, setShowSearch] = React.useState(false);
  const [showFilters, setShowFilters] = React.useState(false);

  const toggleDrawer =
    (show: boolean) => (event: KeyboardEvent<HTMLElement>) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setShowFilters(show);
    };

  return (
    <Toolbar>
      <GridContainer direction="row" justifyContent="flex-end">
        {showSearch ? (
          <GridItem>
            <CustomInput
              formControlProps={{
                className: classes.search,
              }}
              inputProps={{
                type: "search",
                autoFocus: true,
                placeholder: t("general.search"),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={<>{t("general.close")}</>}>
                      <IconButton
                        aria-label={t("general.close")}
                        onClick={() => setShowSearch(false)}
                      >
                        <CloseSharp />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
                value: globalFilterValue || "",
                onChange: (e) => {
                  setGlobalFilterValue(e.target.value);
                  onGlobalChange(e.target.value);
                },
              }}
            />
          </GridItem>
        ) : (
          <>
            {selectionEnabled && (
              <GridItem xs={4}>
                <Typography
                  className={classes.selected}
                  variant="subtitle1"
                  component="div"
                >
                  {t("table.selected", {
                    rowCount: Object.keys(selectedRowIds).length,
                  })}
                </Typography>
              </GridItem>
            )}

            <GridItem
              container
              xs={selectionEnabled ? 8 : 12}
              justifyContent="flex-end"
            >
              {Object.keys(filterGroups).length > 0 && (
                <>
                  <Tooltip title={<>{t("general.filters")}</>}>
                    <IconButton
                      aria-label={t("general.filters")}
                      onClick={() => setShowFilters(true)}
                    >
                      <FilterList />
                    </IconButton>
                  </Tooltip>

                  <FilterDrawer
                    filterGroups={filterGroups}
                    selectedFilters={selectedFilters}
                    onFilterChange={onFilterChange}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    open={showFilters}
                  />
                </>
              )}

              <Tooltip title={<>{t("general.search")}</>}>
                <IconButton
                  aria-label={t("general.search")}
                  onClick={() => setShowSearch(true)}
                >
                  <Search />
                </IconButton>
              </Tooltip>
            </GridItem>

            <GridItem
              container
              xs={12}
              justifyContent="flex-end"
              className={classes.filterChipContainer}
            >
              {Object.keys(selectedFilters).map((name) => {
                const selectedFilter = selectedFilters[name];
                return Object.keys(selectedFilters[name]).map((key) => (
                  <Grid key={key} className={classes.filterChip}>
                    <Chip
                      label={selectedFilter[key]}
                      key={key}
                      onDelete={() => {
                        onFilterChange(name, key, selectedFilter[key], false);
                      }}
                    />
                  </Grid>
                ));
              })}
            </GridItem>
          </>
        )}
      </GridContainer>
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  selectionEnabled: PropTypes.bool,
  selectedRowIds: PropTypes.object,
  globalFilter: PropTypes.string,
  onGlobalFilterChange: PropTypes.func.isRequired,
  filterGroups: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.objectOf(PropTypes.string),
    })
  ),
  onFilterChange: PropTypes.func,
  selectedFilters: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.string).isRequired
  ),
};

export default TableToolbar;
