import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/react";
import { useAuth } from "react-oidc-context";

const useRedirect = async (): Promise<void> => {
  const auth = useAuth();

  try {
    await auth.signinRedirect();
  } catch (err) {
    Sentry.captureMessage("failed to redirect user to sign in", {
      level: Severity.Error,
      extra: { err },
    });

    throw err;
  }
};

export default useRedirect;
