import Cc, { CellCheckboxProps } from "components/Table/Checkbox/CellCheckbox";
import { Device, FOTAJobListView } from "Device/Device";
import React from "react";

const CellCheckbox = ({ row }: CellCheckboxProps<Device>): JSX.Element => {
  return <Cc row={row} disabled={!row.original.editable} />;
};

export const FotaJobCellCheckbox = ({
  row,
}: CellCheckboxProps<FOTAJobListView>): JSX.Element => {
  return <Cc row={row} disabled={!row.original.is_online} />;
};

export default CellCheckbox;
