import React from "react";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Check, Error, HelpOutline } from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {
  dangerColor,
  primaryColor,
  successColor,
  warningColor,
} from "styles/material-dashboard-pro-react";
import { Color } from "@material-ui/lab/Alert/Alert";

const useStyles = makeStyles(() => ({
  listItemIconRoot: {
    justifyContent: "center",
  },
  listItemIconNeutral: {
    color: primaryColor[0],
    height: 20,
  },
  listItemIconSuccess: {
    color: successColor[0],
  },
  listItemIconWarning: {
    color: warningColor[0],
  },
  listItemIconError: {
    color: dangerColor[0],
  },
}));

const Status = {
  IN_PROGRESS: -1,
  SUCCESS: 0,
  FAILURE: 1,
  WARNING: 2,
};

type ValidationData = {
  errors?: {
    code: number;
    descr: string;
  }[];
  warnings?: {
    code: number;
    descr: string;
  }[];
};

type Props = {
  description?: string;
  label: string;
  status: number;
  validationData?: ValidationData;
};

const CalibrationTestListItem = ({
  description,
  label,
  status,
  validationData,
}: Props): JSX.Element => {
  const classes = useStyles();

  const getListIcon = (status: number) => {
    switch (status) {
      case Status.IN_PROGRESS:
        return <CircularProgress className={classes.listItemIconNeutral} />;
      case Status.SUCCESS:
        return <Check className={classes.listItemIconSuccess} />;
      case Status.FAILURE:
        return <Error className={classes.listItemIconError} />;
      case Status.WARNING:
        return <Error className={classes.listItemIconWarning} />;
      default:
        return <HelpOutline />;
    }
  };

  const addList = (code: number, descr: string, severity: Color) => {
    return (
      <ListItem key={`${code}`}>
        <ListItemText
          primary={
            <Alert severity={severity}>
              <AlertTitle>{code}</AlertTitle>
              {descr}
            </Alert>
          }
        />
      </ListItem>
    );
  };

  const addErrorsAndWarnings = (validationData?: ValidationData) => {
    if (!validationData) {
      return null;
    }

    const { errors, warnings } = validationData;
    if (
      (!Array.isArray(errors) || !errors.length) &&
      (!Array.isArray(warnings) || !warnings.length)
    ) {
      return null;
    }

    return (
      <List component="div" disablePadding>
        {Array.isArray(errors)
          ? errors.map(({ code, descr }) => addList(code, descr, "error"))
          : null}
        {Array.isArray(warnings)
          ? warnings.map(({ code, descr }) => addList(code, descr, "warning"))
          : null}
      </List>
    );
  };

  return (
    <>
      <ListItem>
        <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
          {getListIcon(status)}
        </ListItemIcon>

        <ListItemText primary={label} secondary={description} />
      </ListItem>
      {addErrorsAndWarnings(validationData)}
    </>
  );
};

export default CalibrationTestListItem;
