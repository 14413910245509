import List from "@material-ui/core/List";
import CalibrationTestListItem from "Device/Calibration/CalibrationTestListItem";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = { id: string; errorCodes: number[] };

export default function ErrorList({ id, errorCodes }: Props) {
  const [t] = useTranslation("app");

  return (
    <List id={id}>
      <CalibrationTestListItem
        key={"deviceErrors"}
        label={
          errorCodes.length > 0
            ? t("device.calibration.testStep.hasErrors")
            : t("device.calibration.testStep.noErrors")
        }
        status={errorCodes.length > 0 ? 1 : 0}
      />
      {errorCodes.map((code) => {
        return (
          <CalibrationTestListItem
            key={code}
            label={`[${code}]: ${t(`di:rpc.error.${code}`, {
              defaultValue: t("di:rpc.error.undefined"),
            })}`}
            status={1}
          />
        );
      })}
    </List>
  );
}
