import { array, boolean, number, object, string } from "yup";

type DeviceComposition = {
  imsi: string;
  mainHardware: string;
  mainFirmware: string;
  modemHardware: string;
  modemFirmware: string;
  ncmHardware: string;
  ncmFirmware: string;
  switchHardware: string;
  switchFirmwareDrive: string;
  switchFirmwareMeasure: string;
  switchFirmwareControl: string;
};

export type DeviceCompositionKey = keyof DeviceComposition;

export type DeviceConfiguration = {
  id: number;
  imsi: string[];
  mainHardware: string;
  mainFirmware: string[];
  modemHardware: string;
  modemFirmware: string[];
  ncmHardware: string;
  ncmFirmware: string[];
  switchHardware: string[];
  switchFirmwareDrive: string[];
  switchFirmwareMeasure: string[];
  switchFirmwareControl: string[];
};

export type IccidStatus = {
  iccid: string;
  reasons: string[];
  isValid: boolean;
  simValidityDays: number;
  simValidityError: ErrorLog;
  simUtilizedDays: number;
};

export type ErrorLog = {
  is_error: boolean;
  error_code: string;
};
export type Composition = {
  configuration: DeviceConfiguration;
  composition: { updatedAt: string } & DeviceComposition;
  iccidStatus: IccidStatus;
  mismatches: DeviceCompositionKey[];
  ncmType: string;
};

const CompositionSchema = object().shape({
  configuration: object().shape({
    id: number().required(),
    imsi: array().of(string()).min(1).required(),
    mainHardware: string().defined(),
    mainFirmware: array().of(string()),
    modemHardware: string().defined(),
    modemFirmware: array().of(string()),
    ncmHardware: string().defined(),
    ncmFirmware: array().of(string()),
    switchHardware: array().of(string()),
    switchFirmwareDrive: array().of(string()),
    switchFirmwareMeasure: array().of(string()),
    switchFirmwareControl: array().of(string()),
  }),
  composition: object().shape({
    updatedAt: string().required(),
    imsi: string().required(),
    mainHardware: string().defined(),
    mainFirmware: string().defined(),
    modemHardware: string().defined(),
    modemFirmware: string().defined(),
    ncmHardware: string().defined(),
    ncmFirmware: string().defined(),
    switchHardware: string().defined(),
    switchFirmwareDrive: string().defined(),
    switchFirmwareMeasure: string().defined(),
    switchFirmwareControl: string().defined(),
  }),
  iccidStatus: object().shape({
    iccid: string(),
    reason: array().of(string()),
    isvalid: boolean(),
    simValidityDays: number(),
    simValidityError: object().shape({
      is_error: boolean(),
      err_code: string(),
    }),
    simUtilizedDays: number(),
  }),
  mismatches: array().of(
    string().oneOf([
      "imsi",
      "mainHardware",
      "mainFirmware",
      "modemHardware",
      "modemFirmware",
      "ncmHardware",
      "ncmFirmware",
      "switchHardware",
      "switchFirmwareDrive",
      "switchFirmwareMeasure",
      "switchFirmwareControl",
    ])
  ),
});

export default CompositionSchema;
