import { SelfTestReport } from "Device/Report/Rdi/SelfTestReport";
import ReportLanguageDialog from "Device/Report/ReportLanguageDialog";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Column, UseTableRowProps } from "react-table";

import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import ExpansionCell from "components/Table/ExpansionCell";
import Table, { PageOptions } from "components/Table/Table";

import { DateTime } from "Device/formatters";
import { downloadRdiReport, useFetchRdiReportPage } from "Device/requests";
import DownloadDeliveryReportButton from "Device/Info/DownloadDeliveryReportButton";
import DetailPanel from "Device/Info/Rdi/DetailPanel";

export default function Reports() {
  const { uid } = useParams<{ uid: string }>();
  const [t] = useTranslation("app");
  const [showingLanguageSelection, showLanguageSelection] = useState(false);
  const [selectedReport, selectReport] = useState<number>(0);
  const { fetchReportPage } = useFetchRdiReportPage();

  const columns = useMemo<Column<SelfTestReport>[]>(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ExpansionCell,
      },
      {
        Header: t<string>("device.report.createdAt"),
        accessor: "createdAt",
        Cell: DateTime,
      },
      {
        Header: t<string>("device.report.tester"),
        accessor: "tester",
      },
      {
        Header: "",
        id: "action",

        Cell: ({ row }: { row: UseTableRowProps<SelfTestReport> }) => (
          <DownloadDeliveryReportButton
            onClick={() => {
              showLanguageSelection(true);

              selectReport(row.original.id);
            }}
          />
        ),
      },
    ],
    [t]
  );

  const fetchData = async (options: PageOptions) => {
    return await fetchReportPage(uid, options);
  };

  return (
    <>
      <Table
        containerHeader={
          <CardHeader color="warning" text>
            <CardText color="warning">
              <h4>{t("device.report.deliveryReports")}</h4>
            </CardText>
          </CardHeader>
        }
        columns={columns}
        onFetchData={fetchData}
        onRowExpanded={(row: UseTableRowProps<SelfTestReport>) => (
          <DetailPanel row={row} />
        )}
      />

      <ReportLanguageDialog
        setOpen={showLanguageSelection}
        onClose={async (language: string) => {
          if (!selectedReport) return;
          await downloadRdiReport(uid, selectedReport, language);
        }}
        open={showingLanguageSelection}
        languages={["en", "nl", "fr"]}
      />
    </>
  );
}
