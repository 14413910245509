import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  dangerColor,
  grayColor,
  hexToRgb,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
} from "styles/material-dashboard-pro-react";

export type Background =
  | "primaryBackground"
  | "warningBackground"
  | "dangerBackground"
  | "successBackground"
  | "infoBackground"
  | "roseBackground"
  | "grayBackground";

const useStyles = makeStyles({
  root: {
    height: "4px",
    marginBottom: "20px",
    overflow: "hidden",
  },
  bar: {
    height: "4px",
  },
  primary: {
    backgroundColor: primaryColor[0],
  },
  warning: {
    backgroundColor: warningColor[0],
  },
  danger: {
    backgroundColor: dangerColor[0],
  },
  success: {
    backgroundColor: successColor[0],
  },
  info: {
    backgroundColor: infoColor[0],
  },
  rose: {
    backgroundColor: roseColor[0],
  },
  gray: {
    backgroundColor: grayColor[0],
  },
  primaryBackground: {
    background: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.2)",
  },
  warningBackground: {
    background: "rgba(" + hexToRgb(warningColor[0]) + ", 0.2)",
  },
  dangerBackground: {
    background: "rgba(" + hexToRgb(dangerColor[0]) + ", 0.2)",
  },
  successBackground: {
    background: "rgba(" + hexToRgb(successColor[0]) + ", 0.2)",
  },
  infoBackground: {
    background: "rgba(" + hexToRgb(infoColor[0]) + ", 0.2)",
  },
  roseBackground: {
    background: "rgba(" + hexToRgb(roseColor[0]) + ", 0.2)",
  },
  grayBackground: {
    background: "rgba(" + hexToRgb(grayColor[5]) + ", 0.2)",
  },
});

type Props = LinearProgressProps & {
  color?:
    | "primary"
    | "warning"
    | "danger"
    | "success"
    | "info"
    | "rose"
    | "gray";
};

const CustomLinearProgress = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { color, ...rest } = props;
  return (
    <LinearProgress
      {...rest}
      classes={{
        root:
          classes.root +
          (color && " " + classes[(color + "Background") as Background]),
        bar: classes.bar + (color && " " + classes[color]),
      }}
    />
  );
};

CustomLinearProgress.defaultProps = {
  color: "gray",
};

export default CustomLinearProgress;
