import FormDialog from "components/Dialog/FormDialog";
import CustomInputField from "components/Form/CustomInputField";
import { Device } from "Device/Device";
import { patchDevice } from "Device/requests";
import { Field } from "formik";
import { FormikHelpers, FormikValues } from "formik/dist/types";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";

type Props = {
  device?: Device;
  setOpen: (open: boolean) => void;
  open: boolean;
  onUpdated: (device: Device) => void;
};

const DeviceCalibrationDataDialog = (props: Props): JSX.Element => {
  const { onUpdated, device, open, setOpen } = props,
    { enqueueSnackbar } = useSnackbar(),
    [t] = useTranslation(),
    title = t("app:device.calibration.missingData.title"),
    initialValues = { serialNr: "" },
    validationSchema = object({
      serialNr: string().max(32).required(),
    });

  const onSubmit = async (
    { serialNr }: FormikValues,
    { resetForm }: FormikHelpers<FormikValues>
  ) => {
    if (!device) return;

    try {
      const updatedDevice = await patchDevice(device, {
        serial: serialNr,
      });

      enqueueSnackbar(
        t("app:device.calibration.missingData.updatedSuccessfully"),
        {
          variant: "success",
        }
      );

      resetForm();
      onUpdated(updatedDevice);
    } catch {
      //Caught by interceptor
    }
  };

  return (
    <FormDialog
      title={title}
      onSubmit={onSubmit}
      open={open}
      setOpen={setOpen}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Field
        component={CustomInputField}
        labelText={t("di:device.serialNr")}
        name="serialNr"
        id="serialNr"
        required={true}
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: "text",
          autoComplete: "off",
          inputProps: {
            maxLength: 32,
          },
        }}
      />
    </FormDialog>
  );
};

export default DeviceCalibrationDataDialog;
