import Wizard from "components/Wizard/Wizard";
import config from "config/WebSocket";
import { Ba, JumperBox, ResistanceBox } from "Device/Calibration/Calibration";
import DeviceCalibrationTestStep from "Device/Calibration/DeviceCalibrationTestStep";
import PreFlightStep from "Device/Calibration/PreFlightStep";
import CalibrationStep from "Device/Calibration/Rdi/CalibrationStep";
import VerificationStep from "Device/Calibration/VerificationStep";
import { RDI_3000, RSS_3000 } from "Device/supportedTypes";
import useWebSocket from "hooks/WebSocket";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Composition } from "./Calibration/Composition/CompositionSchema";
import DeviceCalibrationSearch from "./Calibration/DeviceCalibrationSearch";
import { Device } from "./Device";

type Props = {
  autoRefresh?: boolean;
};

const CalibrationTestPage = ({ autoRefresh = true }: Props): JSX.Element => {
  const [t] = useTranslation("app"),
    [device, setSelectedDevice] = useState<Device | null>(null),
    [resistanceBox, setSelectedResistanceBox] = useState<ResistanceBox | null>(
      null
    ),
    [jumperBox, setSelectedJumperBox] = useState<JumperBox | null>(null),
    [composition, setComposition] = useState<Composition | null>(null),
    [ba, setBa] = useState<Ba>(""),
    [dropOff, setDropOff] = useState<string>("...");
  const [url, setUrl] = useState<string | null>(null);
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(url);
  const startSelfTest = useCallback(() => {
    if (!device) return;

    let url = `${config.url}zkl-rc/${device.uid}/test?t=${Date.now()}`;
    switch (device.type) {
      case RDI_3000:
        url = `${config.url}rdi/${device.uid}/test?t=${Date.now()}`;
        break;
      case RSS_3000:
        url = `${config.url}rss/${device.uid}/test?t=${Date.now()}`;
        break;
    }

    setUrl(url);
  }, [setUrl, device]);

  const stopSelfTest = useCallback(() => {
    setUrl(null);
  }, [setUrl]);

  const onStepChange = (activeStep: number) => {
    if (activeStep === 1) {
      setSelectedDevice(null);
      setSelectedResistanceBox(null);
      setSelectedJumperBox(null);
      setBa("");
      setDropOff("...");
      setComposition(null);
      stopSelfTest();
    }
  };

  useEffect(() => {
    return () => {
      stopSelfTest();
    };
  }, [stopSelfTest]);

  return (
    <Wizard
      labels={[
        { name: t("device.calibration.selectStep.name") },
        { name: t("device.calibration.preFlightStep.name") },
        ...(device?.type === "rdi"
          ? [
              {
                name: t("device.calibration.calibrateStep.name"),
                optional: !device?.editable,
              },
            ]
          : []),
        { name: t("device.calibration.testStep.name") },
        { name: t("device.calibration.verifyStep.name") },
      ]}
      onStepChange={onStepChange}
    >
      <DeviceCalibrationSearch
        onRowClick={function (device: Device | null) {
          setSelectedDevice(device);
        }}
        autoRefresh={autoRefresh}
      />
      <PreFlightStep
        device={device}
        composition={composition}
        setComposition={setComposition}
        setSelectedDevice={setSelectedDevice}
        setSelectedJumperBox={setSelectedJumperBox}
        setSelectedResistanceBox={setSelectedResistanceBox}
        resistanceBox={resistanceBox}
        setResistanceBox={setSelectedResistanceBox}
        jumperBox={jumperBox}
        setJumperBox={setSelectedJumperBox}
        ba={ba}
        setBa={setBa}
        dropOff={dropOff}
        setDropOff={setDropOff}
      />
      {device?.type === "rdi" && <CalibrationStep device={device} />}

      <DeviceCalibrationTestStep
        device={device}
        startSelfTest={startSelfTest}
        stopSelfTest={stopSelfTest}
        lastJsonMessage={lastJsonMessage}
      />

      <VerificationStep
        device={device}
        lastJsonMessage={lastJsonMessage}
        finishSelfTest={sendJsonMessage}
        sendJsonMessage={sendJsonMessage}
        composition={composition}
        jumperBox={jumperBox}
        resistanceBox={resistanceBox}
        ba={ba}
        dropOff={dropOff}
      />
    </Wizard>
  );
};

export default CalibrationTestPage;
