import {
  DurationTestStatus,
  FetchDeviceFilters,
  Status,
} from "Device/definitions";
import { Device } from "Device/Device";
import DeviceDurationTestInProgressSearch from "Device/DurationTest/DeviceDurationTestInProgressSearch";
import DeviceStartDurationTestStatus from "Device/DurationTest/DeviceStartDurationTestStatus";
import { transferDurationTestStatus } from "Device/requests";
import React, { useCallback, useState } from "react";
import { Column } from "react-table";
import Wizard from "components/Wizard/Wizard";

type Props = {
  deviceFilters: FetchDeviceFilters;
  actionTitle: string;
  selectDevicesTitle: string;
  confirmActionText: string;
  columns: Column<Device>[];
  autoRefresh: boolean;
  onUpdate?: () => void;
};

const DeviceDurationTestInProgress = ({
  deviceFilters,
  actionTitle,
  selectDevicesTitle,
  columns,
  autoRefresh,
  confirmActionText,
  onUpdate,
}: Props): JSX.Element => {
  const [selected, setSelected] = React.useState<string[]>([]);
  const [loaded, setLoaded] = React.useState<Map<string, Device>>(new Map());
  const [selectedDevices, setSelectedDevices] = React.useState<Device[]>([]);
  const [activeStep, setActiveStep] = React.useState<number>(1);
  const [requests, setRequests] = useState(new Map());
  const [requestInProgress, setRequestInProgress] = useState(false);

  const onDataFetched = (array: Device[]) => {
    setLoaded(
      new Map([...loaded].concat(array.map((device) => [device.uid, device])))
    );
    onUpdate && onUpdate();
  };

  const stopDurationTest = async (uid: string) => {
    const oldRequest = requests.get(uid);
    if (oldRequest && oldRequest.status !== Status.FAILURE) return;
    const timeout = setTimeout(() => {
      setRequests(
        (prevState) =>
          new Map([...prevState, [uid, { status: Status.IN_PROGRESS }]])
      );
    }, 100);
    const result = await transferDurationTestStatus(
      uid,
      DurationTestStatus.DurationTestCanceled
    );
    clearTimeout(timeout);
    setRequests((prevState) => new Map([...prevState, [uid, result]]));
  };

  const stopDurationTests = async () => {
    if (requestInProgress) return;
    setRequestInProgress(true);
    await Promise.allSettled(
      selected.map((device) => stopDurationTest(device))
    );
    setRequestInProgress(false);
  };

  const updateSelected = useCallback(() => {
    setSelectedDevices(
      selected.reduce((result: Device[], uid) => {
        const loadedDevice = loaded.get(uid);
        if (loadedDevice) result.push(loadedDevice);
        return result;
      }, [])
    );
  }, [selected, loaded, setSelectedDevices]);

  const onStepChange = useCallback(
    (activeStep: number) => {
      updateSelected();
      if (activeStep === 2) {
        stopDurationTests();
      }

      setActiveStep(activeStep);
    },
    [updateSelected, setActiveStep, activeStep]
  );

  return (
    <Wizard
      labels={[{ name: selectDevicesTitle }, { name: actionTitle }]}
      onStepChange={onStepChange}
    >
      <DeviceDurationTestInProgressSearch
        onSelectionChanged={setSelected}
        onDataFetched={onDataFetched}
        initialSelectedRowIds={selected}
        deviceFilters={deviceFilters}
        columns={columns}
        autoRefresh={autoRefresh}
        nextStep={stopDurationTests}
      />
      <DeviceStartDurationTestStatus
        devices={selectedDevices}
        confirmActionText={confirmActionText}
        actionBtnText={actionTitle}
        requests={requests}
      />
    </Wizard>
  );
};

export default DeviceDurationTestInProgress;
