// nodejs library to set properties for components
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// nodejs library that concatenates classes
import classNames from "classnames";
import React from "react";
import {
  dangerCardHeader,
  grayColor,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  successCardHeader,
  warningCardHeader,
} from "styles/material-dashboard-pro-react";
// @material-ui/icons

const useStyles = makeStyles({
  cardText: {
    float: "none",
    display: "inline-block",
    marginRight: "0",
    borderRadius: "3px",
    backgroundColor: grayColor[0],
    padding: "15px",
    marginTop: "-20px",
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
});

type Props = {
  className?: string;
  color?: "warning" | "success" | "danger" | "info" | "primary" | "rose";
  children?: React.ReactNode;
};

export default function CardText(props: Props): JSX.Element {
  const classes = useStyles();
  const { className, children, color, ...rest } = props;
  const cardTextClasses = classNames({
    [classes.cardText]: true,
    ...(color && { [classes[`${color}CardHeader`]]: color }),
    ...(className && { [className]: className }),
  });
  return (
    <div className={cardTextClasses} {...rest}>
      {children}
    </div>
  );
}
