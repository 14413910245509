import {
  RDI_3000,
  RSS_3000,
  SupportedTypes,
  ZKL_3000,
  ZKL_3000_RC,
  ZKL_3000_RC_AFTC,
  ZKL_3000_RCC,
} from "Device/supportedTypes";

const types: Record<SupportedTypes, string> = {
  [ZKL_3000]: "ZKL 3000",
  [ZKL_3000_RC]: "ZKL 3000 RC",
  [ZKL_3000_RCC]: "ZKL 3000 RC-C",
  [ZKL_3000_RC_AFTC]: "ZKL 3000 RC AFTC",
  [RDI_3000]: "RDI 3000",
  [RSS_3000]: "RSS 3000",
};

export default types;
