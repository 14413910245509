import React, { isValidElement } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Step from "@material-ui/core/Step";
import { StepWizardChildProps } from "react-step-wizard";

const useStyles = makeStyles(() => ({
  activeStep: {
    display: "inherit",
  },
  inactiveStep: {
    display: "none",
  },
}));

type Props = {
  children: React.ReactNode[] | React.ReactNode;
} & Partial<StepWizardChildProps>;

const WizardStep = ({ children, ...rest }: Props): JSX.Element => {
  const classes = useStyles();
  const { isActive } = rest;

  return (
    <div className={isActive ? classes.activeStep : classes.inactiveStep}>
      {React.Children.map(children, (child) => {
        if (!isValidElement(child)) return null;

        return child && <Step>{React.cloneElement(child, rest)}</Step>;
      })}
    </div>
  );
};

export default WizardStep;
