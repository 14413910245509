import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { AxiosResponse } from "axios";
import FormSelect from "components/Form/FormSelect";
import {
  ResistanceBox,
  ResistanceBoxLabel,
  RssCalibration,
  RssCalibrationSchema,
} from "Device/Calibration/Calibration";
import { Composition } from "Device/Calibration/Composition/CompositionSchema";
import PreFlightStepBase from "Device/Calibration/PreFlightStepBase";
import { Device } from "Device/Device";
import { getRssCalibration } from "Device/requests";
import useResponseValidator from "hooks/ResponseValidator";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { StepWizardChildProps } from "react-step-wizard";
import useAsyncEffect from "use-async-effect";
import { string } from "yup";
import { ObjectShape } from "yup/lib/object";

type Props = {
  device: Device | null;
  setDevice?: Dispatch<SetStateAction<Device | null>>;
  composition: Composition | null;
  setComposition: React.Dispatch<React.SetStateAction<Composition | null>>;
  resistanceBox: ResistanceBox | null;
  setResistanceBox: (rb: ResistanceBox | null) => void;
} & Partial<StepWizardChildProps>;

const RssPreFlightStep = ({
  device,
  setDevice,
  composition,
  setComposition,
  resistanceBox,
  setResistanceBox,
  previousStep,
  nextStep,
}: Props): JSX.Element => {
  const [t] = useTranslation("app");
  const { validate } = useResponseValidator();
  const [resistanceBoxes, setResistanceBoxes] = useState<ResistanceBox[]>([]);

  const getCalibration = useCallback(async () => {
    if (!device) return;

    try {
      const response = await getRssCalibration(device.uid);
      const { resistances } = await validate<RssCalibration>(
        RssCalibrationSchema,
        response
      );

      setResistanceBoxes(resistances);
    } catch (e) {
      // Caught by interceptor
    }
  }, [device, setResistanceBoxes]);

  const onSubmit = useCallback<() => Promise<AxiosResponse | void>>(() => {
    return new Promise((resolve) => {
      resolve();
    });
  }, [device]);

  useAsyncEffect(async () => {
    if (!device) {
      return;
    }

    await getCalibration();
  }, [device, getCalibration]);

  if (!device) return <></>;

  const initialValues: {
    testTool: string;
  } = {
    testTool: resistanceBox?.label ?? "",
  };
  const schemaShape: ObjectShape = {
    testTool: string().required(),
  };

  const onResistanceBoxChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    for (const rb of resistanceBoxes) {
      if (rb.label === e.target?.value) {
        setResistanceBox(rb);
      }
    }
  };

  if (!device) return <></>;

  return (
    <PreFlightStepBase
      device={device}
      setDevice={setDevice}
      composition={composition}
      setComposition={setComposition}
      initValues={initialValues}
      initTouched={{ mustHaveAftc: true }}
      schemaShape={schemaShape}
      hasConfigurableServiceInterval={false}
      onSubmit={[onSubmit]}
      previousStep={previousStep}
      nextStep={nextStep}
    >
      {({ handleChange }) => (
        <>
          <FormGroup>
            <FormLabel component="h3">
              {t("device.calibration.preFlightStep.rss.selectUsedTools")}
            </FormLabel>

            <FormSelect
              label={t("device.calibration.rss.testTool")}
              name="testTool"
              items={resistanceBoxes.map((rb) => {
                return {
                  id: rb.label,
                  label: ResistanceBoxLabel(rb),
                };
              })}
              onChange={(
                e: React.ChangeEvent<{
                  name?: string;
                  value: unknown;
                }>
              ) => {
                handleChange(e);
                onResistanceBoxChange(e);
              }}
            />
          </FormGroup>
        </>
      )}
    </PreFlightStepBase>
  );
};

export default RssPreFlightStep;
