import DurationTestSummaryPage from "Device/DurationTest/DurationTestSummaryPage";
import DurationTestPage from "Device/DurationTestPage";
import FotaJobPage from "Device/FotaJobPage";
import i18next from "i18next";
import { LAYOUT_DEFAULT } from "routes/routes";
import IntakePage from "Device/IntakePage";
import InServicePage from "Device/InServicePage";
import CalibrationTestPage from "./CalibrationTestPage";
import { Build, DoneAll, Tune, Timer, CloudDownload } from "@material-ui/icons";
import DeviceInfoPage from "Device/DeviceInfoPage";
import { useParams } from "react-router-dom";
import React, { ReactElement } from "react";
import { Uid } from "Device/formatters";
import { ReactComponentLike } from "prop-types";

export const RT_DEVICE_INTAKE = "/device/intake";
export const RT_DEVICE_IN_SERVICE = "/device/inservice";
export const RT_DEVICE_CALIBRATION_TEST = "/device/calibrationtest";
export const RT_DEVICE_DURATION_TEST = "/device/durationtest";
export const RT_DEVICE_INFO = "/device/:uid";
export const RT_DEVICE_DURATION_TEST_SUMMARY = "/duration-test/:uid";
export const RT_FOTA_MANAGEMENT = "/device/fota-management";

const DeviceInfoTitle = () => {
  const { uid } = useParams<{ uid: string }>();
  return <Uid value={uid} />;
};

type DeclaredRoute = {
  path: string;
  pageTitle: string | ReactElement;
  component: React.ComponentType<unknown>;
  layout: string;
  redirect?: boolean;
};

type SidebarRoute = {
  name: string;
  rtlName: string;
  icon: ReactComponentLike;
  rtlMini?: string;
  mini?: string;
} & DeclaredRoute;

type CollapsibleRoute = SidebarRoute & {
  collapse: true;
  state: string;
  rtlMini: string;
  mini: string;
  views: Routes;
};

type OtherRoute = {
  inSidebar: false;
} & DeclaredRoute;

export type Routes = (SidebarRoute | OtherRoute | CollapsibleRoute)[];

const getDeviceRoutes = (): Routes => {
  // Feature flag for non production features
  const stagingFeature = window.location.hostname != "sam.dualinventive.com";

  // Fota Routes
  const fotaRoutes = [
    {
      path: RT_FOTA_MANAGEMENT,
      name: i18next.t("app:device.fotaJobs.menuLabel"),
      rtlName: i18next.t("app:device.fotaJobs.menuLabel"),
      pageTitle: i18next.t("app:device.fotaJobs.pageTitle"),
      icon: CloudDownload,
      component: FotaJobPage,
      layout: LAYOUT_DEFAULT,
    },
  ];

  return [
    {
      path: RT_DEVICE_INTAKE,
      name: i18next.t("app:device.intake.menuLabel"),
      rtlName: i18next.t("app:device.intake.menuLabel"),
      pageTitle: i18next.t("app:device.intake.pageTitle"),
      icon: Build,
      component: IntakePage,
      layout: LAYOUT_DEFAULT,
    },
    {
      path: RT_DEVICE_CALIBRATION_TEST,
      name: i18next.t("app:device.calibration.menuLabel"),
      rtlName: i18next.t("app:device.calibration.menuLabel"),
      pageTitle: i18next.t("app:device.calibration.pageTitle"),
      icon: Tune,
      component: CalibrationTestPage,
      layout: LAYOUT_DEFAULT,
    },
    {
      path: RT_DEVICE_DURATION_TEST,
      name: i18next.t("app:device.durationTest.menuLabel"),
      rtlName: i18next.t("app:device.durationTest.menuLabel"),
      pageTitle: i18next.t("app:device.durationTest.pageTitle"),
      icon: Timer,
      component: DurationTestPage,
      layout: LAYOUT_DEFAULT,
    },
    {
      path: RT_DEVICE_IN_SERVICE,
      name: i18next.t("app:device.inService.menuLabel"),
      rtlName: i18next.t("app:device.inService.menuLabel"),
      pageTitle: i18next.t("app:device.inService.pageTitle"),
      icon: DoneAll,
      component: InServicePage,
      layout: LAYOUT_DEFAULT,
    },
    ...(stagingFeature ? fotaRoutes : []),
    {
      path: RT_DEVICE_INFO,
      component: DeviceInfoPage,
      layout: LAYOUT_DEFAULT,
      inSidebar: false,
      pageTitle: <DeviceInfoTitle />,
    },
    {
      path: RT_DEVICE_DURATION_TEST_SUMMARY,
      component: DurationTestSummaryPage,
      layout: LAYOUT_DEFAULT,
      inSidebar: false,
      pageTitle: <DeviceInfoTitle />,
    },
  ];
};

export default getDeviceRoutes;
