import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { CloudDownload } from "@material-ui/icons";
import Button from "components/Button";

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export default function DownloadDeliveryReportButton({
  onClick,
}: Props): JSX.Element {
  const [t] = useTranslation("common");
  return (
    <Button
      justIcon={true}
      round
      download
      color="primary"
      size="sm"
      title={t("general.download")}
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      <CloudDownload />
    </Button>
  );
}
