import makeStyles from "@material-ui/core/styles/makeStyles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { dateTimeFormatter } from "Device/formatters";
import { DurationTestReport } from "Device/Report/DurationTestReport";
import React from "react";
import { useTranslation } from "react-i18next";
import { UseTableRowProps } from "react-table";

const useStyles = makeStyles((theme) => ({
  subHeader: {
    fontWeight: "bold",
    opacity: 0.5,
    marginBottom: 0,
  },
  container: {
    marginBottom: theme.spacing(1),
  },
}));

type DetailPanelProps = {
  row: UseTableRowProps<DurationTestReport>;
};

const DurationTestDetailPanel = ({ row }: DetailPanelProps): JSX.Element => {
  const originalRow = row.original;
  const [t] = useTranslation("app");
  const classes = useStyles();

  return (
    <GridContainer className={classes.container}>
      <GridItem xs={6} md={4}>
        <h6 className={classes.subHeader}>{t("device.report.startedAt")}</h6>

        <div>{dateTimeFormatter(originalRow.startedAt)}</div>
      </GridItem>
      <GridItem xs={6} md={4}>
        <h6 className={classes.subHeader}>{t("device.report.endedAt")}</h6>

        <div>{dateTimeFormatter(originalRow.updatedAt)}</div>
      </GridItem>
      <GridItem xs={6} md={4}>
        <h6 className={classes.subHeader}>
          {t("device.durationTest.gpsCount")}
        </h6>

        <div>{originalRow.noOfGPSMessages}</div>
      </GridItem>
      <GridItem xs={6} md={4}>
        <h6 className={classes.subHeader}>
          {t("device.durationTest.heartBeatCount")}
        </h6>

        <div>{originalRow.noOfHeartbeats}</div>
      </GridItem>
      <GridItem xs={6} md={4}>
        <h6 className={classes.subHeader}>
          {t("device.durationTest.disconnectCount")}
        </h6>

        <div>{originalRow.noOfDisconnects}</div>
      </GridItem>

      <GridItem xs={12}>
        <h6 className={classes.subHeader}>{t("device.report.remarks")}</h6>

        <div>{originalRow.remarks ? originalRow.remarks : "-"}</div>
      </GridItem>
    </GridContainer>
  );
};

export default DurationTestDetailPanel;
