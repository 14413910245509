import {
  DefaultFetchDeviceFilters,
  DurationTestStatus,
} from "Device/definitions";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { isOnlineFormatter, uidFormatter } from "Device/formatters";
import DeviceService from "Device/Service/DeviceService";
import DeviceInfoButton from "Device/Info/DeviceInfoButton";
import deviceType from "Device/deviceType";
import { Column } from "react-table";
import { Device } from "./Device";
import { SupportedTypes } from "./supportedTypes";

type Props = {
  autoRefresh?: boolean;
};

const IntakePage = ({
  autoRefresh = true,
}: PropsWithChildren<Props>): JSX.Element => {
  const [t] = useTranslation(["app"]);
  const columns = React.useMemo<Column<Device>[]>(
    () => [
      {
        Header: t<string>("di:device.uid"),
        accessor: "uid",
        Cell: uidFormatter,
      },
      {
        Header: t<string>("di:device.serialNr"),
        accessor: "serial",
      },
      {
        Header: t<string>("di:device.ownerLabel"),
        accessor: "label",
      },
      {
        Header: t<string>("di:device.isOnline"),
        accessor: "isOnline",
        Cell: isOnlineFormatter,
      },
      {
        Header: t<string>("di:device.projectName"),
        accessor: "projectName",
      },
      {
        Header: t<string>("di:device.type"),
        accessor: "type",
        Cell: ({ value }: { value: SupportedTypes }) =>
          deviceType[value] ?? value,
      },
      {
        Header: "",
        id: "action",
        Cell: DeviceInfoButton,
      },
    ],
    [t]
  );

  return (
    <DeviceService
      selectDevicesTitle={t("device.intake.selectDevices")}
      actionTitle={t("device.intake.putIntoService")}
      deviceFilters={{
        ...DefaultFetchDeviceFilters,
        inService: false,
        durationTestStatus: [DurationTestStatus.DurationTestNotStarted],
      }}
      confirmActionText={t("device.intake.willPutAllIntoService")}
      columns={columns}
      autoRefresh={autoRefresh}
    />
  );
};

export default IntakePage;
