import IndeterminateCheckbox from "components/Table/Checkbox/IndeterminateCheckbox";
import React from "react";
import { UseRowSelectRowProps, UseTableRowProps } from "react-table";

export type CellCheckboxProps<D extends Record<string, unknown>> = {
  row: UseRowSelectRowProps<D> & UseTableRowProps<D>;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export type CellCheckboxType<D extends Record<string, unknown>> = ({
  row,
}: CellCheckboxProps<D>) => JSX.Element;

const CellCheckbox = <D extends Record<string, unknown>>({
  row,
  disabled,
  onClick,
}: CellCheckboxProps<D>): JSX.Element => {
  return (
    <IndeterminateCheckbox
      {...row.getToggleRowSelectedProps()}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export default CellCheckbox;
