import { Ba, JumperBox, ResistanceBox } from "Device/Calibration/Calibration";
import { Composition } from "Device/Calibration/Composition/CompositionSchema";
import RdiVerificationStep from "Device/Calibration/Verification/Rdi";
import { FinishSelfTest } from "Device/Calibration/Verification/VerificationStep";
import ZklRcVerificationStep from "Device/Calibration/Verification/ZklRc";
import RssVerificationStep from "Device/Calibration/Verification/Rss";
import { Device } from "Device/Device";
import { RDI_3000, RSS_3000 } from "Device/supportedTypes";
import { WebSocketHook } from "hooks/WebSocket";
import React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";

type Props = {
  device: Device | null;
  composition: Composition | null;
  resistanceBox: ResistanceBox | null;
  jumperBox: JumperBox | null;
  ba: Ba;
  dropOff: string;
  previousStep?: () => void;
  firstStep?: () => void;
  isActive?: () => void;
  finishSelfTest: FinishSelfTest;
  sendJsonMessage: SendJsonMessage;
  lastJsonMessage?: WebSocketHook["lastJsonMessage"];
} & Partial<StepWizardChildProps>;

const VerificationStep = ({
  device,
  composition,
  resistanceBox,
  jumperBox,
  isActive,
  dropOff,
  sendJsonMessage,
  lastJsonMessage,
  previousStep,
  firstStep,
  ba,
}: Props): JSX.Element => {
  switch (device?.type) {
    case RDI_3000:
      return (
        <RdiVerificationStep
          device={device}
          composition={composition}
          finishSelfTest={sendJsonMessage}
          lastJsonMessage={lastJsonMessage}
          previousStep={previousStep}
          firstStep={firstStep}
        />
      );
    case RSS_3000:
      return (
        <RssVerificationStep
          device={device}
          lastJsonMessage={lastJsonMessage}
          finishSelfTest={sendJsonMessage}
          composition={composition}
          resistanceBox={resistanceBox}
          previousStep={previousStep}
          firstStep={firstStep}
        />
      );
    default:
      return (
        <ZklRcVerificationStep
          device={device}
          lastJsonMessage={lastJsonMessage}
          finishSelfTest={sendJsonMessage}
          composition={composition}
          jumperBox={jumperBox}
          resistanceBox={resistanceBox}
          dropOff={dropOff}
          previousStep={previousStep}
          firstStep={firstStep}
          isActive={isActive}
          initialBa={ba}
        />
      );
  }
};

export default VerificationStep;
