import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import logo from "assets/img/logo-mtinfo-cloud.svg";

export default function Loader() {
  const classes = useStyles();
  return (
    <div className={classes.loadingWrapper}>
      <img alt="logo" src={logo} width={100} height={100} />
      <CircularProgress />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  loadingWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
  },
}));
