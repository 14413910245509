import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import DefaultButton from "@material-ui/core/Button";
import React from "react";

type FinishSnackbarProps = {
  reportID: number;
  onDownloadClick: (reportID: number) => void;
};

const FinishSnackbar = ({
  reportID,
  onDownloadClick,
}: FinishSnackbarProps): JSX.Element => {
  const [t] = useTranslation("common"),
    { closeSnackbar } = useSnackbar();

  return (
    <>
      <DefaultButton
        onClick={() => {
          closeSnackbar();
        }}
      >
        {t("general.ok")}
      </DefaultButton>

      <DefaultButton
        onClick={() => {
          closeSnackbar();
          onDownloadClick(reportID);
        }}
      >
        {t("general.download")}
      </DefaultButton>
    </>
  );
};

export default FinishSnackbar;
