import makeStyles from "@material-ui/core/styles/makeStyles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { SelfTestReport } from "Device/Report/Rss/SelfTestReport";
import React from "react";
import { useTranslation } from "react-i18next";
import { UseTableRowProps } from "react-table";

const useStyles = makeStyles((theme) => ({
  subHeader: {
    fontWeight: "bold",
    opacity: 0.5,
    marginBottom: 0,
  },
  container: {
    marginBottom: theme.spacing(1),
  },
}));

type DetailPanelProps = {
  row: UseTableRowProps<SelfTestReport>;
};

const DetailPanel = ({ row }: DetailPanelProps): JSX.Element => {
  const originalRow = row.original;
  const [t] = useTranslation("app");
  const classes = useStyles();

  return (
    <GridContainer className={classes.container}>
      <GridItem xs={12}>
        <h6 className={classes.subHeader}>{t("device.report.remarks")}</h6>

        <div>{originalRow.remarks ? originalRow.remarks : "-"}</div>
      </GridItem>
    </GridContainer>
  );
};

export default DetailPanel;
