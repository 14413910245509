import { string, object, array } from "yup";

type Endpoint = string;

export type Endpoints = {
  production: Endpoint[];
  development: Endpoint[];
};

const EndpointSchema = array().of(string());

const EndpointsSchema = object().shape({
  production: EndpointSchema,
  pilot: EndpointSchema,
  development: EndpointSchema,
});

export default EndpointsSchema;
