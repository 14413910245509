import { Device } from "Device/Device";
import { updateDeviceImei } from "Device/requests";
import { useSnackbar } from "notistack";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Close } from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import modalStyle from "styles/modalStyle";
import Button from "components/Button";
import { TransitionProps } from "@material-ui/core/transitions";
import { Field, Form, Formik } from "formik";
import CustomInputField from "components/Form/CustomInputField";

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps,
  ref
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  device: Device | undefined;
  children: React.ReactNode[] | React.ReactNode;
};

const ImeiChangeDialog = ({ device, open, setOpen }: Props): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("common");
  const classes = useStyles();
  const title = t("general.areYouSure");
  const cancelText = t("general.cancel");
  const confirmText = t("general.yes");

  const onCancelClick = () => {
    setOpen(false);
  };

  const onCloseInternal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={open}
      TransitionComponent={Transition}
      onClose={onCloseInternal}
      keepMounted
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onCloseInternal}
        >
          <Close className={classes.modalClose} />
        </Button>

        <h4 className={classes.modalTitle}>{title}</h4>
      </DialogTitle>

      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        <h5>{t("device.info.imeiChange.header")}</h5>
        <div>
          <div>
            <Formik
              initialValues={{ IMEIVerification: "", newIMEI: "" }}
              onSubmit={async (values, { resetForm }) => {
                const res = await updateDeviceImei(
                  device?.uid,
                  values.IMEIVerification,
                  values.newIMEI
                );
                if (res.status == 200) {
                  enqueueSnackbar(
                    t("device.info.imeiChange.imeiChangeSuccess"),
                    {
                      variant: "success",
                    }
                  );
                } else {
                  enqueueSnackbar(
                    t("device.info.imeiChange.imeiChangeFailed"),
                    {
                      variant: "error",
                    }
                  );
                }
                resetForm();
                setOpen(false);
              }}
              validateOnChange={false}
            >
              {({ isValid, resetForm }) => (
                <Form>
                  <Field
                    component={CustomInputField}
                    labelText={t("device.info.imeiChange.oldImeiInputLabel")}
                    id="imei_verification"
                    name="IMEIVerification"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      maxLength: 4,
                      minLength: 4,
                      pattern: "\\d{4}",
                      type: "text",
                      inputMode: "numeric",
                      onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                        e.target.value = e.target.value
                          .replace(/[^0-9]/g, "")
                          .slice(0, 4);
                      },
                    }}
                    validate={(value: string) => {
                      if (!/^\d{4}$/.test(value)) {
                        return t(
                          "device.info.imeiChange.oldImeiInputLengthValidationError"
                        );
                      }
                    }}
                  />
                  <Field
                    component={CustomInputField}
                    labelText={t("device.info.imeiChange.newImeiInputLabel")}
                    id="new_imei"
                    name="newIMEI"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      maxLength: 15,
                      minLength: 15,
                      pattern: "\\d{15}",
                      type: "text",
                      inputMode: "numeric",
                      onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                        e.target.value = e.target.value
                          .replace(/[^0-9]/g, "")
                          .slice(0, 15);
                      },
                    }}
                    validate={(value: string) => {
                      if (!/^\d{15}$/.test(value)) {
                        return t(
                          "device.info.imeiChange.newImeiInputLengthValidationError"
                        );
                      }
                    }}
                  />
                  <DialogActions
                    className={
                      classes.modalFooter + " " + classes.modalFooterCenter
                    }
                  >
                    <Button
                      onClick={() => {
                        resetForm();
                        onCancelClick();
                      }}
                    >
                      {cancelText}
                    </Button>

                    <Button type={"submit"} color="success" disabled={!isValid}>
                      {confirmText}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ImeiChangeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ImeiChangeDialog;
