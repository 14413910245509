//todo fix the state names once the update phase implementation is finalized

import {
  ConfirmErrorState,
  ConfirmTimeoutState,
  DownloadErrorState,
  DownloadTimeoutState,
  InstallErrorState,
  UpdateTimeoutState,
} from "Device/jobState";

export type ErroneousStates =
  | typeof DownloadTimeoutState
  | typeof UpdateTimeoutState
  | typeof DownloadErrorState
  | typeof InstallErrorState
  | typeof ConfirmErrorState
  | typeof ConfirmTimeoutState;

const erroneousJobStates: Record<ErroneousStates, string> = {
  [DownloadTimeoutState]: "DOWNLOAD TIMEOUT ",
  [UpdateTimeoutState]: "UPDATE TIMEOUT ",
  [DownloadErrorState]: "DOWNLOAD ERROR ",
  [InstallErrorState]: "INSTALL ERROR ",
  [ConfirmErrorState]: "CONFIRM ERROR ",
  [ConfirmTimeoutState]: "CONFIRM TIMEOUT ",
};

export default erroneousJobStates;
