import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Close } from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import modalStyle from "styles/modalStyle";
import Button from "components/Button";
import { TransitionProps } from "@material-ui/core/transitions";

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps,
  ref
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type Props = {
  title?: string;
  confirmText?: string;
  cancelText?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onCancel?: () => void;
  onClose?: () => void;
  onConfirm: () => void;
  children: React.ReactNode[] | React.ReactNode;
};

const ConfirmDialog = (props: Props): JSX.Element => {
  const [t] = useTranslation("common");
  const {
    title = t("general.areYouSure"),
    children,
    confirmText = t("general.yes"),
    onConfirm,
    cancelText = t("general.cancel"),
    onCancel = () => {
      //noop
    },
    onClose = () => {
      //noop
    },
    open,
    setOpen,
  } = props;
  const classes = useStyles();

  const onConfirmClick = () => {
    setOpen(false);
    onConfirm();
  };

  const onCancelClick = () => {
    setOpen(false);
    onCancel();
  };

  const onCloseInternal = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseInternal}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onCloseInternal}
        >
          <Close className={classes.modalClose} />
        </Button>

        <h4 className={classes.modalTitle}>{title}</h4>
      </DialogTitle>

      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        {children}
      </DialogContent>

      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
      >
        <Button onClick={onCancelClick}>{cancelText}</Button>

        <Button onClick={onConfirmClick} color="success">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ConfirmDialog;
