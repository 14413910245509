/*eslint-disable*/
import { Theme } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import { StyleRules } from "@material-ui/styles/withStyles/withStyles";
import cx from "classnames";
import { Routes } from "Device/routes";
import SidebarWrapper from "layout/SidebarWrapper";
import User from "layout/User";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  blackColor,
  boxShadow,
  dangerColor,
  defaultFont,
  drawerMiniWidth,
  drawerWidth,
  grayColor,
  hexToRgb,
  infoColor,
  primaryBoxShadow,
  primaryColor,
  roseColor,
  successColor,
  transition,
  warningColor,
  whiteColor,
} from "styles/material-dashboard-pro-react";

const sidebarStyle = (theme: Theme): StyleRules => ({
  drawerPaperRTL: {
    [theme.breakpoints.up("md")]: {
      left: "auto !important",
      right: "0 !important",
    },
    [theme.breakpoints.down("sm")]: {
      left: "0  !important",
      right: "auto !important",
    },
  },

  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: 1032,
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    // overflow: 'auto',
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      top: "0",
    },
  },

  blackBackground: {
    color: whiteColor,
    "&:after": {
      background: blackColor,
      opacity: ".8",
    },
  },

  blueBackground: {
    color: whiteColor,
    "&:after": {
      background: infoColor[0],
      opacity: ".93",
    },
  },

  whiteBackground: {
    color: grayColor[2],
    "&:after": {
      background: whiteColor,
      opacity: ".93",
    },
  },

  whiteAfter: {
    "&:after": {
      backgroundColor: "hsla(0,0%,71%,.3) !important",
    },
  },

  drawerPaperMini: {
    width: drawerMiniWidth + "px!important",
  },

  logo: {
    padding: "15px 0px",
    margin: "0",
    display: "block",
    position: "relative",
    zIndex: 4,
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "hsla(0,0%,100%,.3)",
    },
  },

  logoMini: {
    transition: "all 300ms linear",
    opacity: 1,
    position: "absolute",
    textAlign: "center",
    width: "30px",
    display: "inline-block",
    maxHeight: "30px",
    marginLeft: "22px",
    marginRight: "18px",
    marginTop: "18px",
    color: "inherit",
  },

  logoMiniRTL: {
    float: "right",
    marginRight: "30px",
    marginLeft: "26px",
  },

  logoNormal: {
    ...defaultFont,
    transition: "all 300ms linear",
    display: "block",
    opacity: "1",
    transform: "translate3d(0px, 0, 0)",
    textTransform: "uppercase",
    padding: "5px 0px",
    fontSize: "18px",
    fontWeight: 400,
    overflow: "hidden",
    "&,&:hover,&:focus": {
      color: "inherit",
    },
    width: "140px",
    marginLeft: "80px",
  },

  logoNormalRTL: {
    textAlign: "right",
  },

  logoNormalSidebarMini: {
    opacity: "0",
    transform: "translate3d(-25px, 0, 0)",
  },

  logoNormalSidebarMiniRTL: {
    transform: "translate3d(25px, 0, 0)",
  },

  img: {
    width: "35px",
    verticalAlign: "middle",
    border: "0",
  },

  background: {
    position: "absolute",
    zIndex: 1,
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    transition: "all 300ms linear",
  },

  list: {
    marginTop: "15px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    color: "inherit",
    "&:before,&:after": {
      display: "table",
      content: '" "',
    },
    "&:after": {
      clear: "both",
    },
  },

  item: {
    color: "inherit",
    position: "relative",
    display: "block",
    textDecoration: "none",
    margin: "0",
    padding: "0",
  },

  itemLink: {
    paddingLeft: "10px",
    paddingRight: "10px",
    transition: "all 300ms linear",
    margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    backgroundColor: "transparent",
    ...defaultFont,
    width: "auto",
    "&:hover": {
      outline: "none",
      backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
      boxShadow: "none",
    },
    "&,&:hover,&:focus": {
      color: "inherit",
    },
  },

  itemIcon: {
    color: "inherit",
    width: "30px",
    height: "24px",
    float: "left",
    position: "inherit",
    top: "3px",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    opacity: "0.8",
  },

  itemIconRTL: {
    float: "right",
    marginLeft: "15px",
    marginRight: "1px",
  },

  itemText: {
    color: "inherit",
    ...defaultFont,
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    transform: "translate3d(0px, 0, 0)",
    opacity: "1",
    transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
    position: "relative",
    display: "block",
    height: "auto",
    whiteSpace: "nowrap",
    padding: "0 16px !important",
  },

  itemTextRTL: {
    marginRight: "45px",
    textAlign: "right",
  },

  itemTextMini: {
    transform: "translate3d(-25px, 0, 0)",
    opacity: "0",
  },

  itemTextMiniRTL: {
    transform: "translate3d(25px, 0, 0) !important",
  },

  collapseList: {
    marginTop: "0",
    "& $caret": {
      marginTop: "8px",
    },
  },

  collapseItem: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    margin: "10px 0 0 0",
    padding: "0",
  },

  collapseActive: {
    outline: "none",
    backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
    boxShadow: "none",
  },

  collapseItemLink: {
    transition: "all 300ms linear",
    margin: "0 15px",
    borderRadius: "3px",
    position: "relative",
    display: "block",
    padding: "10px",
    backgroundColor: "transparent",
    ...defaultFont,
    width: "auto",
    "&:hover": {
      outline: "none",
      backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
      boxShadow: "none",
    },
    "&,&:hover,&:focus": {
      color: "inherit",
    },
  },

  collapseItemMini: {
    color: "inherit",
    ...defaultFont,
    textTransform: "uppercase",
    width: "30px",
    marginRight: "15px",
    textAlign: "center",
    letterSpacing: "1px",
    position: "relative",
    float: "left",
    display: "inherit",
    transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
    fontSize: "14px",
  },

  collapseItemMiniRTL: {
    float: "right",
    marginLeft: "30px",
    marginRight: "1px",
  },

  collapseItemText: {
    color: "inherit",
    ...defaultFont,
    margin: "0",
    position: "relative",
    transform: "translateX(0px)",
    opacity: "1",
    whiteSpace: "nowrap",
    display: "block",
    transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
    fontSize: "14px",
  },

  collapseItemTextRTL: {
    textAlign: "right",
  },

  collapseItemTextMiniRTL: {
    transform: "translate3d(25px, 0, 0) !important",
  },

  collapseItemTextMini: {
    transform: "translate3d(-25px, 0, 0)",
    opacity: "0",
  },

  caret: {
    marginTop: "13px",
    position: "absolute",
    right: "18px",
    transition: "all 150ms ease-in",
    display: "inline-block",
    width: "0",
    height: "0",
    marginLeft: "2px",
    verticalAlign: "middle",
    borderTop: "4px solid",
    borderRight: "4px solid transparent",
    borderLeft: "4px solid transparent",
  },

  caretRTL: {
    left: "11px",
    right: "auto",
  },

  caretActive: {
    transform: "rotate(180deg)",
  },

  purple: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: primaryColor[0],
      ...primaryBoxShadow,
    },
  },

  blue: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: infoColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(infoColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(infoColor[0]) +
        ",.2)",
    },
  },

  green: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: successColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(successColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ",.2)",
    },
  },

  orange: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: warningColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(warningColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ",.2)",
    },
  },

  red: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: dangerColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(dangerColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ",.2)",
    },
  },

  white: {
    "&,&:hover,&:focus": {
      color: grayColor[2],
      backgroundColor: whiteColor,
      boxShadow:
        "0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.14), 0 7px 10px -5px rgba(" +
        hexToRgb(grayColor[2]) +
        ",.4)",
    },
  },

  rose: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: roseColor[0],
      boxShadow:
        "0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.14), 0 7px 10px -5px rgba(" +
        hexToRgb(roseColor[0]) +
        ",.4)",
    },
  },

  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "auto",
    width: "260px",
    zIndex: 4,
    overflowScrolling: "touch",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    color: "inherit",
    paddingBottom: "30px",
  },

  sidebarWrapperWithPerfectScrollbar: {
    overflow: "hidden !important",
  },
});

export type Color =
  | "white"
  | "red"
  | "orange"
  | "green"
  | "blue"
  | "purple"
  | "rose";

export type BgColor = "white" | "black" | "blue";

type SidebarState = any;

type SidebarProps = {
  classes: any;
  bgColor?: BgColor;
  rtlActive?: boolean;
  color?: Color;
  logo?: string;
  logoText?: string;
  image?: string;
  routes: Routes;
  miniActive?: boolean;
  open?: boolean;
  handleDrawerToggle?: (...args: any[]) => any;
};

class Sidebar extends React.Component<SidebarProps, SidebarState> {
  static defaultProps = {
    bgColor: "blue" as const,
  };

  constructor(props: SidebarProps) {
    super(props);
    this.state = {
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    };
  }

  mainPanel = React.createRef<HTMLDivElement>();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes: any) => {
    let initialState = {};
    routes.map((prop: any) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes: any) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName: any) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes: Routes) => {
    const { classes, color, rtlActive } = this.props;
    return routes.map((prop, key: any) => {
      if (prop.redirect || "inSidebar" in prop) {
        return null;
      }
      if ("collapse" in prop && prop.collapse) {
        const st: Record<string, unknown> = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            ),
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });
        const caret =
          classes.caret +
          " " +
          cx({
            [classes.caretRTL]: rtlActive,
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}

              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>

            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          ...(color && { [" " + classes[color]]: this.activeRoute(prop.path) }),
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive,
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          ...(color && { [" " + classes[color]]: this.activeRoute(prop.path) }),
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive,
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive,
        });
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>
                {rtlActive ? prop.rtlMini : prop.mini}
              </span>
            )}

            <ListItemText
              primary={rtlActive ? prop.rtlName : prop.name}
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  render() {
    const { classes, logo, image, logoText, routes, bgColor, rtlActive } =
      this.props;
    const links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive,
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });
    const brand = (
      <div className={logoClasses}>
        <a href="#" className={logoMini}>
          <img src={logo} alt="logo" className={classes.img} />
        </a>

        <a href="#" className={logoNormal}>
          {logoText}
        </a>
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}

            <SidebarWrapper className={sidebarWrapper}>
              <User
                miniActive={this.props.miniActive && this.state.miniActive}
                bgColor={bgColor}
              />
              {links}
            </SidebarWrapper>
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>

        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}

            <SidebarWrapper className={sidebarWrapper}>
              <User
                miniActive={this.props.miniActive && this.state.miniActive}
                bgColor={bgColor}
              />
              {links}
            </SidebarWrapper>
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

export default withStyles(sidebarStyle)(Sidebar);
