import React from "react";

import { Divider } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    margin: theme.spacing(0.5),
  },
}));

export default function StyledDivider() {
  const classes = useStyles();
  return <Divider className={classes.divider} />;
}
