import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { useAuth } from "auth";
import SignInPage from "auth/SignInPage";
import Main from "layout/DefaultLayout";
import { LAYOUT_DEFAULT } from "routes/routes";

const AppRouter = function (): JSX.Element {
  const { isAuthenticated } = useAuth();
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route
          path={LAYOUT_DEFAULT}
          render={(props) =>
            isAuthenticated ? <Main {...props} /> : <SignInPage />
          }
        />
        <Redirect from="/" to={LAYOUT_DEFAULT} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
