import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ErrorMessage, Field } from "formik";
import { Select } from "formik-material-ui";
import React from "react";
import {
  blackColor,
  grayColor,
  hexToRgb,
  primaryBoxShadow,
  primaryColor,
  whiteColor,
} from "styles/material-dashboard-pro-react";

const useStyles = makeStyles({
  select: {
    padding: "12px 0 7px",
    fontSize: ".75rem",
    fontWeight: 400,
    lineHeight: "1.42857",
    textDecoration: "none",
    color: grayColor[2],
    letterSpacing: "0",
    "&:focus": {
      backgroundColor: "transparent",
    },
    "&[aria-owns] + input + svg": {
      transform: "rotate(180deg)",
    },
    "& + input + svg": {
      transition: "all 300ms linear",
    },
  },
  selectFormControl: {
    margin: "7px 0 17px 0 !important",
    "& > div": {
      "&:before": {
        borderBottomWidth: "1px !important",
        borderBottomColor: grayColor[4] + "!important",
      },
      "&:after": {
        borderBottomColor: primaryColor[0] + "!important",
      },
    },
  },
  selectLabel: {
    fontSize: "12px",
    textTransform: "uppercase",
    color: grayColor[2] + " !important",
    top: "8px",
  },
  selectMenu: {
    "& > div > ul": {
      border: "0",
      padding: "5px 0",
      margin: "0",
      boxShadow: "none",
      minWidth: "100%",
      borderRadius: "4px",
      boxSizing: "border-box",
      display: "block",
      fontSize: "14px",
      textAlign: "left",
      listStyle: "none",
      backgroundColor: whiteColor,
      backgroundClip: "padding-box",
    },
    "& $selectPaper $selectMenuItemSelectedMultiple": {
      backgroundColor: "inherit",
    },
    "& > div + div": {
      maxHeight: "266px !important",
    },
  },
  selectMenuItem: {
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: 400,
    lineHeight: "2",
    whiteSpace: "nowrap",
    color: grayColor[7],
    paddingRight: "30px",
    "&:hover": {
      backgroundColor: primaryColor[0],
      color: whiteColor,
      ...primaryBoxShadow,
    },
  },
  selectMenuItemSelected: {
    backgroundColor: primaryColor[0] + "!important",
    color: whiteColor,
  },
  selectMenuItemSelectedMultiple: {
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: primaryColor[0] + "!important",
      color: whiteColor,
      ...primaryBoxShadow,
      "&:after": {
        color: whiteColor,
      },
    },
    "&:after": {
      top: "16px",
      right: "12px",
      width: "12px",
      height: "5px",
      borderLeft: "2px solid currentColor",
      transform: "rotate(-45deg)",
      opacity: "1",
      color: grayColor[2],
      position: "absolute",
      content: "''",
      borderBottom: "2px solid currentColor",
      transition: "opacity 90ms cubic-bezier(0,0,.2,.1)",
    },
  },
  selectPaper: {
    boxSizing: "border-box",
    borderRadius: "4px",
    padding: "0",
    minWidth: "100%",
    display: "block",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.26)",
    backgroundClip: "padding-box",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "transparent",
    maxHeight: "266px",
  },
});

export type Item = {
  id: string;
  label: string;
};

type OnChangeHandler = (
  event: React.ChangeEvent<{ name?: string; value: unknown }>
) => void;

type Props = {
  items: Item[];
  label: string;
  name: string;
  onChange?: OnChangeHandler;
  disabled?: boolean;
};

const FormSelect = ({
  items,
  name,
  label,
  disabled,
  onChange,
}: Props): JSX.Element => {
  const classes = useStyles();
  const fieldProps: {
    onChange?: OnChangeHandler;
  } = {};
  if (onChange) {
    fieldProps["onChange"] = onChange;
  }

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor={name} className={classes.selectLabel}>
        {label}
      </InputLabel>
      <Field
        component={Select}
        name={name}
        native={true}
        disabled={disabled}
        inputProps={{
          id: name,
        }}
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        {...fieldProps}
      >
        <option value={""} key={"emptyItem"} disabled={true} />
        {items.map(({ id, label }) => {
          return (
            <option
              value={id}
              key={`${name}-${id}`}
              className={classes.selectMenuItem}
            >
              {label}
            </option>
          );
        })}
      </Field>
      <ErrorMessage
        name={name}
        render={(errorMessage) => (
          <FormHelperText error={true}>{errorMessage}</FormHelperText>
        )}
      />
    </FormControl>
  );
};

export default FormSelect;
