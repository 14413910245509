import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "components/Button";
import Table from "components/Table/Table";
import {
  DefaultFetchDeviceFilters,
  DurationTestStatus,
} from "Device/definitions";
import { Device } from "Device/Device";
import deviceType from "Device/deviceType";

import { isOnlineFormatter, uidFormatter } from "Device/formatters";
import DeviceInfoButton from "Device/Info/DeviceInfoButton";
import { FetchDevicePageOptions } from "Device/definitions";
import { useFetchDevicePage } from "Device/requests";
import { SupportedTypes } from "Device/supportedTypes";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import DeviceCalibrationDataDialog from "./DeviceCalibrationDataDialog";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  nextStep?: () => void;
  onRowClick: (device: Device | null) => void;
  autoRefresh: boolean;
};

const DeviceCalibrationSearch = ({
  onRowClick,
  nextStep,
  autoRefresh,
}: Props): JSX.Element => {
  const classes = useStyles(),
    [t] = useTranslation("di"),
    columns = React.useMemo<Column<Device>[]>(
      () => [
        {
          Header: `${t("device.uid")}`,
          accessor: "uid",
          Cell: uidFormatter,
        },
        {
          Header: `${t("device.serialNr")}`,
          accessor: "serial",
        },
        {
          Header: `${t("device.ownerLabel")}`,
          accessor: "label",
        },
        {
          Header: `${t("device.isOnline")}`,
          accessor: "isOnline",
          Cell: isOnlineFormatter,
        },
        {
          Header: `${t("device.type")}`,
          accessor: "type",
          Cell: ({ value }: { value: SupportedTypes }) =>
            deviceType[value] ?? value,
        },
        {
          Header: "",
          id: "action",
          Cell: DeviceInfoButton,
        },
      ],
      [t]
    ),
    [missingDataDialogOpen, showMissingDataDialog] = useState(false),
    [clickedDevice, setClickedDevice] = useState<Device>();
  const { enqueueSnackbar } = useSnackbar();
  const filterGroups = {
    type: { label: t("device.type"), options: deviceType },
  };
  const { fetchDevicePage } = useFetchDevicePage();

  const onFetchData = async (options: FetchDevicePageOptions) =>
    fetchDevicePage(
      {
        ...DefaultFetchDeviceFilters,
        inService: true,
        durationTestStatus: [DurationTestStatus.DurationTestNotStarted],
      },
      options
    );

  const onRowClickInternal = (device: Device): boolean => {
    setClickedDevice(device);
    if (!device) {
      onRowClick(null);
      return false;
    }

    if (!device.editable) {
      enqueueSnackbar(t("device.rights.producerOnlyError"), {
        variant: "error",
      });
      onRowClick(null);
      return false;
    }

    !device.serial ? showMissingDataDialog(true) : onDeviceSelected(device);
    return true;
  };

  const onMissingDataSaved = (item: Device) => {
    showMissingDataDialog(false);
    onDeviceSelected(item);
  };

  const onDeviceSelected = (item: Device) => {
    onRowClick(item);
    nextStep?.();
  };

  return (
    <>
      <Table
        columns={columns}
        onFetchData={onFetchData}
        onRowClick={onRowClickInternal}
        autoRefresh={autoRefresh}
        filterGroups={filterGroups}
        rowIdProp="uid"
      />
      <div>
        <Button disabled={true} className={classes.button}>
          {t("common:general.back")}
        </Button>
      </div>
      <DeviceCalibrationDataDialog
        open={missingDataDialogOpen}
        setOpen={showMissingDataDialog}
        device={clickedDevice}
        onUpdated={onMissingDataSaved}
      />
    </>
  );
};

export default DeviceCalibrationSearch;
