import { FetchDeviceFilters } from "Device/definitions";
import { Device } from "Device/Device";
import DeviceDurationTestFinishedSearch from "Device/DurationTest/DeviceDurationTestFinishedSearch";
import React from "react";
import { Column } from "react-table";

type Props = {
  deviceFilters: FetchDeviceFilters;
  actionTitle: string;
  confirmActionText: string;
  columns: Column<Device>[];
  autoRefresh: boolean;
  onUpdate?: () => void;
};

const DeviceDurationTestFinished = ({
  deviceFilters,
  columns,
  autoRefresh,
  onUpdate,
}: Props): JSX.Element => {
  const [selected, setSelected] = React.useState<string[]>([]);
  const [loaded, setLoaded] = React.useState<Map<string, Device>>(new Map());
  const onDataFetched = (array: Device[]) => {
    setLoaded(
      new Map([...loaded].concat(array.map((device) => [device.uid, device])))
    );
    onUpdate && onUpdate();
  };

  return (
    <>
      <DeviceDurationTestFinishedSearch
        onSelectionChanged={setSelected}
        onDataFetched={onDataFetched}
        initialSelectedRowIds={selected}
        deviceFilters={deviceFilters}
        columns={columns}
        autoRefresh={autoRefresh}
      />
    </>
  );
};

export default DeviceDurationTestFinished;
