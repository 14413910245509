import { Box, Grid, Tab } from "@material-ui/core";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import CustomInput from "components/Form/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Device } from "Device/Device";
import DurationTestReports from "Device/DeviceInfoPage/DurationTestReports";

import deviceType from "Device/deviceType";
import { getDevice } from "Device/requests";
import { RDI_3000, RSS_3000 } from "Device/supportedTypes";

import countries from "i18n-iso-countries";
import i18n from "i18next";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";

import Divider from "./Divider";
import Indicators from "./Indicators";
import RdiReports from "./Rdi/Reports";
import UpdateForm from "./UpdateForm";
import ZklRcReports from "./ZklRc/Reports";
import RssReports from "./Rss/Reports";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import FotaDeviceInfoPage from "Device/FotaDeviceInfoPage";

export default function DeviceInfoPage(): JSX.Element {
  const { uid } = useParams<{ uid: string }>();
  const [device, setDevice] = React.useState<Device | null>(null);
  const [t] = useTranslation("app");
  const [tab, setTab] = React.useState("deviceTab");
  const stagingFeature = window.location.hostname != "sam.dualinventive.com";

  const handleChange = (event: ChangeEvent<unknown>, newValue: string) => {
    setTab(newValue);
  };

  useAsyncEffect(async () => {
    const response = await getDevice(uid);

    setDevice(response.data);
  }, [uid, setDevice]);

  if (!device) {
    return <></>;
  }

  return (
    <div>
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <TabList
            onChange={handleChange}
            aria-label="device page tabs"
            indicatorColor="secondary"
          >
            <Tab label={t("common:tabs.device")} value="deviceTab" />
            <Tab label={t("common:tabs.reports")} value="reportsTab" />
            {stagingFeature ? (
              <Tab label={t("common:tabs.fota")} value="fotaTab" />
            ) : (
              []
            )}
          </TabList>
        </Box>
        <TabPanel value="deviceTab">
          <Card>
            <CardHeader color="warning" text>
              <Grid container wrap="nowrap">
                <Grid item>
                  <CardText color="warning">
                    <h4>{t("common:general.info")}</h4>
                  </CardText>
                </Grid>
                <Indicators device={device} />
              </Grid>
            </CardHeader>

            <CardBody>
              <GridContainer>
                <GridItem>
                  <CustomInput
                    labelText={t("di:device.type")}
                    underline={false}
                    labelProps={{ shrink: true }}
                    inputProps={{
                      value: (device?.type && deviceType[device.type]) ?? "",
                      disabled: true,
                    }}
                  />
                </GridItem>
                {device.type.startsWith("zkl-3000") && (
                  <GridItem>
                    <CustomInput
                      labelText={t("di:device.version")}
                      underline={false}
                      labelProps={{ shrink: true }}
                      inputProps={{
                        value: device?.version || "-",
                        disabled: true,
                      }}
                    />
                  </GridItem>
                )}
                <GridItem>
                  <CustomInput
                    labelText={t("di:device.country")}
                    underline={false}
                    labelProps={{ shrink: true }}
                    inputProps={{
                      value: device?.country
                        ? countries.getName(device.country, i18n.language, {
                            select: "official",
                          })
                        : "-",
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem>
                  <CustomInput
                    labelText={t("di:device.ownerLabel")}
                    underline={false}
                    labelProps={{ shrink: true }}
                    inputProps={{
                      value: device?.label || "-",
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem>
                  <CustomInput
                    labelText={t("di:device.ncmType")}
                    underline={false}
                    labelProps={{ shrink: true }}
                    inputProps={{
                      value: device?.ncmType?.toUpperCase() || "-",
                      disabled: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Divider />

              <UpdateForm device={device} />
            </CardBody>
          </Card>
        </TabPanel>

        <TabPanel value="reportsTab">
          <Card>
            {device.type === RDI_3000 ? (
              <RdiReports />
            ) : device.type === RSS_3000 ? (
              <RssReports />
            ) : (
              <ZklRcReports />
            )}
          </Card>
          <Card>
            <DurationTestReports />
          </Card>
        </TabPanel>
        {stagingFeature ? (
          <TabPanel value="fotaTab">
            <Card>
              <FotaDeviceInfoPage />
            </Card>
          </TabPanel>
        ) : (
          []
        )}
      </TabContext>
    </div>
  );
}
