import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  delimiter: {
    marginRight: theme.spacing(1),
  },
}));

type StringProps = {
  value: string;
  interval: number;
};

const StringFormatter = ({ value, interval }: StringProps): JSX.Element => {
  const split = value.match(new RegExp(`.{1,${interval}}`, "g"));
  const classes = useStyles();

  if (!split) {
    return <></>;
  }

  return (
    <>
      {split.slice(0, -1).map((v, i) => (
        <React.Fragment key={i}>
          {<span className={classes.delimiter}>{v}</span>}
        </React.Fragment>
      ))}
      {split.slice(-1)}
    </>
  );
};

type UidProps = Omit<StringProps, "interval">;

export const Uid = ({ value }: UidProps): JSX.Element => {
  return <StringFormatter value={value} interval={4} />;
};

export const uidFormatter = ({ value }: UidProps): JSX.Element => (
  <Uid value={value} />
);

export const fotaJobStateFormatter = ({ value }: UidProps): JSX.Element => {
  const [t] = useTranslation("app");
  return (
    <>
      {t(`device.fotaJobs.states.${value}`, {
        defaultValue: value,
      })}
    </>
  );
};

type ImsiProps = Omit<StringProps, "interval">;

export const Imsi = ({ value }: ImsiProps): JSX.Element => {
  return <StringFormatter value={value} interval={3} />;
};

type IsOnlineProps = {
  value: boolean;
};

export const IsOnline = ({ value }: IsOnlineProps): JSX.Element => {
  const [t] = useTranslation("common");

  return <>{value ? t("general.yes") : t("general.no")}</>;
};

export const isOnlineFormatter = ({ value }: IsOnlineProps): JSX.Element => (
  <IsOnline value={value} />
);

export const dateTimeFormatter = (value: string): string =>
  new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(Date.parse(value));

export const DateTime = ({ value }: { value: string }): string => {
  return dateTimeFormatter(value);
};

export const dateInputFormat = "yyyy-MM-dd";

export const baFormatter = (ba: number | string): number | string =>
  typeof ba === "number" ? +ba.toFixed(3) : ba;
