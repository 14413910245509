import * as Sentry from "@sentry/react";

import AppContextProvider from "AppContextProvider";
import AppRouter from "AppRouter";
import { useAuth } from "auth";
import Loader from "components/Loader";
import ErrorFallback from "error/ErrorFallback";
import { DialogProvider } from "hooks/Dialog";

import { SnackbarProvider } from "notistack";
import React, { Suspense } from "react";

export default Sentry.withProfiler(() => {
  const auth = useAuth();
  if (auth.isLoading) return <Loader />;
  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <Suspense fallback={<Loader />}>
          <AppContextProvider>
            <DialogProvider>
              <AppRouter />
            </DialogProvider>
          </AppContextProvider>
        </Suspense>
      </Sentry.ErrorBoundary>
    </SnackbarProvider>
  );
});
