import React from "react";
import { useTranslation } from "react-i18next";

import { Chip } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Build,
  Delete,
  Lock,
  PlayArrow,
  Public,
  SignalWifi0Bar,
  SignalWifi4Bar,
} from "@material-ui/icons";

export const IsOnlineIndicator = ({ isOnline }: { isOnline?: boolean }) => {
  const classes = useStyles();
  const [t] = useTranslation("app");
  return (
    <Chip
      className={classes.chip}
      icon={isOnline ? <SignalWifi4Bar /> : <SignalWifi0Bar />}
      label={isOnline ? t("device.info.online") : t("device.info.offline")}
    />
  );
};

export const InServiceIndicator = ({ inService }: { inService?: boolean }) => {
  const classes = useStyles();
  const [t] = useTranslation("app");
  return (
    <Chip
      className={classes.chip}
      icon={inService ? <Build /> : <Public />}
      label={
        inService ? t("device.info.inService") : t("device.info.onProduction")
      }
    />
  );
};

export const StatusCustomerIndicator = ({
  statusCustomer,
}: {
  statusCustomer?: string;
}) => {
  const classes = useStyles();
  const [t] = useTranslation("di");
  if (!statusCustomer) return null;
  return (
    <Chip
      className={classes.chip}
      icon={statusCustomer === "ACTIVE" ? <PlayArrow /> : <Delete />}
      label={`${t("device.statusCustomer")}: ${t(
        `statusCustomer.${statusCustomer}`
      )}`}
    />
  );
};

export const ProjectIndicator = ({ projectName }: { projectName?: string }) => {
  const classes = useStyles();
  if (!projectName) return null;
  return <Chip className={classes.chip} icon={<Lock />} label={projectName} />;
};

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
}));
