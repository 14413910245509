import DurationTestDetailPanel from "Device/DeviceInfoPage/DurationTestDetailPanel";
import { DurationTestReport } from "Device/Report/DurationTestReport";
import ReportLanguageDialog from "Device/Report/ReportLanguageDialog";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Column, UseTableRowProps } from "react-table";

import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import ExpansionCell from "components/Table/ExpansionCell";
import Table, { PageOptions } from "components/Table/Table";

import { DateTime } from "Device/formatters";
import {
  downloadDeviceDurationTestReport,
  useFetchDeviceDurationTestReportPage,
} from "Device/requests";
import DownloadDeliveryReportButton from "Device/Info/DownloadDeliveryReportButton";

export default function DurationTestReports() {
  const { uid } = useParams<{ uid: string }>();
  const [t] = useTranslation("app");
  const [showingLanguageSelection, showLanguageSelection] = useState(false);
  const [selectedReport, selectReport] = useState<number>(0);
  const { fetchDurationTestReportPage } =
    useFetchDeviceDurationTestReportPage();

  const columns = useMemo<Column<DurationTestReport>[]>(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ExpansionCell,
      },
      {
        Header: t<string>("device.report.startedAt"),
        accessor: "startedAt",
        Cell: DateTime,
      },
      {
        Header: t<string>("device.report.endedAt"),
        accessor: "updatedAt",
        Cell: DateTime,
      },
      {
        Header: t<string>("device.durationTest.gpsCount"),
        accessor: "noOfGPSMessages",
      },
      {
        Header: t<string>("device.durationTest.heartBeatCount"),
        accessor: "noOfHeartbeats",
      },
      {
        Header: t<string>("device.durationTest.disconnectCount"),
        accessor: "noOfDisconnects",
      },
      {
        Header: "",
        id: "action",
        Cell: ({ row }: { row: UseTableRowProps<DurationTestReport> }) => (
          <DownloadDeliveryReportButton
            onClick={() => {
              showLanguageSelection(true);
              selectReport(row.original.id);
            }}
          />
        ),
      },
    ],
    [t]
  );

  const fetchData = async (options: PageOptions) => {
    return await fetchDurationTestReportPage(uid, options);
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <Table
        containerHeader={
          <CardHeader color="warning" text>
            <CardText color="warning">
              <h4>{t("device.report.durationTestReports")}</h4>
            </CardText>
          </CardHeader>
        }
        columns={columns}
        onFetchData={fetchData}
        onRowExpanded={(row: UseTableRowProps<DurationTestReport>) => (
          <DurationTestDetailPanel row={row} />
        )}
      />

      <ReportLanguageDialog
        setOpen={showLanguageSelection}
        onClose={async (language: string) => {
          if (!selectedReport) return;
          await downloadDeviceDurationTestReport(uid, selectedReport, language);
        }}
        open={showingLanguageSelection}
        languages={["en", "nl", "fr", "de"]}
      />
    </div>
  );
}
