import { List } from "@material-ui/core";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "components/Button";
import { Device } from "Device/Device";
import deviceType from "Device/deviceType";
import { Transfer, DurationTestStatus } from "Device/definitions";
import { transferDurationTestStatus } from "Device/requests";
import DeviceListItem from "Device/Service/DeviceListItem";
import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  devices: Device[];
  previousStep?: MouseEventHandler<HTMLButtonElement>;
  confirmActionText: string;
  actionBtnText: string;
  requests: Map<string, Transfer>;
};

const DeviceStartDurationTestStatus = ({
  devices,
  previousStep,
  requests,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [t] = useTranslation("common");

  return (
    <>
      <List>
        {devices.map(({ type, serial, label, uid }) => (
          <DeviceListItem
            key={uid}
            serialNr={serial}
            ownerLabel={label}
            deviceTypeName={deviceType[type] ?? type}
            request={requests.get(uid)}
            onRetryClick={() =>
              transferDurationTestStatus(
                uid,
                DurationTestStatus.DurationTestInProgress
              )
            }
          />
        ))}
      </List>
      <div>
        <Button onClick={previousStep} className={classes.button}>
          {t("general.back")}
        </Button>
      </div>
    </>
  );
};

export default DeviceStartDurationTestStatus;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  selectControl: {
    width: "100%",
  },
  select: {
    width: "100%",
  },
}));
