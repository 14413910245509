import Wizard from "components/Wizard/Wizard";
import React, { useReducer } from "react";
import SelectUpdatesStep from "./SelectUpdatesStep";
import { UpdatesContext, updateReducer } from "./reducers";
import PreDownloadCheckStep from "./PreDownloadCheckStep";

type Props = {
  selectDevicesTitle: string;
  actionTitle: string;
  autoRefresh: boolean;
  onUpdate?: () => void;
};

const CreateFotaJob = ({
  selectDevicesTitle,
  actionTitle,
}: Props): JSX.Element => {
  const [updates, updatesDispatch] = useReducer(updateReducer, {});

  return (
    <UpdatesContext.Provider value={updates}>
      <Wizard labels={[{ name: actionTitle }, { name: selectDevicesTitle }]}>
        <SelectUpdatesStep updatesDispatch={updatesDispatch} />
        <PreDownloadCheckStep updatesDispatch={updatesDispatch} />
      </Wizard>
    </UpdatesContext.Provider>
  );
};
export default CreateFotaJob;
