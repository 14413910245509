import axios from "axios";
import { AppContext } from "context/context";
import useAxiosInterceptors from "hooks/AxiosInterceptors";
import PerfectScrollbar from "perfect-scrollbar";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

type Props = {
  children?: JSX.Element | JSX.Element[];
};

const AppContextProvider = function ({ children }: Props): JSX.Element {
  const { addResponseInterceptor } = useAxiosInterceptors(),
    responseInterceptorRef = useRef(-1),
    [ps, setPs] = useState<null | PerfectScrollbar>(null);

  if (responseInterceptorRef.current === -1) {
    responseInterceptorRef.current = addResponseInterceptor(axios);
  }

  return (
    <AppContext.Provider value={{ ps, setPs }}>{children}</AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node,
};

export default AppContextProvider;
