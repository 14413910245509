import { useFetchFotaHistory } from "Device/requests";
import Table from "components/Table/Table";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FotaDeviceHistory } from "Device/Device";
import { Column } from "react-table";
import { DateTime } from "Device/formatters";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import { FetchDevicePageOptions } from "Device/definitions";

const FotaDeviceInfoPage = (): JSX.Element => {
  const { uid } = useParams<{ uid: string }>();
  const [t] = useTranslation(["app"]);
  const { fetchFotaHistory } = useFetchFotaHistory();

  const columns = React.useMemo<Column<FotaDeviceHistory>[]>(
    () => [
      {
        Header: t<string>("device.fotaDeviceInfo.updatedDate"),
        accessor: "updatedTime",
        Cell: DateTime,
      },
      {
        Header: t<string>("device.fotaDeviceInfo.updatedBy"),
        accessor: "updatedBy",
      },
      {
        Header: t<string>("device.fotaDeviceInfo.firmwareType"),
        accessor: "firmwareType",
      },
      {
        Header: t<string>("device.fotaDeviceInfo.previousVersion"),
        accessor: "previousFirmwareVersion",
      },
      {
        Header: t<string>("device.fotaDeviceInfo.currentVersion"),
        accessor: "currentFirmwareVersion",
      },
      {
        Header: t<string>("device.fotaDeviceInfo.status"),
        accessor: "status",
      },
    ],
    [t]
  );

  const fetchHistoryData = async (options: FetchDevicePageOptions) => {
    return await fetchFotaHistory(uid, options);
  };

  return (
    <Table
      containerHeader={
        <CardHeader color="warning" text>
          <CardText color="warning">
            <h4>{t("device.fotaDeviceInfo.tableTitle")}</h4>
          </CardText>
        </CardHeader>
      }
      columns={columns}
      onFetchData={fetchHistoryData}
      autoRefresh={false}
      rowIdProp="updatedTime"
    />
  );
};

export default FotaDeviceInfoPage;
