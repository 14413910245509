import { Ba, JumperBox, ResistanceBox } from "Device/Calibration/Calibration";
import { Composition } from "Device/Calibration/Composition/CompositionSchema";
import RdiPreFlightStep from "Device/Calibration/Rdi/PreFlightStep";
import RssPreFlightStep from "Device/Calibration/Rss/PreFlightStep";
import ZklRcPreFlightStep from "Device/Calibration/ZklRc/PreFlightStep";
import { Device } from "Device/Device";
import { RDI_3000, RSS_3000 } from "Device/supportedTypes";
import React, { Dispatch, SetStateAction } from "react";
import { StepWizardChildProps } from "react-step-wizard";

type Props = {
  device: Device | null;
  setDevice?: Dispatch<SetStateAction<Device | null>>;
  composition: Composition | null;
  setComposition: React.Dispatch<React.SetStateAction<Composition | null>>;
  setSelectedDevice: React.Dispatch<React.SetStateAction<Device | null>>;
  setSelectedJumperBox: React.Dispatch<React.SetStateAction<JumperBox | null>>;
  setSelectedResistanceBox: React.Dispatch<
    React.SetStateAction<ResistanceBox | null>
  >;
  resistanceBox: ResistanceBox | null;
  setResistanceBox: (rb: ResistanceBox | null) => void;
  jumperBox: JumperBox | null;
  setJumperBox: (jb: JumperBox | null) => void;
  ba: Ba;
  setBa: (ba: Ba) => void;
  dropOff: string;
  setDropOff: (dropOff: string) => void;
} & Partial<StepWizardChildProps>;

const PreFlightStep = ({
  device,
  composition,
  setComposition,
  setSelectedDevice,
  setSelectedJumperBox,
  setSelectedResistanceBox,
  resistanceBox,
  jumperBox,
  ba,
  setBa,
  dropOff,
  setDropOff,
  previousStep,
  nextStep,
}: Props): JSX.Element => {
  switch (device?.type) {
    case RDI_3000:
      return (
        <RdiPreFlightStep
          device={device}
          composition={composition}
          setComposition={setComposition}
          previousStep={previousStep}
          nextStep={nextStep}
        />
      );
    case RSS_3000:
      return (
        <RssPreFlightStep
          device={device}
          setDevice={setSelectedDevice}
          composition={composition}
          setComposition={setComposition}
          resistanceBox={resistanceBox}
          setResistanceBox={setSelectedResistanceBox}
          previousStep={previousStep}
          nextStep={nextStep}
        />
      );
    default:
      return (
        <ZklRcPreFlightStep
          device={device}
          setDevice={setSelectedDevice}
          composition={composition}
          setComposition={setComposition}
          resistanceBox={resistanceBox}
          setResistanceBox={setSelectedResistanceBox}
          jumperBox={jumperBox}
          setJumperBox={setSelectedJumperBox}
          ba={ba}
          setBa={setBa}
          dropOff={dropOff}
          setDropOff={setDropOff}
          previousStep={previousStep}
          nextStep={nextStep}
        />
      );
  }
};

export default PreFlightStep;
