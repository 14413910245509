import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  makeStyles,
  Radio,
  Typography,
} from "@material-ui/core";
import { ErrorMessage, Field } from "formik";
import { RadioGroup } from "formik-material-ui";
import React from "react";

const useStyles = makeStyles(() => ({
  radioGroupLabel: {
    fontSize: "9pt",
    color: "#AAAAAA !important",
    marginTop: "12px",
  },
  radioFormLabel: {
    fontSize: "9pt",
    color: "#AAAAAA !important",
  },
  radioFormButtons: {
    padding: "3px",
    paddingLeft: "9px",
    "& svg": {
      width: ".75em",
      height: ".75em",
    },
  },
}));

interface Props {
  controlName: string;
  controlLabelText: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  options: string[];
  disabled: boolean;
}

const FormRadioGroup = function ({
  onChange,
  controlName,
  controlLabelText,
  options,
  disabled,
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <FormControl component="fieldset">
      <FormLabel className={classes.radioGroupLabel}>
        {controlLabelText}
      </FormLabel>
      <Field
        component={RadioGroup}
        aria-label={controlName}
        name={controlName}
        onChange={onChange}
      >
        {options.map((item) => {
          return (
            <FormControlLabel
              key={item + "Radio"}
              className={classes.radioFormLabel}
              value={item}
              control={
                <Radio
                  className={classes.radioFormButtons}
                  color="default"
                  disabled={disabled}
                />
              }
              label={
                <Typography className={classes.radioFormLabel}>
                  {item}
                </Typography>
              }
              disabled={disabled}
            />
          );
        })}
      </Field>
      <ErrorMessage
        name={controlName}
        render={(errorMessage) => (
          <FormHelperText error={true}>{errorMessage}</FormHelperText>
        )}
      />
    </FormControl>
  );
};

export default FormRadioGroup;
