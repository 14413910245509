import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import CustomInputField from "components/Form/CustomInputField";
import FormCheckbox from "components/Form/FormCheckbox";
import {
  ResistanceBox,
  ResistanceBoxLabel,
} from "Device/Calibration/Calibration";
import { Composition } from "Device/Calibration/Composition/CompositionSchema";
import VerificationStep, {
  FinishSelfTest,
} from "Device/Calibration/Verification/VerificationStep";
import { Device } from "Device/Device";
import { downloadRssReport } from "Device/requests";
import { Field } from "formik";
import { FormikValues } from "formik/dist/types";
import { WebSocketHook } from "hooks/WebSocket";
import React from "react";
import { useTranslation } from "react-i18next";
import { boolean, string } from "yup";

type Props = {
  device: Device | null;
  composition: Composition | null;
  resistanceBox: ResistanceBox | null;
  previousStep?: () => void;
  firstStep?: () => void;
  finishSelfTest: FinishSelfTest;
  lastJsonMessage?: WebSocketHook["lastJsonMessage"];
};

const RssVerificationStep = ({
  device,
  composition,
  resistanceBox,
  previousStep,
  firstStep,
  finishSelfTest,
  lastJsonMessage,
}: Props): JSX.Element => {
  const [t] = useTranslation("app");

  const finishDataProvider = ({ remarks }: FormikValues) => {
    return {
      resistanceBox: resistanceBox?.label ?? "Unknown",
      remark: remarks,
      deviceType: device?.type ?? "Unknown",
    };
  };

  return (
    <VerificationStep
      device={device}
      composition={composition}
      initialValues={{
        blueLedOK: false,
        switchOnSuccess: false,
        switchOffSuccess: false,
        greenLEDOnSwitchOn: false,
        remark: "",
        resistanceBox: ResistanceBoxLabel(resistanceBox),
      }}
      schema={{
        blueLedOK: boolean().equals([true]),
        switchOnSuccess: boolean().equals([true]),
        switchOffSuccess: boolean().equals([true]),
        greenLEDOnSwitchOn: boolean().equals([true]),
        remark: string(),
      }}
      finishSelfTest={finishSelfTest}
      finishDataProvider={finishDataProvider}
      previousStep={previousStep}
      firstStep={firstStep}
      lastJsonMessage={lastJsonMessage}
      downloadReport={downloadRssReport}
      languages={["en", "nl", "fr", "de"]}
    >
      <FormGroup>
        <FormLabel component="h3">
          {t("device.calibration.verifyStep.rss.duringTesting")}
        </FormLabel>

        <FormCheckbox
          label={t("device.calibration.verifyStep.rss.greenLEDOnSwitchOn")}
          name="greenLEDOnSwitchOn"
        />

        <FormCheckbox
          label={t("device.calibration.verifyStep.rss.blueLedOK")}
          name="blueLedOK"
        />
      </FormGroup>
      <FormGroup>
        <FormCheckbox
          label={t("device.calibration.verifyStep.rss.switchOnSuccess")}
          name="switchOnSuccess"
        />
        <FormCheckbox
          label={t("device.calibration.verifyStep.rss.switchOffSuccess")}
          name="switchOffSuccess"
        />
      </FormGroup>
      <Field
        labelText={t("device.calibration.verifyStep.remarks")}
        component={CustomInputField}
        id="remarks"
        name="remarks"
        multiline
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{ multiline: true }}
      />
    </VerificationStep>
  );
};

export default RssVerificationStep;
