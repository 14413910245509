import { Composition } from "Device/Calibration/Composition/CompositionSchema";
import PreFlightStepBase from "Device/Calibration/PreFlightStepBase";
import { Device } from "Device/Device";
import React from "react";
import { StepWizardChildProps } from "react-step-wizard";

type Props = {
  device: Device | null;
  composition: Composition | null;
  setComposition: React.Dispatch<React.SetStateAction<Composition | null>>;
} & Partial<StepWizardChildProps>;

const RdiPreFlightStep = ({
  device,
  composition,
  setComposition,
  previousStep,
  nextStep,
}: Props): JSX.Element => {
  if (!device) return <></>;

  return (
    <PreFlightStepBase
      device={device}
      composition={composition}
      setComposition={setComposition}
      hasConfigurableServiceInterval={false}
      previousStep={previousStep}
      nextStep={nextStep}
    />
  );
};

export default RdiPreFlightStep;
