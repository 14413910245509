import useIsMounted from "hooks/IsMounted";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import { AxiosResponse } from "axios";
import { AnyObjectSchema, SchemaOf } from "yup";

type Hook = {
  validate: <T>(schema: AnyObjectSchema, response: AxiosResponse) => Promise<T>;
};

const useResponseValidator = (): Hook => {
  const [t] = useTranslation("common"),
    { enqueueSnackbar } = useSnackbar(),
    isMounted = useIsMounted();

  return {
    validate: async <T>(schema: SchemaOf<T>, response: AxiosResponse) => {
      const { config, data } = response;
      try {
        return await schema.validate(data);
      } catch (e) {
        const { url } = config;
        if (isMounted()) {
          enqueueSnackbar(t("request.invalidResponseData", { url }), {
            variant: "error",
          });
        }
        Sentry.captureException(e);
        throw e;
      }
    },
  };
};

export default useResponseValidator;
