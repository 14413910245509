import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  CircularProgress,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Typography,
} from "@material-ui/core";
import { Check, Devices, ErrorOutline, Refresh } from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { Status, Transfer } from "Device/definitions";

import Concerns from "./Concerns";
import Button from "components/Button";

type DeviceListItemProps = {
  deviceTypeName: string;
  serialNr?: string;
  ownerLabel?: string;
  request?: Transfer;
  onRetryClick: MouseEventHandler<HTMLButtonElement>;
};

export default function DeviceListItem({
  deviceTypeName,
  serialNr,
  ownerLabel,
  request,
  onRetryClick,
}: DeviceListItemProps): JSX.Element {
  const classes = useStyles();
  const [t] = useTranslation("common");

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar>
            <Devices />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          secondary={deviceTypeName}
          primary={
            <>
              {serialNr}
              {ownerLabel && (
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textSecondary"
                >
                  {` - ${ownerLabel}`}
                </Typography>
              )}
            </>
          }
        />
        {request && (
          <RequestStatusIndicator status={request.status}>
            <Button
              justIcon
              round
              color="primary"
              onClick={onRetryClick}
              aria-label={t("general.retry")}
            >
              <Refresh />
            </Button>
          </RequestStatusIndicator>
        )}
      </ListItem>
      {request && request.status === Status.FAILURE && (
        <Concerns concerns={request.concerns} message={t(request.reason)} />
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  inline: {
    display: "inline",
  },
}));

type RequestStatusIndicatorProps = {
  children?: React.ReactNode[] | React.ReactNode;
  status: number;
};

function RequestStatusIndicator({
  children,
  status,
}: RequestStatusIndicatorProps) {
  switch (status) {
    case Status.IN_PROGRESS:
      return (
        <ListItemSecondaryAction>
          <CircularProgress />
        </ListItemSecondaryAction>
      );
    case Status.SUCCESS:
      return (
        <ListItemSecondaryAction>
          <Check />
        </ListItemSecondaryAction>
      );
    case Status.FAILURE:
      return (
        <ListItemSecondaryAction>
          {children || <ErrorOutline />}
        </ListItemSecondaryAction>
      );
    default:
      return null;
  }
}
