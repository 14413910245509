export type DurationTestReport = {
  id: number;
  uid: string;
  asset: number;
  noOfGPSMessages: number;
  lastDisconnectedAt: string;
  lastGPSAt: string;
  lastHeartbeatAt: string;
  noOfDisconnects: number;
  minimumRSSIValue: number;
  maximumRSSIValue: number;
  minimumRSRPValue: number;
  maximumRSRPValue: number;
  minimumAvgRSRQValue: number;
  maximumAvgRSRQValue: number;
  noOfHeartbeats: number;
  status: number;
  startedAt: string;
  updatedAt: string;
  remarks: string;
  concerns: Concern[];
};

export type Concern = {
  error_code: number;
  count: number;
};

export const DefaultDurationTestReport: DurationTestReport = {
  id: 0,
  uid: "",
  asset: 0,
  noOfGPSMessages: 0,
  lastDisconnectedAt: "",
  noOfDisconnects: 0,
  minimumRSSIValue: 0,
  maximumRSSIValue: 0,
  minimumRSRPValue: 0,
  minimumAvgRSRQValue: 0,
  maximumAvgRSRQValue: 0,
  maximumRSRPValue: 0,
  lastHeartbeatAt: "",
  lastGPSAt: "",
  noOfHeartbeats: 0,
  status: 0,
  startedAt: "",
  updatedAt: "",
  remarks: "",
  concerns: [],
};
