import { PageOptions } from "components/Table/Table";
import { Device, ZklRc } from "Device/Device";

export type Failure = 1;
export type Success = 0;

export type FailedTransfer = {
  status: Failure;
  reason: string;
  concerns?: TransferConcerns;
};

export type SuccessfulTransfer = {
  status: Success;
  data: string;
};

export type TransferConcerns = {
  errors?: {
    code: number;
    descr: string;
  }[];
  warnings?: {
    code: number;
    descr: string;
  }[];
};

export type FetchDeviceFilters = {
  inService: boolean;
  durationTestStatus: number[];
  transferStatus: number[];
  selfTest: number;
};

export const DefaultFetchDeviceFilters: FetchDeviceFilters = {
  inService: true,
  durationTestStatus: [],
  transferStatus: [],
  selfTest: 0,
};

export type FetchDevicePageOptions = {
  selectedFilters: Record<string, Record<string, string>>;
} & PageOptions;

export type PatchDevice = RequireAtLeastOne<
  Required<
    Pick<
      Device,
      | "serial"
      | "country"
      | "productionDate"
      | "productionNr"
      | "writtenOff"
      | "pcbSerials"
    > &
      Pick<ZklRc, "mustHaveAftc">
  >
>;

export type Transfer = FailedTransfer | SuccessfulTransfer;

export const Status: { IN_PROGRESS: -1; SUCCESS: Success; FAILURE: Failure } = {
  IN_PROGRESS: -1,
  SUCCESS: 0,
  FAILURE: 1,
};

export const TransferState: {
  DOMESTIC: 0;
  IN_PROGRESS: 1;
  FOREIGN: 2;
} = {
  DOMESTIC: 0,
  IN_PROGRESS: 1,
  FOREIGN: 2,
};

export const DurationTestStatus: {
  DurationTestNotStarted: 0;
  DurationTestInProgress: 1;
  DurationTestVerify: 2;
  DurationTestCanceled: 3;
  DurationTestSkipped: 4;
  DurationTestCompleted: 5;
  DurationTestIgnored: 6;
} = {
  DurationTestNotStarted: 0,
  DurationTestInProgress: 1,
  DurationTestVerify: 2,
  DurationTestCanceled: 3,
  DurationTestSkipped: 4,
  DurationTestCompleted: 5,
  DurationTestIgnored: 6,
};

export type FetchFotaDeviceFilters = {
  inService: boolean;
  transferStatus: number[];
  selfTest: number;
};
export const DefaultFotaFetchDeviceFilters: FetchFotaDeviceFilters = {
  inService: true,
  transferStatus: [],
  selfTest: 0,
};
