import { Device, FotaDeviceView } from "Device/Device";
import deviceType from "Device/deviceType";
import { isOnlineFormatter, uidFormatter } from "Device/formatters";
import DeviceInfoButton from "Device/Info/DeviceInfoButton";
import { SupportedTypes } from "Device/supportedTypes";
import React from "react";
import { TFunction } from "react-i18next";
import { Column } from "react-table";

export default function GetColumns(t: TFunction<string[]>): Column<Device>[] {
  return React.useMemo<Column<Device>[]>(
    () => [
      {
        Header: t<string>("di:device.uid"),
        accessor: "uid",
        Cell: uidFormatter,
      },
      {
        Header: t<string>("di:device.serialNr"),
        accessor: "serial",
      },
      {
        Header: t<string>("di:device.ownerLabel"),
        accessor: "label",
      },
      {
        Header: t<string>("di:device.isOnline"),
        accessor: "isOnline",
        Cell: isOnlineFormatter,
      },
      {
        Header: t<string>("di:device.type"),
        accessor: "type",
        Cell: ({ value }: { value: SupportedTypes }) =>
          deviceType[value] ?? value,
      },
      {
        Header: "",
        id: "action",
        Cell: DeviceInfoButton,
      },
    ],
    [t]
  );
}

export function GetFotaColumns(
  ft: TFunction<string[]>
): Column<FotaDeviceView>[] {
  return React.useMemo<Column<FotaDeviceView>[]>(
    () => [
      {
        Header: ft<string>("di:device.uid"),
        accessor: "uid",
        Cell: uidFormatter,
      },
      {
        Header: ft<string>("di:device.serialNr"),
        accessor: "serial",
      },
      {
        Header: ft<string>("di:device.ownerLabel"),
        accessor: "label",
      },
      {
        Header: ft<string>("di:device.isOnline"),
        accessor: "isOnline",
        Cell: isOnlineFormatter,
      },
    ],
    [ft]
  );
}
