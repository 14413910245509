import makeStyles from "@material-ui/core/styles/makeStyles";
import { useAuth } from "auth";
import Table from "components/Table/Table";
import { Device } from "Device/Device";
import deviceType from "Device/deviceType";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import Tooltip from "@material-ui/core/Tooltip";
import { FetchDeviceFilters, FetchDevicePageOptions } from "Device/definitions";
import { useFetchDevicePage } from "Device/requests";
import CellCheckbox from "Device/Service/CellCheckbox";
import HeaderCheckbox from "Device/Service/HeaderCheckbox";
import ToggleAllRowsSelectedHook from "Device/Service/ToggleAllRowsSelectedHook";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import Button from "components/Button";

type Props = {
  deviceFilters: FetchDeviceFilters;
  onSelectionChanged: (rowIDs: string[]) => void;
  onDataFetched: (devices: Device[]) => void;
  initialSelectedRowIds?: string[];
  nextStep?: () => void;
  columns: Column<Device>[];
  autoRefresh: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  selectControl: {
    width: "100%",
  },
  select: {
    width: "100%",
  },
}));

const DeviceDurationTestInProgressSearch = ({
  deviceFilters,
  onSelectionChanged,
  initialSelectedRowIds,
  onDataFetched,
  columns,
  autoRefresh,
  nextStep,
}: Props): JSX.Element => {
  const { profile } = useAuth();
  const classes = useStyles();
  const [t] = useTranslation("di"),
    [selected, setSelected] = React.useState<string[]>([]);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const filterGroups = {
    type: { label: t("device.type"), options: deviceType },
  };
  const { fetchDevicePage } = useFetchDevicePage();

  const onRowClick = (): boolean => {
    return true;
  };

  useEffect(() => {
    onSelectionChanged(selected);
    setConfirmed(false);
  }, [selected, onSelectionChanged]);

  const fetchData = async (options: FetchDevicePageOptions) => {
    const page = await fetchDevicePage(deviceFilters, options, true);
    if (page.data === null) return page;
    onDataFetched(
      page.data.map((device: Device) => {
        device.editable = true;
        return device;
      })
    );
    return page;
  };

  return (
    <>
      <Table
        columns={columns}
        onFetchData={fetchData}
        onSelectionChanged={setSelected}
        initialSelectedRowIds={initialSelectedRowIds}
        selectionEnabled={true}
        autoRefresh={autoRefresh}
        filterGroups={filterGroups}
        rowIdProp="uid"
        cellCheckbox={CellCheckbox}
        headerCheckbox={HeaderCheckbox}
        toggleAllRowsSelectedHooks={[ToggleAllRowsSelectedHook]}
        onRowClick={onRowClick}
      />

      <div>
        <Button disabled={true} className={classes.button}>
          {t("common:general.back")}
        </Button>
        <Tooltip
          title={
            profile?.productionOnly
              ? (t("device.rights.noPermissionError") as string)
              : (t("device.durationTest.stopDurationTest") as string)
          }
        >
          <span>
            <Button
              color="primary"
              onClick={
                confirmed
                  ? () => {
                      nextStep && nextStep();
                    }
                  : () => {
                      setOpen(true);
                    }
              }
              className={classes.button}
              disabled={!selected.length || profile?.productionOnly}
            >
              {t("device.durationTest.stopDurationTest")}
            </Button>
          </span>
        </Tooltip>
      </div>
      <ConfirmDialog
        onConfirm={() => {
          setConfirmed(true);
          return nextStep ? nextStep() : null;
        }}
        open={open}
        setOpen={setOpen}
      >
        <h5>{t("device.durationTest.stopDurationPrompt")}</h5>
      </ConfirmDialog>
    </>
  );
};

export default DeviceDurationTestInProgressSearch;
