import { createContext } from "react";

export type updates = Record<string, number[]>;
export type updateReducerAction =
  | {
      action: "set" | "toggle";
      device: string;
      updates: number[];
    }
  | {
      action: "unset";
      device: string;
    }
  | {
      action: "keep";
      devices: string[];
    };

export const updateReducer = (
  state: updates,
  action: updateReducerAction
): updates => {
  switch (action.action) {
    case "set": {
      return {
        ...state,
        [action.device]: action.updates,
      };
    }
    case "toggle": {
      if (state[action.device] != undefined) {
        const next = { ...state };
        delete next[action.device];
        return next;
      }
      return {
        ...state,
        [action.device]: action.updates,
      };
    }
    case "unset": {
      const next = { ...state };
      delete next[action.device];
      return next;
    }
    case "keep": {
      const next = { ...state };
      Object.keys(next).forEach((cur) => {
        if (!action.devices.includes(cur)) {
          delete next[cur];
        }
      });
      return next;
    }
    default: {
      throw Error("Unknown action");
    }
  }
};

export const UpdatesContext = createContext<updates>({});
