import Badge from "@material-ui/core/Badge";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TabPanel from "components/TabPanel";
import DeviceDurationTestInProgress from "Device/DurationTest/DeviceDurationTestInProgress";
import DeviceDurationTestFinished from "Device/DurationTest/DeviceDurationTestFinished";
import DurationTestResultButton from "Device/DurationTest/DurationTestResultButton";
import DeviceInfoButton from "Device/Info/DeviceInfoButton";
import { useFetchDevicePage } from "Device/requests";
import React, { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import {
  dangerColor,
  successColor,
  warningColor,
} from "styles/material-dashboard-pro-react";
import DeviceStartDurationTest from "./DurationTest/DeviceStartDurationTest";
import { Device } from "./Device";
import {
  DefaultFetchDeviceFilters,
  DurationTestStatus,
  TransferState,
} from "./definitions";
import { Tabs, Tab } from "@material-ui/core";
import GetColumns from "./columns";
import { Check, Error } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  listItemIconSuccess: {
    color: successColor[0],
  },
  listItemIconWarning: {
    color: warningColor[0],
  },
  listItemIconError: {
    color: dangerColor[0],
  },
}));

type Props = {
  autoRefresh?: boolean;
};

const DurationTestPage = ({ autoRefresh = true }: Props): JSX.Element => {
  const classes = useStyles();
  const [t] = useTranslation(["app"]);
  const { fetchDevicePage } = useFetchDevicePage();
  const [finishTestCount, setFinishTestCount] = useState<number>(-1);
  const columns = GetColumns(t);
  const trimmedColumns = columns.slice().splice(0, columns.length - 1);
  const finishedColumns = React.useMemo<Column<Device>[]>(
    () => [
      ...trimmedColumns,
      {
        Header: t<string>("di:device.status"),
        accessor: "isPass",
        Cell: ({ value }: { value: boolean }) => {
          if (value) {
            return <Check className={classes.listItemIconSuccess} />;
          } else return <Error className={classes.listItemIconWarning} />;
        },
      },
      {
        Header: "",
        id: "action",
        Cell: DeviceInfoButton,
      },
      {
        Header: "",
        id: "results",
        Cell: DurationTestResultButton,
      },
    ],
    [t]
  );

  const inProgressColumns = React.useMemo<Column<Device>[]>(
    () => [
      ...trimmedColumns,
      {
        Header: t<string>("di:device.timeElapsed"),
        accessor: "elapsedTime",
        disableSortBy: true,
      },
      {
        Header: "",
        id: "action",
        Cell: DeviceInfoButton,
      },
    ],
    [t]
  );

  const [tabPanel, setTabPanel] = useState<number>(0);

  const changeTabPanel = (event: ChangeEvent<unknown>, newValue: number) => {
    setTabPanel(newValue);
  };

  const fetchData = async () => {
    const page = await fetchDevicePage(
      {
        ...DefaultFetchDeviceFilters,
        durationTestStatus: [DurationTestStatus.DurationTestVerify],
      },
      {
        pageIndex: 0,
        pageSize: 100,
        sortBy: [],
        globalFilter: "",
        selectedFilters: {},
      },
      true
    );
    if (page?.rowCount) {
      setFinishTestCount(page.rowCount);
    } else {
      setFinishTestCount(0);
    }
  };

  return (
    <div>
      <Tabs value={tabPanel} onChange={changeTabPanel}>
        <Tab label={t("device.durationTest.startDurationTest")} />
        <Tab label={t("device.durationTest.inDurationTest")} />
        <Tab
          label={
            finishTestCount > 0 ? (
              <Badge badgeContent={finishTestCount} color="error">
                {t("device.durationTest.finishDurationTest")}
              </Badge>
            ) : (
              t("device.durationTest.finishDurationTest")
            )
          }
        />
      </Tabs>
      <TabPanel value={tabPanel} index={0}>
        <DeviceStartDurationTest
          actionTitle={t("device.durationTest.startSkipDurationTest")}
          selectDevicesTitle={t("device.durationTest.selectDevices")}
          deviceFilters={{
            ...DefaultFetchDeviceFilters,
            inService: true,
            transferStatus: [TransferState.DOMESTIC],
            durationTestStatus: [DurationTestStatus.DurationTestNotStarted],
            selfTest: 1,
          }}
          confirmActionText={t("device.durationTest.startDurationTest")}
          columns={columns}
          autoRefresh={autoRefresh}
          onUpdate={fetchData}
        />
      </TabPanel>
      <TabPanel value={tabPanel} index={1}>
        <DeviceDurationTestInProgress
          actionTitle={t("device.durationTest.stopDurationTest")}
          selectDevicesTitle={t("device.durationTest.selectDevicesToStopTest")}
          deviceFilters={{
            ...DefaultFetchDeviceFilters,
            durationTestStatus: [DurationTestStatus.DurationTestInProgress],
          }}
          confirmActionText={t("device.durationTest.stopDurationTest")}
          columns={inProgressColumns}
          autoRefresh={autoRefresh}
          onUpdate={fetchData}
        />
      </TabPanel>
      <TabPanel value={tabPanel} index={2}>
        <DeviceDurationTestFinished
          actionTitle={t("device.durationTest.finishDurationTest")}
          deviceFilters={{
            ...DefaultFetchDeviceFilters,
            durationTestStatus: [DurationTestStatus.DurationTestVerify],
          }}
          confirmActionText={t("device.durationTest.finishDurationTest")}
          columns={finishedColumns}
          autoRefresh={autoRefresh}
          onUpdate={fetchData}
        />
      </TabPanel>
    </div>
  );
};

export default DurationTestPage;
