import { TransferConcerns } from "Device/definitions";
import React from "react";

import { List, ListItem, ListItemText } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Color } from "@material-ui/lab/Alert/Alert";

type ConcernsProps = {
  message: string;
  concerns?: TransferConcerns;
};

export default function Concerns({
  message,
  concerns,
}: ConcernsProps): JSX.Element {
  return (
    <List>
      <Concern severity="error" descr={message} />
      {concerns && (
        <>
          {concerns.errors &&
            concerns.errors.map(({ code, descr }) => (
              <Concern key={code} code={code} severity="error" descr={descr} />
            ))}
          {concerns.warnings &&
            concerns.warnings.map(({ code, descr }) => (
              <Concern
                key={code}
                code={code}
                severity="warning"
                descr={descr}
              />
            ))}
        </>
      )}
    </List>
  );
}

type ConcernProps = {
  code?: number;
  severity: Color;
  descr?: string;
};

function Concern({ code, severity, descr }: ConcernProps) {
  const classes = useStyles();
  return (
    <ListItem className={classes.concern}>
      <ListItemText
        primary={
          <Alert severity={severity}>
            {code && <AlertTitle>{code}</AlertTitle>}
            {descr}
          </Alert>
        }
      />
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  concern: {
    paddingLeft: theme.spacing(4),
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
