import { Grid } from "@material-ui/core";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import CustomInput from "components/Form/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Device } from "Device/Device";

import deviceType from "Device/deviceType";
import DurationTestSummary from "Device/DurationTest/DurationTestSummary";
import { getDevice } from "Device/requests";

import countries from "i18n-iso-countries";
import i18n from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";

import Divider from "../DeviceInfoPage/Divider";
import Indicators from "../DeviceInfoPage/Indicators";

export default function DurationTestSummaryPage(): JSX.Element {
  const { uid } = useParams<{ uid: string }>();
  const [device, setDevice] = React.useState<Device | null>(null);
  const [t] = useTranslation("app");

  useAsyncEffect(async () => {
    const response = await getDevice(uid);

    setDevice(response.data);
  }, [uid, setDevice]);

  if (!device) {
    return <></>;
  }

  return (
    <>
      <Card>
        <CardHeader color="warning" text>
          <Grid container wrap="nowrap">
            <Grid item>
              <CardText color="warning">
                <h4>{t("common:general.info")}</h4>
              </CardText>
            </Grid>
            <Indicators device={device} />
          </Grid>
        </CardHeader>

        <CardBody>
          <GridContainer>
            <GridItem>
              <CustomInput
                id="type"
                labelText={t("di:device.type")}
                underline={false}
                labelProps={{ shrink: true }}
                inputProps={{
                  value: (device?.type && deviceType[device.type]) ?? "",
                  disabled: true,
                }}
              />
            </GridItem>
            {device.type.startsWith("zkl-3000") && (
              <GridItem>
                <CustomInput
                  id="version"
                  labelText={t("di:device.version")}
                  underline={false}
                  labelProps={{ shrink: true }}
                  inputProps={{
                    value: device?.version || "-",
                    disabled: true,
                  }}
                />
              </GridItem>
            )}
            <GridItem>
              <CustomInput
                id="country"
                labelText={t("di:device.country")}
                underline={false}
                labelProps={{ shrink: true }}
                inputProps={{
                  value: device?.country
                    ? countries.getName(device.country, i18n.language, {
                        select: "official",
                      })
                    : "-",
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                id="owner"
                labelText={t("di:device.ownerLabel")}
                underline={false}
                labelProps={{ shrink: true }}
                inputProps={{
                  value: device?.label || "-",
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem>
              <CustomInput
                id="serial"
                labelText={t("di:device.serialNr")}
                underline={false}
                labelProps={{ shrink: true }}
                inputProps={{
                  value: device?.serial || "-",
                  disabled: true,
                }}
              />
            </GridItem>
          </GridContainer>
          <Divider />
        </CardBody>
      </Card>
      <DurationTestSummary device={device} />
    </>
  );
}
