import React from "react";
import { CircularProgress, Box, Typography, Paper } from "@material-ui/core";

interface CustomCircularProgressWithLabelProps {
  value?: number;
  isSending?: boolean;
  id?: string;
}

const CustomCircularProgressWithLabel: React.FC<
  CustomCircularProgressWithLabelProps
> = ({ value, isSending, id }) => {
  return (
    <Paper
      elevation={3}
      style={{
        padding: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box position="relative" display="inline-flex" alignItems="center">
        <CircularProgress size={60} />
        <Box
          position="absolute"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          top={0}
          left={0}
          bottom={0}
          right={0}
        >
          <Typography
            variant="h6"
            component="div"
            color="textSecondary"
            id={id}
          >
            {isSending ? "Sending..." : `${Math.round(value ?? 0)}%`}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default CustomCircularProgressWithLabel;
