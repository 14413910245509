import { setLocale } from "yup";
import { Callback, TFunction } from "i18next";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormikI18nInit: Callback = (error: any, t: TFunction) => {
  setLocale({
    mixed: {
      required: t("common:form.required"),
      oneOf: ({ values }: { values: [] }) =>
        t("common:form.oneOf", { values: values }),
    },
    string: {
      max: ({ max }: { max: number }) =>
        t("common:form.max", { characters: max }),
    },
  });
};

export default FormikI18nInit;
