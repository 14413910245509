import * as Sentry from "@sentry/react";
import axios, { AxiosResponse, Method } from "axios";
import { PageOptions, PageResult } from "components/Table/Table";
import { Calibration, RssCalibration } from "Device/Calibration/Calibration";
import {
  DurationTestStatus,
  FetchDeviceFilters,
  FetchDevicePageOptions,
  PatchDevice,
  Status,
  Transfer,
  TransferConcerns,
} from "Device/definitions";
import {
  DefaultDurationTestReport,
  DurationTestReport,
} from "Device/Report/DurationTestReport";
import useNonConcurrentRequest from "hooks/Axios";
import FileDownload from "js-file-download";
import {
  Composition,
  DeviceConfiguration,
} from "./Calibration/Composition/CompositionSchema";
import {
  Device,
  FotaBulkCreateView,
  FotaDeviceView,
  FOTAJobListView,
  FotaDeviceHistory,
  FOTAUpdateReadyJobListView,
  FOTAJobRetryResponse,
  FOTAJobCancelResponse,
  FOTAJobCompleteResponse,
} from "./Device";
import { SelfTestReport as RSSSelfTestReport } from "./Report/Rss/SelfTestReport";
import { SelfTestReport } from "./Report/ZklRc/SelfTestReport";

export async function fetchEndpoints(): Promise<AxiosResponse> {
  return await axios.get(`${process.env.REACT_APP_API_URL}endpoint`);
}

export async function downloadFile(url: string): Promise<void> {
  try {
    const response = await axios.get(url, { responseType: "blob" });
    FileDownload(
      response.data,
      response.headers["content-disposition"].split("filename=")[1],
      response.headers["content-type"]
    );
  } catch (e) {
    // Caught by interceptor
  }
}

export const useFetchDevicePage = function () {
  const ncr = useNonConcurrentRequest();

  return {
    fetchDevicePage: async function (
      {
        inService,
        durationTestStatus,
        transferStatus,
        selfTest,
      }: FetchDeviceFilters,
      {
        pageIndex,
        pageSize,
        sortBy,
        globalFilter,
        selectedFilters,
      }: FetchDevicePageOptions,
      pickTestProgress?: boolean
    ): Promise<PageResult<Device[]>> {
      let filters: string[][] = [];
      for (const name in selectedFilters) {
        filters = [
          ...filters,
          ...Object.keys(selectedFilters[name]).map((key) => [name, key]),
        ];
      }
      for (const key in durationTestStatus) {
        const status: string = durationTestStatus[key].toString();
        filters = [
          ...filters,
          ...Object.keys(status).map(() => ["duration_test_status", status]),
        ];
      }
      for (const key in transferStatus) {
        const status: string = transferStatus[key].toString();
        filters = [
          ...filters,
          ...Object.keys(status).map(() => ["transfer_status", status]),
        ];
      }
      const params = new URLSearchParams(filters);
      if (globalFilter) params.set("search", globalFilter);
      params.set("page", `${pageIndex + 1}`);
      params.set("per_page", `${pageSize}`);
      params.set("not_in_service", `${Number(!inService)}`);
      params.set("self_test", `${Number(selfTest)}`);

      if (sortBy.length > 0) {
        const [{ id, desc }] = sortBy;
        params.set("sort", id);
        params.set("sort_dir", desc ? "desc" : "asc");
      }

      let url = `${process.env.REACT_APP_API_URL}device?${params.toString()}`;
      if (pickTestProgress) {
        url = `${
          process.env.REACT_APP_API_URL
        }duration-test-reports?${params.toString()}`;
      }
      try {
        const response = await ncr.request<Device[]>({ url, method: "GET" });
        return {
          rowCount: Number(response.headers["x-total-count"]),
          data: response.data,
        };
      } catch (e) {
        if (!axios.isCancel(e)) throw e;
        return {
          rowCount: null,
          data: null,
        };
      }
    },
  };
};

export async function updateMustHaveAftc(
  uid: Device["uid"],
  mustHaveAftc: boolean
): Promise<AxiosResponse> {
  const url = `${process.env.REACT_APP_API_URL}zkl-rc/${uid}/aftc`;
  return await axios.patch(url, { mustHaveAftc });
}

export async function takeIn(
  uid: Device["uid"],
  endpoint: string
): Promise<Transfer> {
  return transfer("put", uid, endpoint);
}

export async function pushOut(
  uid: Device["uid"],
  endpoint: string
): Promise<Transfer> {
  return transfer("delete", uid, endpoint);
}

export async function transferDurationTestStatus(
  uid: string,
  state: number,
  remarks?: string | ""
): Promise<Transfer> {
  try {
    const response = await axios.create().request({
      url: `${process.env.REACT_APP_API_URL}device/${uid}/in-duration-test?in_duration_test=${state}`,
      method: "PATCH",
      data: {
        in_duration_test: state,
        remarks: remarks,
      },
    });
    return { status: Status.SUCCESS, data: response.data };
  } catch (error) {
    if (!axios.isAxiosError(error) || !error.response) {
      return { status: Status.FAILURE, reason: "device.transfer.noResponse" };
    }
    let reason = "device.transfer.error";
    let concerns: undefined | TransferConcerns;
    const { response } = error;
    const { status, data } = response;
    switch (status) {
      default:
        // Every other state than the defined cases is seen as an error. A
        // service engineer will not be able to resolve most of the errors
        // that can occur. As an example, a not found probably indicates there
        // is something wrong in the data-flow, which is out of control of
        // the aforementioned service engineer.
        Sentry.captureMessage("unsolvable transfer-status", (scope) => {
          scope.setExtra("response", response);
          return scope;
        });
        break;
      case 401:
        reason = "device.transfer.unauthorized";
        break;
      case 409:
        reason = "device.transfer.offline";
        concerns = data as TransferConcerns | undefined;
        break;
      case 424:
        reason = "device.transfer.blocked";
        concerns = data as TransferConcerns | undefined;
        break;
      case 502:
        reason = "device.transfer.failed";
        concerns = data as TransferConcerns | undefined;
        break;
      case 503:
        reason = "device.transfer.unable";
        break;
      case 504:
        reason = "device.transfer.timeout";
        break;
    }
    return {
      status: Status.FAILURE,
      reason,
      concerns,
    };
  }
}

async function transfer(
  method: Method,
  uid: Device["uid"],
  endpoint: string
): Promise<Transfer> {
  try {
    const response = await axios.create().request({
      url: `${process.env.REACT_APP_API_URL}device/${uid}/service`,
      method: method,
      data: {
        endpoint,
      },
    });
    return { status: Status.SUCCESS, data: response.data };
  } catch (error) {
    if (!axios.isAxiosError(error) || !error.response) {
      return { status: Status.FAILURE, reason: "device.transfer.noResponse" };
    }
    let reason = "device.transfer.error";
    let concerns: undefined | TransferConcerns;
    const { response } = error;
    const { status, data } = response;
    switch (status) {
      default:
        // Every other state than the defined cases is seen as an error. A
        // service engineer will not be able to resolve most of the errors
        // that can occur. As an example, a not found probably indicates there
        // is something wrong in the data-flow, which is out of control of
        // the aforementioned service engineer.
        Sentry.captureMessage("unsolvable transfer-status", (scope) => {
          scope.setExtra("response", response);
          return scope;
        });
        break;
      case 502:
        reason = "device.transfer.failed";
        concerns = data as TransferConcerns | undefined;
        break;
      case 503:
        reason = "device.transfer.unable";
        break;
      case 504:
        reason = "device.transfer.timeout";
        break;
    }
    return {
      status: Status.FAILURE,
      reason,
      concerns,
    };
  }
}

export const useFetchDurationTestReport = function () {
  const ncr = useNonConcurrentRequest();

  return {
    fetchDurationTestReport: async function (
      uid: Device["uid"]
    ): Promise<DurationTestReport> {
      const url = `${process.env.REACT_APP_API_URL}device/${uid}/duration-test-report?`;
      try {
        const response = await ncr.request<DurationTestReport>({
          url,
          method: "GET",
        });
        return response.data;
      } catch (e) {
        if (!axios.isCancel(e)) throw e;
        return DefaultDurationTestReport;
      }
    },
  };
};

function setPaginationParameters({
  pageIndex,
  pageSize,
  sortBy,
  globalFilter,
}: PageOptions) {
  const params = new URLSearchParams();
  if (globalFilter) params.set("search", globalFilter);
  params.set("page", `${pageIndex + 1}`);
  params.set("per_page", `${pageSize}`);
  if (sortBy.length > 0) {
    const [{ id, desc }] = sortBy;
    params.set("sort", id);
    params.set("sort_dir", desc ? "desc" : "asc");
  }
  return params;
}

export const useFetchRdiReportPage = function () {
  const ncr = useNonConcurrentRequest();

  return {
    fetchReportPage: async function (
      uid: Device["uid"],
      { pageIndex, pageSize, sortBy, globalFilter }: PageOptions
    ): Promise<PageResult<SelfTestReport[]>> {
      const params = setPaginationParameters({
        pageIndex,
        pageSize,
        sortBy,
        globalFilter,
      });

      const url = `${
        process.env.REACT_APP_API_URL
      }rdi/${uid}/report?${params.toString()}`;
      try {
        const response = await ncr.request<SelfTestReport[]>({
          url,
          method: "GET",
        });
        return {
          rowCount: Number(response.headers["x-total-count"]),
          data: response.data,
        };
      } catch (e) {
        if (!axios.isCancel(e)) throw e;
        return {
          rowCount: null,
          data: null,
        };
      }
    },
  };
};

export const useFetchZklRcReportPage = function () {
  const ncr = useNonConcurrentRequest();

  return {
    fetchReportPage: async function (
      uid: Device["uid"],
      { pageIndex, pageSize, sortBy, globalFilter }: PageOptions
    ): Promise<PageResult<SelfTestReport[]>> {
      const params = setPaginationParameters({
        pageIndex,
        pageSize,
        sortBy,
        globalFilter,
      });

      const url = `${
        process.env.REACT_APP_API_URL
      }zkl-rc/${uid}/report?${params.toString()}`;
      try {
        const response = await ncr.request<SelfTestReport[]>({
          url,
          method: "GET",
        });
        return {
          rowCount: Number(response.headers["x-total-count"]),
          data: response.data,
        };
      } catch (e) {
        if (!axios.isCancel(e)) throw e;
        return {
          rowCount: null,
          data: null,
        };
      }
    },
  };
};

export const useFetchRssReportPage = function () {
  const ncr = useNonConcurrentRequest();

  return {
    fetchReportPage: async function (
      uid: Device["uid"],
      { pageIndex, pageSize, sortBy, globalFilter }: PageOptions
    ): Promise<PageResult<RSSSelfTestReport[]>> {
      const params = setPaginationParameters({
        pageIndex,
        pageSize,
        sortBy,
        globalFilter,
      });

      const url = `${
        process.env.REACT_APP_API_URL
      }rss/${uid}/report?${params.toString()}`;
      try {
        const response = await ncr.request<RSSSelfTestReport[]>({
          url,
          method: "GET",
        });
        return {
          rowCount: Number(response.headers["x-total-count"]),
          data: response.data,
        };
      } catch (e) {
        if (!axios.isCancel(e)) throw e;
        return {
          rowCount: null,
          data: null,
        };
      }
    },
  };
};

export const useFetchDeviceDurationTestReportPage = function () {
  const ncr = useNonConcurrentRequest();

  return {
    fetchDurationTestReportPage: async function (
      uid: Device["uid"],
      { pageIndex, pageSize, sortBy, globalFilter }: PageOptions
    ): Promise<PageResult<DurationTestReport[]>> {
      const params = setPaginationParameters({
        pageIndex,
        pageSize,
        sortBy,
        globalFilter,
      });
      params.set(
        "status",
        "" +
          DurationTestStatus.DurationTestIgnored +
          "," +
          DurationTestStatus.DurationTestSkipped +
          "," +
          DurationTestStatus.DurationTestCompleted
      );
      const url = `${
        process.env.REACT_APP_API_URL
      }device/${uid}/duration-test-reports?${params.toString()}`;
      try {
        const response = await ncr.request<DurationTestReport[]>({
          url,
          method: "GET",
        });
        return {
          rowCount: Number(response.headers["x-total-count"]),
          data: response.data,
        };
      } catch (e) {
        if (!axios.isCancel(e)) throw e;
        return {
          rowCount: null,
          data: null,
        };
      }
    },
  };
};

export async function downloadZklRcReport(
  uid: string,
  reportID: number,
  language: string
): Promise<void> {
  try {
    const params = new URLSearchParams();
    params.set("lang", language);

    await downloadFile(
      `${
        process.env.REACT_APP_API_URL
      }zkl-rc/${uid}/report/${reportID}?${params.toString()}`
    );
  } catch {
    // Caught by interceptor
  }
}

export async function downloadRssReport(
  uid: string,
  reportID: number,
  language: string
): Promise<void> {
  try {
    const params = new URLSearchParams();
    params.set("lang", language);

    await downloadFile(
      `${
        process.env.REACT_APP_API_URL
      }rss/${uid}/report/${reportID}?${params.toString()}`
    );
  } catch {
    // Caught by interceptor
  }
}

export async function downloadDeviceDurationTestReport(
  uid: string,
  reportID: number,
  language: string
): Promise<void> {
  try {
    const params = new URLSearchParams();
    params.set("lang", language);

    await downloadFile(
      `${
        process.env.REACT_APP_API_URL
      }device/${uid}/duration-report/${reportID}?${params.toString()}`
    );
  } catch {
    // Caught by interceptor
  }
}

export async function downloadRdiReport(
  uid: string,
  reportID: number,
  language: string
): Promise<void> {
  try {
    const params = new URLSearchParams();
    params.set("lang", language);

    await downloadFile(
      `${
        process.env.REACT_APP_API_URL
      }rdi/${uid}/report/${reportID}?${params.toString()}`
    );
  } catch {
    // Caught by interceptor
  }
}

export async function updateServiceInterval(
  uid: Device["uid"],
  years: number
): Promise<AxiosResponse> {
  const url = `${process.env.REACT_APP_API_URL}device/${uid}/service-interval`;
  return await axios.patch(url, { years });
}

export async function getDevice(
  uid: Device["uid"]
): Promise<AxiosResponse<Device>> {
  const url = `${process.env.REACT_APP_API_URL}device/${uid}`;
  return await axios.get(url);
}

export async function patchDevice(
  device: Device,
  toUpdate: PatchDevice
): Promise<Device> {
  const url = `${process.env.REACT_APP_API_URL}device/${device.uid}`;
  await axios.patch(url, toUpdate);

  return { ...device, ...(toUpdate as Device) };
}

export async function getDeviceComposition(
  uid: Device["uid"],
  country: string
): Promise<AxiosResponse<Composition>> {
  const params = new URLSearchParams();
  params.set("country", country);

  const url = `${
    process.env.REACT_APP_API_URL
  }device/${uid}/composition?${params.toString()}`;
  return await axios.get(url);
}

export async function postDeviceComposition(
  uid: Device["uid"],
  configurationId: DeviceConfiguration["id"]
): Promise<AxiosResponse> {
  const url = `${process.env.REACT_APP_API_URL}device/${uid}/composition`;
  return await axios.post(url, { configuration: configurationId });
}

export async function getZklRcCalibration(
  uid: Device["uid"]
): Promise<AxiosResponse<Calibration>> {
  const url = `${process.env.REACT_APP_API_URL}zkl-rc/${uid}/calibration`;
  return await axios.get(url);
}

export async function getRssCalibration(
  uid: Device["uid"]
): Promise<AxiosResponse<RssCalibration>> {
  const url = `${process.env.REACT_APP_API_URL}rss/${uid}/calibration`;
  return await axios.get(url);
}

const enrichFotaJobListView = (o: FOTAJobListView): FOTAJobListView => ({
  ...o,
});
export const useFetchFotaDevicePage = function () {
  const ncr = useNonConcurrentRequest();

  return {
    fetchFotaDevicePage: async function ({
      pageIndex,
      pageSize,
      sortBy,
      globalFilter,
      selectedFilters,
    }: FetchDevicePageOptions): Promise<PageResult<FotaDeviceView[]>> {
      let filters: string[][] = [];
      for (const name in selectedFilters) {
        filters = [
          ...filters,
          ...Object.keys(selectedFilters[name]).map((key) => [name, key]),
        ];
      }
      const params = new URLSearchParams(filters);
      if (globalFilter) params.set("search", globalFilter);
      params.set("page", `${pageIndex + 1}`);
      params.set("per_page", `${pageSize}`);

      if (sortBy.length > 0) {
        const [{ id, desc }] = sortBy;
        params.set("sort", id);
        params.set("sort_dir", desc ? "desc" : "asc");
      }

      const url = `${
        process.env.REACT_APP_FOTA_API_URL
      }available_updates?${params.toString()}`;
      try {
        const response = await ncr.request<FotaDeviceView[]>({
          url,
          method: "GET",
        });
        return {
          rowCount: Number(response.headers["x-total-count"]),
          data: response.data as FotaDeviceView[],
        };
      } catch (e) {
        if (!axios.isCancel(e)) throw e;
        return {
          rowCount: null,
          data: null,
        };
      }
    },
  };
};

export const useFetchAllJoblist = function (states?: string[]) {
  const ncr = useNonConcurrentRequest();

  return {
    fetchFotaAllJobList: async function ({
      pageIndex,
      pageSize,
      sortBy,
      globalFilter,
      selectedFilters,
    }: FetchDevicePageOptions): Promise<PageResult<FOTAJobListView[]>> {
      let filters: string[][] = [];
      for (const name in selectedFilters) {
        filters = [
          ...filters,
          ...Object.keys(selectedFilters[name]).map((key) => [name, key]),
        ];
      }
      const params = new URLSearchParams(filters);

      if (states && states.length > 0 && params.toString() === "") {
        states.forEach((state) => {
          params.append("state", state);
        });
      }
      if (globalFilter) params.set("search", globalFilter);
      params.set("page", `${pageIndex + 1}`);
      params.set("per_page", `${pageSize}`);

      if (sortBy.length > 0) {
        const [{ id, desc }] = sortBy;
        params.set("sort", id);
        params.set("sort_dir", desc ? "desc" : "asc");
      }

      const url = `${
        process.env.REACT_APP_FOTA_API_URL
      }jobs?${params.toString()}`;
      try {
        const response = await ncr.request<FOTAJobListView[]>({
          url,
          method: "GET",
        });
        return {
          rowCount: Number(response.headers["x-total-count"]),
          data: (response.data as FOTAJobListView[]).map(enrichFotaJobListView),
        };
      } catch (e) {
        if (!axios.isCancel(e)) throw e;
        return {
          rowCount: null,
          data: null,
        };
      }
    },
  };
};

export async function postBulkUpdates(
  updates: number[]
): Promise<FotaBulkCreateView[]> {
  const url = `${process.env.REACT_APP_FOTA_API_URL}jobs/bulk`;
  return (await axios.post(url, { updates: updates })).data;
}

export async function postBulkUpdatesForUpdateReady(
  updates: number[]
): Promise<FOTAUpdateReadyJobListView> {
  const url = `${process.env.REACT_APP_FOTA_API_URL}jobs/update`;
  return (await axios.post(url, { job_ids: updates })).data;
}

export async function postBulkRetryJobs(
  jobs: number[]
): Promise<FOTAJobRetryResponse[]> {
  const url = `${process.env.REACT_APP_FOTA_API_URL}jobs/retry`;
  return (await axios.post(url, { jobs: jobs })).data;
}

export async function postCancelJobs(
  jobs: number[]
): Promise<FOTAJobCancelResponse[]> {
  const url = `${process.env.REACT_APP_FOTA_API_URL}jobs/cancel`;
  return (await axios.post(url, { job_ids: jobs })).data;
}

export async function postCompleteConfirmJobs(
  jobs: number[]
): Promise<FOTAJobCompleteResponse[]> {
  const url = `${process.env.REACT_APP_FOTA_API_URL}jobs/confirm`;
  return (await axios.post(url, { job_ids: jobs })).data;
}

export const useFetchFotaHistory = function () {
  const ncr = useNonConcurrentRequest();

  return {
    fetchFotaHistory: async function (
      uid: Device["uid"],
      { pageIndex, pageSize, sortBy, globalFilter }: FetchDevicePageOptions
    ): Promise<PageResult<FotaDeviceHistory[]>> {
      const params = setPaginationParameters({
        pageIndex,
        pageSize,
        sortBy,
        globalFilter,
      });

      const url = `${
        process.env.REACT_APP_FOTA_API_URL
      }device/${uid}/history?${params.toString()}`;
      try {
        const response = await ncr.request<FotaDeviceHistory[]>({
          url,
          method: "GET",
        });
        return {
          rowCount: Number(response.headers["x-total-count"]),
          data: response.data,
        };
      } catch (e) {
        if (!axios.isCancel(e)) throw e;
        return {
          rowCount: null,
          data: null,
        };
      }
    },
  };
};

export async function updateDeviceImei(
  uid: string | undefined,
  oldImei: string,
  newImei: string
): Promise<AxiosResponse> {
  const url = `${process.env.REACT_APP_API_URL}device/${uid}/imei`;
  const data = {
    OldImei: oldImei,
    NewImei: newImei,
  };
  return await axios.patch(url, data);
}
