import { Device, FOTAJobListView } from "Device/Device";
import { Meta, TableToggleAllRowsSelectedProps } from "react-table";

const ToggleAllRowsSelectedHook = (
  props: TableToggleAllRowsSelectedProps,
  { instance }: Meta<Device>
): TableToggleAllRowsSelectedProps => {
  const { flatRows } = instance;
  if (flatRows.length) {
    let selectable = 0;
    let selected = 0;
    flatRows.some((row) => {
      if (row.original.editable) {
        selectable += 1;
        if (row.isSelected) {
          selected += 1;
        }
      }
    });

    props.checked = selectable > 0 && selectable === selected;
  }
  return props;
};

export const ToggleAllRowsSelectedFotaJobHook = (
  props: TableToggleAllRowsSelectedProps,
  { instance }: Meta<FOTAJobListView>
): TableToggleAllRowsSelectedProps => {
  const { flatRows } = instance;
  if (flatRows.length) {
    let selectable = 0;
    let selected = 0;
    flatRows.some((row) => {
      if (row.original.is_online) {
        selectable += 1;
        if (row.isSelected) {
          selected += 1;
        }
      }
    });

    props.checked = selectable > 0 && selectable === selected;
  }
  return props;
};

export default ToggleAllRowsSelectedHook;
